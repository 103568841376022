import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import Pagination from "@mui/material/Pagination";
import { AgGridReact } from "ag-grid-react";
import { useState, useMemo, useRef, useEffect } from "react";
import { Typography, Icon, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { themeQuartz } from "ag-grid-community";
import { colorSchemeDark } from "ag-grid-community";
import getEasierToReadColor from "common/utils/getEasierToReadColor";

function formatHeader(input) {
  return input
    .split("_") // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words with a space
}

// selection info component
export const SelectionInfo = ({
  unselectedResources,
  selectedResources,
  selectAll,
  variant = "body2",
}) => (
  <Typography variant={variant} sx={{ height: "24px" }}>
    {unselectedResources?.length
      ? `All rows except ${unselectedResources.length} selected for current filter`
      : selectedResources?.length
      ? `${selectedResources.length} row${
          selectedResources.length > 1 ? "s" : ""
        } selected for current filter`
      : selectAll
      ? `All rows for current filter selected`
      : ""}
  </Typography>
);
//themeing
const theme = themeQuartz.withPart(colorSchemeDark).withParams({
  backgroundColor: "#262626",
  accentColor: "#ff914c",
});

// context menu hook
const useContextMenu = () => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const handleContextMenu = (event, props) => {
    event.preventDefault();
    setAnchorPoint({ x: event.clientX, y: event.clientY });
    setShowMenu(props);
  };

  const handleClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const ContextMenu = ({ items }) => (
    <Menu
      open={!!showMenu}
      onClose={handleClick}
      onContextMenu={(e) => e.preventDefault()} // Disable right-click on the menu
      BackdropProps={{
        onContextMenu: (e) => e.preventDefault(), // Disable right-click on the backdrop
      }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: `${anchorPoint.y}`,
        left: `${anchorPoint.x}`,
      }}
    >
      {items.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setShowMenu(false);
            item.handler(
              showMenu?.data,
              showMenu?.column?.colId,
              showMenu?.value
            );
          }}
        >
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  );

  return { ContextMenu, handleContextMenu };
};

//register all Community features for ag-grid
ModuleRegistry.registerModules([AllCommunityModule]);

//custom ag grid comparators

const datetimeStringComparator = (date1, date2) => {
  const parseDate = (dateStr) => {
    const [datePart, timePart] = dateStr.split(", ");
    const [month, day, year] = datePart.split("/").map(Number);
    const [time, period] = timePart.split(" ");
    let [hours, minutes, seconds] = time.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  console.log(date1, date2);
  try {
    const date1Parsed = parseDate(date1);
    const date2Parsed = parseDate(date2);

    return date1Parsed - date2Parsed;
  } catch {
    return 0;
  }
};

const stringComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  try {
    if (valueA == valueB) return 0;
    return valueA > valueB ? -1 : 1;
  } catch {
    return 0;
  }
};
const boolComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  try {
    if (valueA == valueB) return 0;
    return valueA > valueB ? 1 : -1;
  } catch {
    return 0;
  }
};
const numberComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  try {
    return Number(valueB) - Number(valueA);
  } catch {
    return 0;
  }
};

// main component
const Table = ({
  data,
  idProperty,
  columns,
  loading,
  onPaginate,
  onSort,
  page,
  pages,
  sortColumn,
  sortDirection,
  tableName,
  tableHeight = 500,
  contextMenu,
  filter,
  selectAll,
  setSelectAll,
  selectedRowIds,
  setSelectedRowIds,
  unselectedRowIds,
  setUnselectedRowIds,
  noSelect = false,
  noPaginate = false,
  ...rest
}) => {
  const { ContextMenu, handleContextMenu } = useContextMenu();
  const gridApiRef = useRef(null);
  // const [selectedRowIds, setSelectedRowIds] = useState(new Set());
  // const [unselectedRowIds, setUnselectedRowIds] = useState(new Set());
  // const [selectAll, setSelectAll] = useState(false);

  const rowSelection = useMemo(() => {
    return { mode: "multiRow" };
  }, []);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    window[tableName] = params.api;
  };

  const onRowSelected = (event) => {
    if (["api", "uiSelectAll"].includes(event.source)) return;
    const rowId = event.data[idProperty];
    if (selectAll) {
      setUnselectedRowIds((prev) => {
        const newSet = new Set(prev);
        if (event.node.isSelected()) {
          newSet.delete(rowId);
        } else {
          newSet.add(rowId);
        }
        return newSet;
      });
    } else {
      setSelectedRowIds((prev) => {
        const newSet = new Set(prev);
        if (event.node.isSelected()) {
          newSet.add(rowId);
        } else {
          newSet.delete(rowId);
        }
        return newSet;
      });
    }
  };

  const onSelectionChanged = (event) => {
    if (event.source === "uiSelectAll") {
      const allSelected =
        gridApiRef.current.getSelectedNodes().length === data.length;
      setSelectAll(allSelected);
      setSelectedRowIds(new Set());
      setUnselectedRowIds(new Set());
    }
  };

  const onSortChanged = (params) => {
    if (params.type !== "sortChanged") return;
    let column = params.columns.at(-1).colId;
    let sort = params.columns.at(-1).sort;
    if (sort) {
      sort = sort === "desc" ? "-1" : "1";
    }
    if (onSort) {
      onSort(column, sort);
    }
  };

  const onComponentStateChanged = (params) => {
    params.api.forEachNode((node) => {
      if (
        !noSelect &&
        (selectedRowIds.has(node.data[idProperty]) || selectAll) &&
        !unselectedRowIds.has(node.data[idProperty])
      ) {
        node.setSelected(true);
      }
    });
  };

  const columnDefs = columns.map((col) => {
    // format header
    col.headerName = formatHeader(col.headerName);
    if (!col?.context?.type) return col;
    // assign custom renderer
    if (!col.cellRenderer) {
      col.cellRenderer = (props) => {
        const { value } = props;
        // handle type render
        let renderedValue = value;
        let showContentMenu = true;
        if (props?.colDef?.context?.type === "bool") {
          renderedValue = value?.toString();
          showContentMenu = false;
        } else if (value === null || value === undefined) {
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              -
            </span>
          );
        } else if (typeof value !== "string" && typeof value !== "number") {
          renderedValue = (
            <Icon>
              {props?.colDef?.context?.type === "list"
                ? "data_array"
                : "data_object"}
            </Icon>
          );
          showContentMenu = false;
        } else if (
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
            value
          )
        ) {
          renderedValue = (
            <IconButton
              onClick={() => window.open(value, "_blank")}
              color="secondary"
            >
              <Icon color="secondary">link</Icon>
            </IconButton>
          );
        }
        let rowId = props?.data[idProperty];
        let cellColor = props?.colDef?.context?.cellColor;
        if (cellColor) {
          cellColor = cellColor[rowId];
        }
        return (
          <span
            style={{
              height: "100%",
              width: "110%",
              display: "flex",
              alignItems: "center",
              justifyContent: props?.colDef?.context?.align || "flex-start",
              paddingLeft: "10px",
              ...(cellColor
                ? {
                    background: cellColor,
                    color: getEasierToReadColor(cellColor),
                  }
                : {}),
            }}
            onContextMenu={
              showContentMenu
                ? (event) => handleContextMenu(event, props)
                : (e) => e.preventDefault()
            }
          >
            {renderedValue}
          </span>
        );
      };
    }
    // assign flex
    col.minWidth = 200;
    col.resizable = true;
    col.flex = 1;
    //handle comparators
    if (["datetime"].includes(col.context.type)) {
      col.comparator = datetimeStringComparator;
    } else if (["int", "float"].includes(col.context.type)) {
      col.comparator = numberComparator;
    } else if (["bool"].includes(col.context.type)) {
      col.comparator = boolComparator;
    } else if (["list", "object"].includes(col.context.type)) {
      col.sortable = false;
    } else {
      col.comparator = stringComparator;
    }
    return col;
  });
  // Effect to clear grid state when filter changes
  useEffect(() => {
    if (filter === false) return;
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
    }
    if (!noSelect) {
      setSelectedRowIds(new Set());
      setUnselectedRowIds(new Set());
      setSelectAll(false);
    }
  }, [filter]);
  useEffect(() => {
    //handle initial sorting state
    if (!loading && sortColumn && gridApiRef.current) {
      if (gridApiRef.current.getColumn(sortColumn)?.sort) return;
      gridApiRef.current.applyColumnState({
        state: [
          {
            colId: sortColumn,
            sort: Number(sortDirection) === -1 ? "desc" : "asc",
          },
        ],
        applyOrder: true,
      });
    }
  }, [loading]);
  // if selection based
  let props = {};
  if (!noSelect) {
    props = {
      onRowSelected,
      onSelectionChanged,
      rowSelection,
    };
  }
  return (
    <div style={{ position: "relative", height: tableHeight }}>
      {!noSelect ? (
        <SelectionInfo
          unselectedResources={Array.from(unselectedRowIds)}
          selectedResources={Array.from(selectedRowIds)}
          selectAll={selectAll}
        />
      ) : null}
      <AgGridReact
        theme={theme}
        key={filter}
        rowData={data}
        columnDefs={columnDefs}
        // rowSelection={rowSelection}
        // onRowSelected={onRowSelected}
        // onSelectionChanged={onSelectionChanged}
        onSortChanged={onSortChanged}
        onGridReady={onGridReady}
        onComponentStateChanged={onComponentStateChanged}
        loading={loading}
        suppressCellFocus
        {...props}
        {...rest}
      />
      <div
        style={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!noPaginate ? (
          <Pagination
            onChange={(_, page) => {
              onPaginate(page);
            }}
            showFirstButton
            showLastButton
            color="secondary"
            page={page}
            count={pages}
            variant="outlined"
          />
        ) : null}
      </div>
      {contextMenu ? <ContextMenu items={contextMenu} /> : null}
    </div>
  );
};

export default Table;
