import React from "react";
import { Icon, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useState from "common/hooks/useState";
import MultiSelect from "common/components/MultiSelect";
import AssetImage from "common/components/AssetImage";
import useFetcher from "common/hooks/useFetcher";
import SystemInfoModal from "pages/Store/components/SystemInfo";
import SidePanel from "common/components/SidePanel";
import SystemInfo from "pages/SystemDash/component/SystemInfo";
import ImageLoader from "common/components/ImageLoader";
import ActionButton from "common/components/ActionButton";
import useConfirm from "common/hooks/useConfirm";
import SystemInstall from "./components/SystemInstall";
export default function Store({ role }) {
  const { tags, loading, error, refresh } = useState("/api/store/tags");
  const [load, setLoading] = React.useState(false);
  const [install, setInstall] = React.useState(false);
  const [system, setSystem] = React.useState(null);
  const [systems, setSystems] = React.useState([]);
  const [search, setSearch] = React.useState("ECHELONONE");
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const deleteHandler = (props, context) => {
    setSystem(null);
    if (!context?.id) return;
    fetcher.delete(
      "deleting system",
      `/api/store/system/${context.id}`,
      () => {
        refresh();
      },
      () => {}
    );
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Unpublish System`,
    message: `Are you sure you want to unpublish this system from Astrohub?`,
    callback: deleteHandler,
  });
  const getSystems = async (tag) => {
    if (!tag) return;
    setLoading(true);
    await fetcher.get(
      "fetching systems",
      `/api/store/tag?tag=${tag}`,
      ({ systems }) => {
        setSystems(systems);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const filterByTagHandler = async (tag) => {
    getSystems(tag);
    setSearch(tag);
  };
  React.useEffect(() => {
    getSystems("ECHELONONE");
  }, []);

  return (
    <Box>
      <DeleteModal />
      <SidePanel
        width={800}
        open={!!system}
        closeDrawer={() => setSystem(null)}
        polybg
        props={{
          system: system,
          store: true,
          onInstall: () => {
            setInstall(true);
          },
          onDelete: () => openDeleteModal(true, { ...system }),
          role,
        }}
      >
        {SystemInfo}
      </SidePanel>
      <SidePanel
        width={800}
        open={install}
        closeDrawer={() => setInstall(false)}
        polybg
        props={{
          system: system,
          store: true,
          // onInstall: () => {},
          // onDelete: () => openDeleteModal(true, { ...system }),
          role,
        }}
      >
        {SystemInstall}
      </SidePanel>
      {/* <SystemInfoModal
        {...{
          refresh: () => {
            refresh();
            getSystems(search || "ECHELONONE");
          },
          role,
          open: system,
          onClose: () => setSystem(null),
          filterByTagHandler,
        }}
      /> */}
      <div className="astro-hero">
        <div className="astro-hero__bg" />
        <div className="astro-hero__bg2" />
        <div className="astro-hero__header" style={{ "--stacks": 3 }}>
          <h1 style={{ "--index": 0 }}>ASTROHUB</h1>
          <h1 style={{ "--index": 1 }}>ASTROHUB</h1>
          <h1 style={{ "--index": 2 }}>ASTROHUB</h1>
        </div>
        <h2>AUTOMATION AND BEYOND</h2>
        <div className="astro-hero__search">
          <MultiSelect
            sx={{ width: "100%" }}
            clearHandler={() => {
              setSearch("ECHELONONE");
              getSystems("ECHELONONE");
            }}
            label="Search by Tag"
            loading={loading}
            options={tags || []}
            multiple={false}
            value={search}
            setValue={(e) => {
              getSystems(e);
              setSearch(e);
            }}
          />
        </div>
      </div>
      <div className="astro-grid">
        {systems.map((a) => {
          return (
            <div
              key={a.id}
              // onClick={() => setSystem(a.id)}
              className="astro-card"
            >
              <div className="astro-card__header">
                <span>{a.name}</span>
              </div>
              <div className="astro-card__hr" />
              <div className="astro-card__content">
                <div style={{ minHeight: "200px" }}>
                  <ImageLoader
                    apiPath={`/api/assets/store/store/${a.id}/logo.png`}
                    width="100%"
                    alt="System Logo"
                  />
                </div>
                {/* <AssetImage
                  path={`/api/assets/store/store/${a.id}/logo.png`}
                  style={{ width: "100%" }}
                /> */}
                <p>{a.tagline}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  marginTop: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <ActionButton
                  icon="info"
                  noload
                  handler={async () => {
                    setSystem(a);
                  }}
                >
                  More Info
                </ActionButton>
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
}
