import { Stack, Avatar, Typography, Icon, Grid } from "@mui/material";
import SectionHeader from "common/components/SectionHeader";
import ActionButton from "common/components/ActionButton";
import copyContent from "common/utils/copyContent";
import toast from "react-hot-toast";
import { JsonVerticalTable } from "../Resource";
export const WebhookInfo = ({ type, system, hook, action }) => {
  return (
    <Grid className="floating" gap={1} container>
      {type === "read" ? (
        <>
          <Grid xs={12} item>
            <SectionHeader icon="info">How to use</SectionHeader>
          </Grid>
          <Grid
            xs={12}
            item
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="body2">
              This webhook will provide the current system dataset in JSON
              format. This is an HTTP GET method. An example call is shown
              below.
            </Typography>
            <div style={{ marginTop: "10px", marginLeft: "auto" }}>
              <ActionButton
                circle
                dark
                icon="content_copy"
                handler={() => {
                  toast.success("copied curl example");
                  copyContent(
                    `curl https://${window.location.host}/api/sys/${system}/hook/${hook}`
                  );
                }}
              />
            </div>
            <pre
              style={{
                borderRadius: "7px",
                backgroundColor: "#171717",
                padding: "20px",
                margin: "20px 0",
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              <code>{`curl https://${window.location.host}/api/sys/${system}/hook/${hook}`}</code>
            </pre>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={12} item>
            <SectionHeader icon="info">How to use</SectionHeader>
          </Grid>
          <Grid
            xs={12}
            item
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="body2">
              This webhook will execute the following command,{" "}
              <strong>{action}</strong>, via a POST request. The above context
              will be given as input if overrides are not provided in the POST
              request. An example call is shown below.
            </Typography>
            <div style={{ marginTop: "10px", marginLeft: "auto" }}>
              <ActionButton
                circle
                dark
                icon="content_copy"
                handler={() => {
                  toast.success("copied curl example");
                  copyContent(
                    `curl -X POST  -H "Content-Type: multipart/form-data" -F "varname=@/Users/path/to/file.pdf" -F "json={\\"key\\":\\"value\\"}" https://${window.location.host}/api/sys/${system}/hook/${hook}`
                  );
                }}
              />
            </div>
            <pre
              style={{
                borderRadius: "7px",
                backgroundColor: "#171717",
                padding: "20px",
                margin: "20px 0",
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              <code>
                {`curl -X POST  -H "Content-Type: multipart/form-data" -F "varname=@/Users/path/to/file.pdf" -F "json={\\"key\\":\\"value\\"}" https://${window.location.host}/api/sys/${system}/hook/${hook}`}
                {/* {`curl -X POST -H "Content-Type: application/json" -d '{}' https://${window.location.host}/api/sys/${system}/hook/${hook}`} */}
              </code>
            </pre>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default function WebhookView({ hook, system, onClose }) {
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon color="primary" sx={{ fontSize: "50px !important" }}>
            webhook
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">{hook?.name}</Typography>
          <Typography variant="caption">
            {hook?.content?.user} ({hook?.content?.crew})
          </Typography>
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          dark
          handler={() => {
            toast.success("copied webhook");
            copyContent(
              `https://${window.location.host}/api/sys/${system}/hook/${hook?.pk}`
            );
          }}
          noload
          icon="content_copy"
        >
          Copy Webhook
        </ActionButton>
      </div>
      {hook?.type === "execute" ? (
        <Stack className="floating" gap={3}>
          <SectionHeader icon="input">Context</SectionHeader>
          <Stack gap={1}>
            <JsonVerticalTable
              data={[
                {
                  inputs: hook?.content?.inputs || {},
                  target: hook?.content?.target,
                  selection: {
                    selectAll: hook?.content?.selection?.selectAll,
                    selected: hook?.content?.selection?.selectedResources,
                    unselected: hook?.content?.selection?.unselectedResources,
                  },
                },
              ]}
            />
          </Stack>
        </Stack>
      ) : null}
      <WebhookInfo
        type={hook?.type}
        system={system}
        hook={hook?.pk}
        action={hook?.content?.action_name}
      />
    </Stack>
  );
}
