import React from "react";
import Page from "common/components/Page";
import { Stack, IconButton, Icon, Chip } from "@mui/material";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import { TypeMap } from "constants";
import useConfirm from "common/hooks/useConfirm";
import ModifyVariable from "./components/ModifyVariable";
import useFolderView from "common/hooks/useFolderView";
import QueryBuilder from "common/components/QueryBuilder";
import Table from "common/components/Table/table";
import PopoverMenu from "common/components/PopoverMenu";
import { useSearchParams } from "react-router-dom";

export default function Variables() {
  const { variables, loading, error, refresh } = useState("/api/vars");
  const [searchParams, setSearchParams] = useSearchParams();
  const [modVariable, setModVariable] = React.useState(false);
  const fetcher = useFetcher();
  const [filters, setFilters] = React.useState([]);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Variable`,
    message: `Are you sure you want to delete this variable? This action cannot be undone.`,
    callback: (_, data) => {
      deleteVariable(data?.id);
    },
  });
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    filterFolders,
  } = useFolderView();
  const deleteVariable = async (id) => {
    await fetcher.delete("deleting variable", `/api/vars/${id}`, () => {
      refresh();
    });
  };
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating variable tags",
      `/api/vars/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const searchFilter = () => {
    if (!variables) return [];
    if (!filters.length && !folder) return variables;
    let filtered = [...variables];
    filtered = variables.filter((variable) => {
      if (folder) {
        if (folder !== "default") {
          if (
            !variable.tags
              .map((t) => t.toLowerCase())
              .includes(folder.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return filters.every(({ field, value }) => {
        if (field === "variable") {
          return `${variable.name.toLowerCase()}${variable.description.toLowerCase()}`.includes(
            value.toLowerCase()
          );
        } else if (field === "tag") {
          return variable.tags
            .map((t) => t.toLowerCase())
            .includes(value.toLowerCase());
        } else if (field === "crew") {
          return (
            (variable?.owner_name?.toLowerCase() || "").includes(
              value.toLowerCase()
            ) ||
            (variable?.owner?.toLowerCase() || "").includes(value.toLowerCase())
          );
        }
      });
    });
    return filtered;
  };
  let filteredVars = searchFilter();
  let [tags, tag_options] = filterFolders(filteredVars, variables);
  const variableColumns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Move Variable",
                  onClick: () => {
                    setMoveFolder(data.pk);
                  },
                  icon: "move_up",
                },
                {
                  id: "Delete Variable",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>
          <IconButton
            onClick={() => {
              setModVariable(data);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Type",
      field: "type",
      width: 120,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      cellRenderer: ({ data }) => {
        return (
          <Icon color="secondary" sx={{ fontSize: "30px !important" }}>
            {TypeMap[data.type]?.icon}
          </Icon>
        );
      },
    },
    {
      headerName: "Variable Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Owner",
      field: "owner_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tags",
      field: "tags",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })}
          </span>
        );
      },
    },
  ];
  console.log(variables);
  return (
    <Page
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setModVariable(true);
          }}
        >
          Add Variable
        </ActionButton>,
      ]}
      loading={[loading]}
      header="Variables"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Variable"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <DeleteModal />
      <SidePanel
        width="600px"
        open={!!modVariable}
        closeDrawer={() => setModVariable(false)}
        props={{ refresh, variable: modVariable }}
      >
        {ModifyVariable}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
      </Stack>
      <QueryBuilder
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        defaultField="variable"
        fields={[
          { id: "variable", options: [] },
          {
            id: "tag",
            options: Array.from(
              new Set(variables?.reduce((a, t) => [...a, ...t.tags], []))
            ),
          },
          {
            id: "crew",
            options: Array.from(
              new Set(
                variables?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            ),
          },
        ]}
      />
      <Stack spacing={2}>
        {!folder ? (
          <FolderView tags={tags} />
        ) : (
          <Stack>
            <Table
              data={filteredVars}
              idProperty={"id"}
              columns={variableColumns}
              noSelect
              noPaginate
              loading={loading}
              tableName={"e1varlist"}
              tableHeight={700}
            />
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
