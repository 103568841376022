import React from "react";
import { theme } from "theme";
import useState from "common/hooks/useState";
import Page from "common/components/Page";
import MultiSelect from "common/components/MultiSelect";
import Tag from "common/components/Tag";
import {
  Stack,
  Avatar,
  IconButton,
  Icon,
  Grid,
  Typography,
  Badge,
} from "@mui/material";
import ActionButton from "common/components/ActionButton";
import useFetcher from "common/hooks/useFetcher";
import Input from "common/components/Input";
import FileUpload from "common/components/FileUpload";
import SidePanel from "common/components/SidePanel";
import AzureSSOIcon from "../../assets/sso/azure.png";
import Switch from "common/components/Switch";
import { MiniLoader } from "common/components/Loader";
import useConfirm from "common/hooks/useConfirm";
import AiImage from "assets/icons/black_magic.svg";
import toast from "react-hot-toast";
const BrandingConfig = ({ onClose }) => {
  const fetcher = useFetcher();
  const [imageFile, setImageFile] = React.useState(null);
  const updateLogoPicture = async () => {
    if (!imageFile) return;
    return await fetcher.post(
      "updating brand logo",
      `/api/settings/brand/logo`,
      () => {
        let form = new FormData();
        if (imageFile) {
          form.append("Image", imageFile);
        }
        return form;
      },
      ({ error }) => {
        if (error) {
          toast.error(error);
        }
      },
      () => {}
    );
  };
  const removeLogoPicture = async () => {
    return await fetcher.delete(
      "removing brand logo",
      `/api/settings/brand/logo`,
      ({ error }) => {
        if (error) {
          toast.error(error);
        }
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            tour
          </Icon>
        </Avatar>
        <Typography variant="h4">Branding</Typography>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Upload Your Logo</Typography>
        <Stack gap={1}>
          <Typography>We need a PNG file to use as your logo.</Typography>
          <Typography>Please keep the file size under 300kb.</Typography>
        </Stack>
        <FileUpload
          sizeInKb={300}
          file={imageFile}
          handleFileChange={setImageFile}
        >
          Upload Brand Logo
        </FileUpload>

        {imageFile ? (
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="save"
              dark
              handler={async () => {
                await updateLogoPicture();
              }}
            >
              Update Logo
            </ActionButton>
          </div>
        ) : (
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="clear"
              danger
              dark
              handler={async () => {
                await removeLogoPicture();
              }}
            >
              Remove Custom Logo
            </ActionButton>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

const AzureConfig = ({ onClose, config }) => {
  const [client_id, setClientId] = React.useState(config?.client_id || "");
  const [tenant_id, setTenantId] = React.useState(config?.tenant_id || "");
  const [client_secret, setClientSecret] = React.useState("");
  const fetcher = useFetcher();
  const saveConfig = async () => {
    //validate
    if (!client_id || !tenant_id || !client_secret) {
      return toast.error(
        "Please fill out all configuration inputs for Microsoft Entra SSO."
      );
    }
    //update sso for azure
    return await fetcher.post(
      "updating SSO configuration",
      `/api/settings/sso`,
      { exists: !!config, client_id, tenant_id, client_secret, type: "azure" },
      ({ error }) => {
        if (error) {
          toast.error(error);
        } else {
          onClose();
        }
      },
      () => {}
    );
  };
  return (
    <>
      <Stack gap={2}>
        <Typography>Configuration</Typography>
        <Input
          size="small"
          dark
          sx={{ width: "100%" }}
          placeholder="Application (client) ID"
          value={client_id}
          onChange={(e) => setClientId(e.target.value)}
        />
        <Input
          size="small"
          dark
          sx={{ width: "100%" }}
          placeholder="Directory (tenant) ID"
          value={tenant_id}
          onChange={(e) => setTenantId(e.target.value)}
        />
        <Input
          label={
            config?.pk
              ? `Current Client Secret (${config?.client_secret}******************)`
              : null
          }
          size="small"
          dark
          sx={{ width: "100%" }}
          placeholder={config?.pk ? "Updated Client Secret" : "Client Secret"}
          value={client_secret}
          onChange={(e) => setClientSecret(e.target.value)}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ActionButton
            icon="save"
            dark
            noload
            handler={async () => {
              await saveConfig();
            }}
          >
            Save Configuration
          </ActionButton>
        </div>
      </Stack>
      <Stack gap={1}>
        <div
          style={{
            overflowWrap: "break-word",
          }}
          class="container"
        >
          <h3>Azure Entra SSO Setup Instructions</h3>
          <ol>
            <li>
              <h4>Register the Application in Azure AD</h4>
              <ol>
                <li>Go to the Azure Portal</li>
                <li>
                  Create a New Application Registration:
                  <ul>
                    <li>
                      Navigate to{" "}
                      <strong>
                        Azure Active Directory &gt; App registrations
                      </strong>
                      .
                    </li>
                    <li>
                      Click on <strong>New registration</strong>.
                    </li>
                  </ul>
                </li>
                <li>
                  Fill in the Registration Details:
                  <ul>
                    <li>
                      <strong>Name:</strong> Enter a name for your application
                      (e.g., "Echelon One SSO").
                    </li>
                    <li>
                      <strong>Supported account types:</strong> Choose{" "}
                      <em>
                        Accounts in this organizational directory only (Single
                        tenant)
                      </em>
                      .
                    </li>
                    <li>
                      <strong>Web Redirect URI:</strong> Set the redirect URI to{" "}
                      <code>{`${window.location.protocol}//${window.location.host}/api/sso/entra/authorized`}</code>
                    </li>
                  </ul>
                </li>
                <li>
                  Register the Application:
                  <ul>
                    <li>
                      Click <strong>Register</strong>.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h4>Configure the Application</h4>
              <ol>
                <li>
                  Get the Application (client) ID and Directory (tenant) ID:
                  <ul>
                    <li>
                      After registration, you will be redirected to the
                      application's overview page.
                    </li>
                    <li>
                      Copy the <strong>Application (client) ID</strong> and{" "}
                      <strong>Directory (tenant) ID</strong> into the respective
                      inputs above.
                    </li>
                  </ul>
                </li>
                <li>
                  Create a Client Secret:
                  <ul>
                    <li>
                      Navigate to <strong>Certificates &amp; secrets</strong>.
                    </li>
                    <li>
                      Click on <strong>New client secret</strong>.
                    </li>
                    <li>Add a description and set an expiration period.</li>
                    <li>
                      Click <strong>Add</strong> and copy the generated client
                      secret value into the respective input above.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h4>Configure API Permissions</h4>
              <ol>
                <li>
                  Add API Permissions:
                  <ul>
                    <li>
                      Navigate to <strong>API permissions</strong>.
                    </li>
                    <li>
                      Click on <strong>Add a permission</strong>.
                    </li>
                    <li>
                      Select <strong>Microsoft Graph</strong>.
                    </li>
                    <li>
                      Choose <strong>Delegated permissions</strong>.
                    </li>
                    <li>
                      Add the <strong>User.Read</strong> permission.
                    </li>
                  </ul>
                </li>
                <li>
                  Grant Admin Consent:
                  <ul>
                    <li>
                      Click on{" "}
                      <strong>
                        Grant admin consent for &lt;Your Organization&gt;
                      </strong>
                      .
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Stack>
    </>
  );
};
const SSOOptions = [
  {
    value: "azure",
    label: "Microsoft Entra",
    img: AzureSSOIcon,
  },
];
const Configs = {
  azure: AzureConfig,
};

const SSOConfig = ({ onClose }) => {
  const fetcher = useFetcher();
  const [enabled, setEnabled] = React.useState(false);
  const [option, setOption] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const deleteConfig = async () => {
    setLoading(true);
    await fetcher.delete(
      "deleting SSO configuration",
      `/api/settings/sso`,
      ({ error }) => {
        if (error) return toast.error("error deleting SSO configuration");
        onClose();
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(false);
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete SSO Configuration`,
    message: `Are you sure you want to delete the SSO configuration? This action cannot be undone and users associated with this integration will no longer be able to login.`,
    callback: () => {
      deleteConfig();
    },
  });
  //get sso config
  const getConfig = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching SSO configuration",
      `/api/settings/sso`,
      ({ config }) => {
        if (!config) {
          setLoading(false);
          return;
        }
        //config exists
        setEnabled(true);
        setOption(config["type"]);
        setConfig(config);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getConfig();
  }, []);

  const ConfigurationComponent = Configs[option];
  if (loading)
    return (
      <div
        style={{
          marginTop: "100px",
          flex: 1,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <MiniLoader />
      </div>
    );

  return (
    <Stack p={2} gap={3}>
      <DeleteModal />
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            account_circle
          </Icon>
        </Avatar>
        <Typography variant="h4">Single Sign-on</Typography>
      </Stack>
      <Switch
        label={"Enable SSO"}
        checked={enabled}
        onChange={() => {
          setEnabled(!enabled);
        }}
      />
      {enabled ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Configuration</Typography>
          <Stack gap={1}>
            <Typography>Provider</Typography>
            <Grid gap={1} container>
              {SSOOptions.map((p) => {
                return (
                  <Grid
                    onClick={() => {
                      setOption(p?.value);
                    }}
                    sx={{ cursor: "pointer" }}
                    className="floating"
                    xs={12}
                    item
                  >
                    <Badge
                      sx={{ width: "100%" }}
                      badgeContent={
                        option === p?.value ? (
                          <Icon
                            sx={{
                              borderRadius: "15px",
                              backgroundColor: "#28ebc0",
                              color: "#262626",
                            }}
                          >
                            check
                          </Icon>
                        ) : null
                      }
                      color="primary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Stack
                        sx={{ width: "100%" }}
                        alignItems={"center"}
                        direction="row"
                        gap={1}
                      >
                        <Avatar
                          sx={{
                            width: 60,
                            height: 60,
                            marginRight: "10px",
                            background: "#262626",
                          }}
                        >
                          <img
                            height="40px"
                            width="40px"
                            style={{ marginBottom: "10px" }}
                            src={p?.img}
                            alt="logo"
                          />
                        </Avatar>
                        <Stack>
                          <Typography variant="body1">{p?.label}</Typography>
                        </Stack>
                      </Stack>
                    </Badge>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
          {ConfigurationComponent ? (
            <ConfigurationComponent config={config} onClose={onClose} />
          ) : null}
        </Stack>
      ) : null}
      {config ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ActionButton
            icon="delete"
            danger
            dark
            noload
            handler={async () => {
              openDeleteModal(true);
            }}
          >
            Delete SSO Configuration
          </ActionButton>
        </div>
      ) : null}
      <div style={{ height: "100px" }} />
    </Stack>
  );
};
// const AIConfig = ({ onClose }) => {
//   const fetcher = useFetcher();
//   const { tenants, loading: tenantsLoading, error, refresh } = useState(
//     "/api/tenants"
//   );

//   const [enabled, setEnabled] = React.useState(false);
//   const [exists, setExists] = React.useState(false);
//   const [tenant, setTenant] = React.useState(null);
//   const [loading, setLoading] = React.useState(false);
//   const [confirmed, setConfirmed] = React.useState(false);
//   // const deleteConfig = async () => {
//   //   setLoading(true);
//   //   await fetcher.delete(
//   //     "deleting SSO configuration",
//   //     `/api/settings/sso`,
//   //     ({ error }) => {
//   //       if (error) return toast.error("error deleting SSO configuration");
//   //       onClose();
//   //     },
//   //     () => {
//   //       setLoading(false);
//   //     }
//   //   );
//   //   setLoading(false);
//   // };
//   // const [openDeleteModal, DeleteModal] = useConfirm({
//   //   title: `Delete SSO Configuration`,
//   //   message: `Are you sure you want to delete the SSO configuration? This action cannot be undone and users associated with this integration will no longer be able to login.`,
//   //   callback: () => {
//   //     deleteConfig();
//   //   },
//   // });
//   const disableAIFeatures = async () => {
//     setLoading(true);
//     await fetcher.delete(
//       "disabling AI features",
//       `/api/settings/ai`,
//       ({ error }) => {
//         if (error) {
//           return toast.toast(error);
//         }
//         toast.success("Disabled AI features!");
//         setTenant(null);
//         setExists(false);
//         setConfirmed(false);
//       },
//       (e) => {}
//     );
//     setLoading(false);
//   };
//   const enableAIFeatures = async () => {
//     if (!tenant || !confirmed) return toast.error("Select a tenant");
//     setLoading(true);
//     await fetcher.post(
//       "enabling AI features",
//       `/api/settings/ai`,
//       { tenant },
//       ({ error }) => {
//         if (error) {
//           return toast.toast(error);
//         }
//         toast.success("Enabled AI features!");
//       },
//       (e) => {}
//     );
//     setLoading(false);
//   };
//   const confirmTenant = async () => {
//     if (!tenant) return toast.error("Select a tenant");
//     await fetcher.post(
//       "confirming tenant configuration",
//       `/api/settings/ai/confirm`,
//       { tenant },
//       ({ success }) => {
//         if (success) {
//           setConfirmed(true);
//           toast.success("tenant configuration confirmed");
//           return;
//         }
//         toast.error("tenant configuration check failed");
//         setConfirmed(false);
//       },
//       (e) => setConfirmed(false)
//     );
//   };
//   const getConfig = async () => {
//     setLoading(true);
//     await fetcher.get(
//       "fetching AI configuration",
//       `/api/settings/ai`,
//       ({ config }) => {
//         if (!config) {
//           setLoading(false);
//           return;
//         }
//         //config exists
//         setEnabled(true);
//         setTenant(config.tenant);
//         setExists(true);
//       },
//       () => {
//         setLoading(false);
//       }
//     );
//     setLoading(false);
//   };
//   React.useEffect(() => {
//     getConfig();
//   }, []);
//   if (loading || tenantsLoading)
//     return (
//       <div
//         style={{
//           marginTop: "100px",
//           flex: 1,
//           justifyContent: "center",
//           display: "flex",
//         }}
//       >
//         <MiniLoader />
//       </div>
//     );

//   return (
//     <Stack p={2} gap={3}>
//       <Stack>
//         <Stack alignItems={"center"} direction="row" gap={1}>
//           <Avatar
//             sx={{
//               width: 60,
//               height: 60,
//               marginRight: "10px",
//             }}
//           >
//             <img height="40px" width="40px" src={AiImage} />
//           </Avatar>
//           <Typography variant="h4">AI Configuration</Typography>
//         </Stack>
//         <Stack direction="row" justifyContent={"flex-end"}>
//           <Tag label="Experimental" />
//         </Stack>
//       </Stack>
//       <Stack className="floating" gap={3}>
//         <Typography>
//           To utilize AI features, an AWS tenant must be selected with the Claude
//           3.5 Sonnet model enabled in the Amazon Bedrock environment. The
//           selected tenant's Amazon Bedrock service will be used by this Echelon
//           One instance.
//         </Typography>
//         <MultiSelect
//           filterOptions={(o, args) => {
//             return o.filter((t) =>
//               !args?.inputValue
//                 ? o
//                 : `${t.id}${t.name}`.includes(args?.inputValue)
//             );
//           }}
//           options={tenants.filter((t) => t.type === "aws") || []}
//           loading={tenantsLoading}
//           getOptionLabel={(e) => `${e.id} (${e.name})`}
//           label="Tenant"
//           renderOption={(props, option) => (
//             <li {...props} key={option.pk}>
//               {`${option.id} (${option.name})`}
//             </li>
//           )}
//           getOptionKey={(k) => k.pk}
//           dark
//           multiple={false}
//           placeholder="select a tenant"
//           value={tenant}
//           setValue={(v) => setTenant({ id: v.id, name: v.name, pk: v.pk })}
//           sx={{ width: "100%" }}
//           size="small"
//         />
//       </Stack>
//       <Stack
//         direction="row"
//         gap={2}
//         style={{ display: "flex", justifyContent: "flex-end" }}
//       >
//         {exists ? (
//           <ActionButton
//             disabled={!exists}
//             icon="delete"
//             danger
//             dark
//             noload
//             handler={async () => {
//               disableAIFeatures();
//             }}
//           >
//             Disable AI Features
//           </ActionButton>
//         ) : null}
//         {confirmed ? (
//           <ActionButton
//             disabled={!confirmed}
//             icon="save"
//             dark
//             noload
//             handler={async () => {
//               enableAIFeatures();
//             }}
//           >
//             Enable AI Features
//           </ActionButton>
//         ) : (
//           <ActionButton
//             disabled={!tenant}
//             icon="science"
//             dark
//             handler={async () => {
//               await confirmTenant();
//             }}
//           >
//             Test Tenant Configuration
//           </ActionButton>
//         )}
//       </Stack>
//       {/* <DeleteModal />
//       <Stack alignItems={"center"} direction="row" gap={1}>
//         <Avatar
//           sx={{
//             width: 60,
//             height: 60,
//             marginRight: "10px",
//           }}
//         >
//           <Icon
//             style={{
//               fontSize: "40px",
//               color: theme.palette.primary.main,
//             }}
//           >
//             account_circle
//           </Icon>
//         </Avatar>
//         <Typography variant="h4">Single Sign-on</Typography>
//       </Stack>
//       <Switch
//         label={"Enable SSO"}
//         checked={enabled}
//         onChange={() => {
//           setEnabled(!enabled);
//         }}
//       />
//       {enabled ? (
//         <Stack className="floating" gap={3}>
//           <Typography variant="h5">Configuration</Typography>
//           <Stack gap={1}>
//             <Typography>Provider</Typography>
//             <Grid gap={1} container>
//               {SSOOptions.map((p) => {
//                 return (
//                   <Grid
//                     onClick={() => {
//                       setOption(p?.value);
//                     }}
//                     sx={{ cursor: "pointer" }}
//                     className="floating"
//                     xs={12}
//                     item
//                   >
//                     <Badge
//                       sx={{ width: "100%" }}
//                       badgeContent={
//                         option === p?.value ? (
//                           <Icon
//                             sx={{
//                               borderRadius: "15px",
//                               backgroundColor: "#28ebc0",
//                               color: "#262626",
//                             }}
//                           >
//                             check
//                           </Icon>
//                         ) : null
//                       }
//                       color="primary"
//                       anchorOrigin={{
//                         vertical: "top",
//                         horizontal: "right",
//                       }}
//                     >
//                       <Stack
//                         sx={{ width: "100%" }}
//                         alignItems={"center"}
//                         direction="row"
//                         gap={1}
//                       >
//                         <Avatar
//                           sx={{
//                             width: 60,
//                             height: 60,
//                             marginRight: "10px",
//                             background: "#262626",
//                           }}
//                         >
//                           <img
//                             height="40px"
//                             width="40px"
//                             style={{ marginBottom: "10px" }}
//                             src={p?.img}
//                             alt="logo"
//                           />
//                         </Avatar>
//                         <Stack>
//                           <Typography variant="body1">{p?.label}</Typography>
//                         </Stack>
//                       </Stack>
//                     </Badge>
//                   </Grid>
//                 );
//               })}
//             </Grid>
//           </Stack>
//           {ConfigurationComponent ? (
//             <ConfigurationComponent config={config} onClose={onClose} />
//           ) : null}
//         </Stack>
//       ) : null}
//       {config ? (
//         <div style={{ display: "flex", justifyContent: "flex-end" }}>
//           <ActionButton
//             icon="delete"
//             danger
//             dark
//             noload
//             handler={async () => {
//               openDeleteModal(true);
//             }}
//           >
//             Delete SSO Configuration
//           </ActionButton>
//         </div>
//       ) : null}
//       <div style={{ height: "100px" }} /> */}
//     </Stack>
//   );
// };
const Tile = ({ onClick, icon, children }) => {
  return (
    <Grid sx={{ position: "relative" }} className="user-card-title-ctn" item>
      <div className="floating user-card-tile">
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          {typeof icon === "string" ? (
            <Icon
              style={{
                fontSize: "40px",
                color: theme.palette.primary.main,
              }}
            >
              {icon}
            </Icon>
          ) : (
            icon
          )}
        </Avatar>
        <Stack>
          <span className="user-card-tile__name">{children}</span>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={onClick}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function Settings() {
  const [drawer, setDrawer] = React.useState(null);

  const SettingsDrawerNav = {
    brand: BrandingConfig,
    sso: SSOConfig,
    // ai: AIConfig,
  };
  return (
    <Page header="Settings">
      <SidePanel width={600} open={drawer} closeDrawer={() => setDrawer(null)}>
        {drawer ? SettingsDrawerNav[drawer] : null}
      </SidePanel>
      <Grid container gap={2}>
        <Grid item>
          <Tile icon="tour" onClick={() => setDrawer("brand")}>
            Branding
          </Tile>
        </Grid>
        <Grid item>
          <Tile icon="account_circle" onClick={() => setDrawer("sso")}>
            Single Sign On
          </Tile>
        </Grid>
        {/* <Grid item>
          <Tile
            icon={<img src={AiImage} height="40px" width="40px" />}
            onClick={() => setDrawer("ai")}
          >
            AI Configuration
          </Tile>
        </Grid> */}
      </Grid>
    </Page>
  );
}
