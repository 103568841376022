import React from "react";
import Page from "common/components/Page";
import { Stack, IconButton, Icon, Chip } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import useState from "common/hooks/useState";
import PopoverMenu from "common/components/PopoverMenu";
import useFolderView from "common/hooks/useFolderView";
import { ReactComponent as AwsLogo } from "assets/icons/aws-logo.svg";
import { ReactComponent as GcpLogo } from "assets/icons/gcp-logo.svg";
import { ReactComponent as AzureLogo } from "assets/icons/azure-logo.svg";
import useFetcher from "common/hooks/useFetcher";
import useConfirm from "common/hooks/useConfirm";
import QueryBuilder from "common/components/QueryBuilder";
import Table from "common/components/Table/table";
import SidePanel from "common/components/SidePanel";
import ModifyTarget from "./components/ModifyTarget";
import ActionButton from "common/components/ActionButton";
import CustomPlatforms from "./components/CustomPlatforms";
export const PlatformIconMap = {
  aws: AwsLogo,
  gcp: GcpLogo,
  azure: AzureLogo,
};

export default function Targets() {
  const { targets, loading, error, refresh } = useState("/api/targeting");
  const [modifyPanel, setModifyPanel] = React.useState(false);
  const [customModal, setCustomModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = React.useState([]);
  const fetcher = useFetcher();
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Target`,
    message: `Are you sure you want to delete this target? This action cannot be undone.`,
    callback: (_, data) => {
      deleteTarget(data?.id);
    },
  });
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const searchFilter = () => {
    if (!targets) return [];
    if (!filters.length && !folder) return targets;
    let filtered = [...targets];
    filtered = targets.filter((target) => {
      if (folder) {
        if (folder !== "default") {
          if (
            !target.tags
              .map((t) => t.toLowerCase())
              .includes(folder.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return filters.every(({ field, value }) => {
        if (field === "target") {
          return `${target.name.toLowerCase()}`.includes(value.toLowerCase());
        } else if (field === "tag") {
          return target.tags
            .map((t) => t.toLowerCase())
            .includes(value.toLowerCase());
        } else if (field === "crew") {
          return (
            (target?.owner_name?.toLowerCase() || "").includes(
              value.toLowerCase()
            ) ||
            (target?.owner?.toLowerCase() || "").includes(value.toLowerCase())
          );
        }
      });
    });
    return filtered;
  };

  const deleteTarget = async (id) => {
    await fetcher.delete("deleting target", `/api/targeting/${id}`, () => {
      refresh();
    });
  };

  const navigate = useNavigate();
  React.useEffect(() => {
    let id = searchParams.get("id");
    if (id) {
      setFilter(id);
    }
  }, [searchParams]);
  let filteredTargets = searchFilter();
  let [tags, tag_options] = filterFolders(filteredTargets, targets);
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating target tags",
      `/api/targeting/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  console.log(targets);
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Move Target",
                  onClick: () => {
                    setMoveFolder(data.pk);
                  },
                  icon: "move_up",
                },
                {
                  id: "Delete Target",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>
          <IconButton
            onClick={() => {
              setModifyPanel(data);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Target Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tenant Count",
      field: "tc",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Geo Count",
      field: "gc",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Owner",
      field: "owner_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tags",
      field: "tags",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })}
          </span>
        );
      },
    },
  ];
  return (
    <Page
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setModifyPanel(true);
          }}
        >
          Add Target
        </ActionButton>,
        <ActionButton
          icon="list"
          noload
          handler={async () => {
            navigate("/app/tenants");
          }}
        >
          Tenants
        </ActionButton>,
        <ActionButton
          icon="list"
          noload
          handler={async () => {
            setCustomModal(true);
          }}
        >
          Platforms
        </ActionButton>,
      ]}
      loading={[loading]}
      header="Targets"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Target"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        open={!!customModal}
        closeDrawer={() => setCustomModal(false)}
        props={{ refresh, target: modifyPanel }}
      >
        {CustomPlatforms}
      </SidePanel>
      <SidePanel
        open={!!modifyPanel}
        closeDrawer={() => setModifyPanel(false)}
        props={{ refresh, target: modifyPanel }}
      >
        {ModifyTarget}
      </SidePanel>
      <DeleteModal />
      <Stack p={2} direction={"row"}>
        <FolderHeader />
      </Stack>
      <QueryBuilder
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        defaultField="target"
        fields={[
          { id: "target", options: [] },
          {
            id: "tag",
            options: Array.from(
              new Set(targets?.reduce((a, t) => [...a, ...t.tags], []))
            ),
          },
          {
            id: "crew",
            options: Array.from(
              new Set(
                targets?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            ),
          },
        ]}
      />
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        <Stack>
          <Table
            data={filteredTargets}
            idProperty={"id"}
            columns={columns}
            noSelect
            noPaginate
            loading={loading}
            tableName={"e1targetlist"}
            tableHeight={700}
          />
        </Stack>
      )}
      <div style={{ height: "100px" }} />
    </Page>
  );
}
