import React from "react";
import SectionHeader from "common/components/SectionHeader";
import { Stack } from "@mui/material";
import AiIcon from "assets/icons/magic.svg";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import Background from "assets/poly_bg.svg";
import Tag from "common/components/Tag";
import QuickTasks from "common/components/QuickTasks";

const OPTIMIZE_PROMPT = `
1. FIRST perform a thorough code review that includes,
   - Current code structure and patterns
   - Performance bottlenecks
   - Readability issues
   - Potential bugs or edge cases
3. FINALLY, update the code with the optimizations:
   - Clear comments
   - Efficient algorithms
   - Improved structure
   - Prefer logic that is unnested
`;

export default function CodeGenerator({ system, action, onClose, setCode }) {
  const fetcher = useFetcher();

  const [loading, setLoading] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");
  const generateCode = async () => {
    if (prompt.length < 20) {
      return toast.error("Prompt must be at least 20 characters");
    }
    setLoading(true);
    await fetcher.post(
      "generating code",
      `/api/ai/command/code`,
      { prompt },
      ({ error, code }) => {
        if (error) {
          toast.error(error);
        }
        if (code) {
          setCode(code);
          onClose();
        }
      },
      () => {}
    );
    setLoading(false);
  };
  const changeCode = async (commandCode, passedPrompt, auto = false) => {
    if (!auto && prompt.length < 20) {
      return toast.error(
        "Prompt must be at least 20 characters and have at least 20 characters selected in code"
      );
    }
    setLoading(true);
    await fetcher.post(
      "refactoring code",
      `/api/ai/command/refactor`,
      { prompt: passedPrompt || prompt, commandCode },
      ({ error, code }) => {
        if (error) {
          toast.error(error);
        }
        if (code) {
          const range = editor.getSelectionRange();
          const modifiedText = code;
          editor.session.replace(range, modifiedText);
          setCode(window.editor.getValue());
          onClose();
        }
      },
      () => {}
    );
    setLoading(false);
  };
  return (
    <Stack sx={{ position: "relative" }} p={2} gap={2}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <SectionHeader
          icon={<img height="30px" width="30px" src={AiIcon} />}
          large
        >
          <span style={{ fontFamily: "ECHELONONE" }}>
            <strong>ECHELON</strong>ONE AI
          </span>
        </SectionHeader>
        <Tag label="Experimental" />
      </Stack>
      <Stack m={0} className="floating" gap={2}>
        <Input
          size="small"
          dark
          sx={{ width: "100%" }}
          placeholder="Enter a prompt for a command you want to generate"
          value={prompt}
          multiline
          minRows={10}
          maxRows={10}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <QuickTasks
          tasks={[
            {
              id: 1,
              label: "Comment to Code",
              content: "Convert comments to working code",
              type: "highlight",
            },
            {
              id: 2,
              label: "Optimize Code",
              content: OPTIMIZE_PROMPT,
              type: "highlight",
            },
            {
              id: 3,
              label: "Document Highlighted Code",
              content: "Add comments to code that describe what is happening",
              type: "highlight",
            },
          ]}
          onTaskSelect={(task) => {
            if (task.type === "highlight") {
              setPrompt(task.content);
              const selectedText = window.editor.getSelectedText();
              if (!selectedText) return toast.error("no code highlighted");
              changeCode(selectedText, task.content, true);
            }
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <ActionButton
            icon="code"
            dark
            noload
            handler={async () => {
              await generateCode();
            }}
          >
            Generate Function
          </ActionButton>
          <ActionButton
            icon="compress"
            dark
            noload
            handler={async () => {
              const selectedText = window.editor.getSelectedText();
              // const range = editor.getSelectionRange();
              // const modifiedText = `<<<${selectedText}>>>`;
              // editor.session.replace(range, modifiedText);
              // console.log(window.editor.getValue());
              changeCode(selectedText);
            }}
          >
            Modify Selection
          </ActionButton>
        </div>
      </Stack>
    </Stack>
  );
}
