import React from "react";
import {
  Stack,
  Chip,
  Paper,
  Typography,
  Icon,
  Autocomplete,
  IconButton,
} from "@mui/material";
import SectionHeader from "common/components/SectionHeader";
import MultiSelect from "common/components/MultiSelect";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { nanoid } from "nanoid";
import SidePanel from "common/components/SidePanel";
import MultiLevelMenu from "common/components/MultilevelMenu";
import List from "common/components/List";
import useConfirm from "common/hooks/useConfirm";

const AddFilter = ({
  fields,
  // saved,
  add,
  loading,
  // columns,
  edit,
  setEdit,
  update,
  // setManage,
  query,
  filters,
  defaultField = "",
  // clear,
  // queryBasedOnPassedFilters,
  // additionalOptions = [],
}) => {
  const [field, setField] = React.useState(defaultField);
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    if (!edit) return;
    console.log(edit);
    setField(edit.field);
    setValue(edit.value);
  }, [edit]);
  const onSubmit = () => {
    if (!field || !value) return toast.error("current filter not valid");
    setField("");
    setValue("");
    if (edit) {
      setEdit(null);
      return update({ ...edit, field, value });
    }
    add({ id: nanoid(), field, value });
  };
  return (
    <Stack direction="row" gap={1} alignItems={"center"}>
      <MultiSelect
        size="small"
        label="Field"
        sx={{ width: "200px" }}
        options={fields.map((f) => f.id)}
        multiple={false}
        value={field}
        shrink
        setValue={(e) => {
          setField(e);
        }}
      />
      <MultiSelect
        size="small"
        shrink
        loading={loading}
        label="Value"
        sx={{ width: "200px" }}
        options={fields.find((f) => f.id === field)?.options || []}
        multiple={false}
        freeSolo
        value={value}
        onInputChange={(e) => {
          if (e === null) return;
          let value = e?.target?.value || e?.target?.innerText;
          if (value === undefined) return setValue("");
          setValue(value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault(); // Prevent default behavior
            // Perform any action you want when Enter is pressed
            onSubmit();
          }
        }}
      />

      <ActionButton icon="add" noload handler={onSubmit}>
        {edit ? "Edit" : "Add"} Filter
      </ActionButton>
      {/* {filters?.length ? (
        <>
          <ActionButton handler={() => query()} noload icon="play_arrow">
            Apply Filter
          </ActionButton>
        </>
      ) : null} */}
    </Stack>
  );
};

export default function QueryBuilder({
  fields,
  query,
  columns,
  filters,
  loading,
  currentView,
  setFilters,
  clear,
  searchParam,
  saveSystem,
  system,
  queryBasedOnPassedFilters,
  additionalOptions = [],
  defaultField,
}) {
  const [edit, setEdit] = React.useState(null);

  const addFilter = (filter) => {
    setFilters((f) => [...f, filter]);
    setEdit(false);
  };
  const updateFilter = (filter) => {
    setFilters(
      filters?.map((f) => {
        return f.id !== filter.id ? f : filter;
      })
    );
  };
  const removeFilter = (id) => {
    setFilters((f) => f.filter((f) => f.id !== id));
    setEdit(false);
  };

  return (
    <div>
      <Stack gap={2}>
        <Stack direction="row" sx={{ width: "100%" }} flexWrap="wrap" gap={1}>
          {filters?.length ? (
            <Chip onDelete={() => setFilters([])} label="Clear Filters" />
          ) : (
            <div style={{ height: "40px" }} />
          )}
          {filters?.map((f) => {
            return (
              <Chip
                color="secondary"
                sx={{ fontSize: "0.8.7", height: "40px" }}
                clickable
                onClick={() => setEdit(f)}
                key={f.id}
                label={`${f.field} ~ "${f.value}"`}
                onDelete={() => removeFilter(f.id)}
              />
            );
          })}
        </Stack>
        <AddFilter
          defaultField={defaultField || ""}
          fields={fields}
          loading={loading}
          // additionalOptions={additionalOptions}
          // queryBasedOnPassedFilters={queryBasedOnPassedFilters}
          // saved={system?.queries || []}
          add={addFilter}
          filters={filters}
          // columns={columns}
          edit={edit}
          setEdit={setEdit}
          update={updateFilter}
          setFilters={setFilters}
          // setManage={setManage}
          query={query}
          // clear={clear}
        />

        <Stack direction="row" gap={2}>
          {/* {searchParam ? (
            <ActionButton danger icon="clear" noload handler={clear}>
              Clear Filter
            </ActionButton>
          ) : null} */}
          {/* <ActionButton icon="download" noload handler={async () => {}}>
            Load Filter
          </ActionButton> */}
        </Stack>
      </Stack>
    </div>
  );
}
