import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Input from "common/components/Input";

export default function MultiSelect({
  multiple = true,
  options = [],
  freeSolo,
  shrink,
  getOptionLabel,
  clearHandler,
  label,
  placeholder,
  value,
  size,
  onKeyDown = () => {},
  setValue = () => {},
  dark,
  inputProps = {},
  ...params
}) {
  const ref = React.useRef();
  React.useEffect(() => {
    if (!ref.current || window.muiRefocus !== label) return;
    delete window.muiRefocus;
    ref.current.getElementsByClassName("MuiAutocomplete-input")[0].focus();
  }, [value]);
  return (
    <Autocomplete
      id={label}
      // autoFocus
      multiple={multiple}
      {...{ options, getOptionLabel, freeSolo }}
      value={value}
      filterSelectedOptions
      PaperComponent={({ children }) => (
        <Paper
          style={{ background: "#262626", color: "rgba(239,237,239,0.87)" }}
        >
          {children}
        </Paper>
      )}
      ref={ref}
      ChipProps={{ color: "secondary" }}
      onChange={(e, value) => {
        if (!value && clearHandler) {
          return clearHandler();
        }
        e.preventDefault();
        setValue(value);
        window.muiRefocus = label;
      }}
      renderInput={(p) => (
        <Input
          {...{ label, placeholder }}
          {...p}
          {...inputProps}
          {...(shrink ? { InputLabelProps: { shrink: true } } : {})}
          size={size}
          onKeyDown={onKeyDown}
          dark={dark}
        />
      )}
      {...params}
    />
  );
}
