import React from "react";
import { Stack, Typography } from "@mui/material";
import Table from "common/components/Table/table";
import useFetcher from "common/hooks/useFetcher";
import ActionButton from "common/components/ActionButton";
export default function SelectGeos({ geos, platform, setGeos, onClose }) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(true);
  const [availableGeos, setAvailableGeos] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedResources, setSelectedResources] = React.useState(
    new Set(geos || [])
  );
  const [unselectedResources, setUnselectedResources] = React.useState(
    new Set()
  );

  const getGeos = async () => {
    setLoading(true);
    setAvailableGeos([]);
    await fetcher.get(
      "fetching geographies",
      `/api/geo/${platform}`,
      (data) => {
        setAvailableGeos(data?.geos || []);
        console.log(geos);
        if (geos.length === data?.geos?.length) {
          setSelectAll(true);
        } else {
          setSelectedResources(new Set(geos || []));
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getGeos();
  }, []);
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack gap={1}>
          <Typography variant="h4">Select Geographies</Typography>
          <Typography variant="body2">
            Pick geographies that should be assoicated with the target.
          </Typography>
        </Stack>
      </Stack>
      <div>
        <ActionButton
          icon="check"
          sx={{ marginLeft: "auto" }}
          dark
          noload
          handler={async () => {
            let selected = [];
            if (selectAll) {
              selected =
                availableGeos
                  ?.filter(
                    (x) => !Array.from(unselectedResources).includes(x.code)
                  )
                  ?.map((x) => x.code) || [];
            } else {
              selected = Array.from(selectedResources);
            }
            setGeos(selected);
            onClose();
          }}
        >
          Done
        </ActionButton>
      </div>
      <Stack gap={1}>
        <Table
          filter={false}
          data={availableGeos || []}
          idProperty={"code"}
          columns={[
            {
              headerName: "Name",
              field: "name",
              minWidth: 200,
              resizable: true,
              flex: 1,
            },
            {
              headerName: "Code",
              field: "code",
              minWidth: 200,
              resizable: true,
              flex: 1,
            },
          ]}
          loading={loading}
          noPaginate
          tableName={"e1targetgeostable"}
          tableHeight={600}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedRowIds={selectedResources}
          setSelectedRowIds={setSelectedResources}
          unselectedRowIds={unselectedResources}
          setUnselectedRowIds={setUnselectedResources}
        />
      </Stack>
    </Stack>
  );
}
