import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@mui/material";

const PopoverMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: "#fff !important" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map(({ id, onClick, icon, color }) => (
          <MenuItem key={id} onClick={onClick}>
            {icon ? (
              <Icon
                sx={{
                  color: `${color ? color : "inherit"} !important`,
                  marginRight: "8px",
                }}
              >
                {icon}
              </Icon>
            ) : null}
            {id}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PopoverMenu;
