import React from "react";
import Page from "common/components/Page";
import { Stack, IconButton, Icon, Chip } from "@mui/material";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import QueryBuilder from "common/components/QueryBuilder";
import useConfirm from "common/hooks/useConfirm";
import useFolderView from "common/hooks/useFolderView";
import CreatePackage from "./components/CreatePackage";
import Table from "common/components/Table/table";
import PopoverMenu from "common/components/PopoverMenu";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Packages({ role }) {
  const { packages, loading, error, refresh } = useState("/api/pkg");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addPackage, setAddPackage] = React.useState(false);
  const fetcher = useFetcher();
  const [filters, setFilters] = React.useState([]);
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const deletePackage = async (id) => {
    await fetcher.delete("deleting package", `/api/pkg/${id}`, () => {
      refresh();
    });
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Package`,
    message: `Are you sure you want to delete this package? This action cannot be undone.`,
    callback: (_, data) => {
      deletePackage(data?.name);
    },
  });
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating package tags",
      `/api/pkg/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const searchFilter = () => {
    if (!packages) return [];
    if (!filters.length && !folder) return packages;
    let filtered = [...packages];
    filtered = packages.filter((pkg) => {
      if (folder) {
        if (folder !== "default") {
          if (
            !pkg.tags.map((t) => t.toLowerCase()).includes(folder.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return filters.every(({ field, value }) => {
        if (field === "package") {
          return `${pkg.name.toLowerCase()}${pkg.description.toLowerCase()}`.includes(
            value.toLowerCase()
          );
        } else if (field === "tag") {
          return pkg.tags
            .map((t) => t.toLowerCase())
            .includes(value.toLowerCase());
        } else if (field === "crew") {
          return (
            (pkg?.owner_name?.toLowerCase() || "").includes(
              value.toLowerCase()
            ) || (pkg?.owner?.toLowerCase() || "").includes(value.toLowerCase())
          );
        }
      });
    });
    return filtered;
  };

  let filteredPackages = searchFilter();
  let [tags, tag_options] = filterFolders(filteredPackages, packages);
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Move Package",
                  onClick: () => {
                    setMoveFolder(data.name);
                  },
                  icon: "move_up",
                },
                {
                  id: "Delete Package",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>
          <IconButton
            onClick={() => {
              navigate(`/app/packages/${data.name}`);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Package Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Latest Version",
      field: "latest",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Owner",
      field: "owner_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tags",
      field: "tags",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })}
          </span>
        );
      },
    },
  ];
  console.log(packages);
  return (
    <Page
      header="Packages"
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setAddPackage(true);
          }}
        >
          Add Package
        </ActionButton>,
      ]}
      loading={[loading]}
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Package"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="500px"
        open={addPackage}
        closeDrawer={() => setAddPackage(false)}
        props={{}}
      >
        {CreatePackage}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <DeleteModal />
      </Stack>
      <QueryBuilder
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        defaultField="package"
        fields={[
          { id: "package", options: [] },
          {
            id: "tag",
            options: Array.from(
              new Set(packages?.reduce((a, t) => [...a, ...t.tags], []))
            ),
          },
          {
            id: "crew",
            options: Array.from(
              new Set(
                packages?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            ),
          },
        ]}
      />
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        <Stack spacing={2}>
          <Table
            data={filteredPackages}
            idProperty={"id"}
            columns={columns}
            noSelect
            noPaginate
            loading={loading}
            tableName={"e1pkglist"}
            tableHeight={700}
          />
        </Stack>
      )}
    </Page>
  );
}
