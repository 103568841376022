import React from "react";
import { Stack, Typography } from "@mui/material";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import FileUpload from "common/components/FileUpload";
import MarkdownEditor from "common/components/MarkdownEditor";
import SectionHeader from "common/components/SectionHeader";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import useFetcher from "common/hooks/useFetcher";
import { MiniLoader } from "common/components/Loader";
import { CheckboxList } from "common/components/CheckList";
export default function AstroHubPublish({ system, onClose }) {
  const {
    tags,
    TagInput,
    loading: attrLoading,
    setInit,
  } = useScopeAttributes();
  // const [description, setDescription] = React.useState(
  //   system?.description || ""
  // );
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(system?.name || "");
  const [tagline, setTagline] = React.useState("");
  const [version, setVersion] = React.useState("1");
  const [files, setFiles] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [dispatching, setDispatching] = React.useState(false);
  const [published, setPublished] = React.useState(false);
  // const [imageFile, setImageFile] = React.useState(null);
  const fetcher = useFetcher();
  const onPublish = async (close) => {
    setDispatching(true);
    let payload = { name, tagline, tags, files: checkedItems };
    // payload.details = description;
    // payload.metrics = {
    //   actions: system?.actions?.length || 0,
    //   graphs: system?.graphs?.length || 0,
    //   detectors: system?.detectors?.length || 0,
    //   triggers:
    //     system?.detectors?.reduce((a, c) => [...a, ...(c?.triggers || [])], [])
    //       .length || 0,
    // };
    //validation
    if (tags.length > 3) {
      setDispatching(false);
      return toast.error("Can only have 3 tags for a published system");
    }
    if (tags.length === 0) {
      setDispatching(false);
      return toast.error("Must have at least 1 tag for a published system");
    }
    // if (description.length < 50) {
    //   setDispatching(false);
    //   return toast.error("Description must be at least 50 characters");
    // }
    if (tagline.length < 10 || tagline.length > 50) {
      setDispatching(false);
      return toast.error("Tagline must be between 10 and 50 characters");
    }
    if (!name || !tagline) {
      setDispatching(false);
      return toast.error("Name and Tagline must exist");
    }
    // if (!imageFile && !published) {
    //   setDispatching(false);
    //   return toast.error("Must upload an image");
    // }
    await fetcher.post(
      "publishing system",
      `/api/store/publish/${system?.id}`,
      () => {
        let form = new FormData();
        form.append("json", JSON.stringify(payload));
        // if (imageFile) {
        //   form.append("Image", imageFile);
        // }
        return form;
      },
      ({ error, log, job, hook }) => {
        if (error) {
          toast.error(error);
          setDispatching(false);
          return setLoading(false);
        }
        setDispatching(false);
      },
      () => {
        setDispatching(false);
        setLoading(false);
      }
    );
    onClose();
  };
  const getFiles = async () => {
    await fetcher.get(
      "fetching system files",
      `/api/sys/${system.id}/file`,
      (files) => {
        try {
          let sorted = [...files];
          sorted = sorted.sort((a, b) => {
            // Convert string dates into `Date` objects
            const dateA = new Date(a.last_modified);
            const dateB = new Date(b.last_modified);

            // Subtract dateB from dateA to sort in descending order
            return dateB - dateA;
          });

          setFiles(sorted);
        } catch {
          setFiles([]);
        }
      },
      () => {}
    );
  };
  const fetchSystem = async (id) => {
    setLoading(true);
    try {
      await getFiles();
      await fetcher.get(
        "fetching system",
        `/api/store/system/${system.id}`,
        ({ system }) => {
          if (!system) return;
          setPublished(true);
          // setDescription(system.details || "");
          setName(system.name || "");
          setVersion(system.version ? +`${system.version}`[0] + 1 : "1");
          setTagline(system.tagline || "");
          setInit(system?.tags || [], []);
        },
        () => setLoading(false)
      );
    } catch {}
    setLoading(false);
  };
  React.useEffect(() => {
    if (!open) return;
    fetchSystem(open);
  }, [open]);
  if (loading) {
    return (
      <div
        style={{
          marginTop: "50px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MiniLoader />
        <Typography sx={{ marginTop: "70px" }}>
          Getting ready for launch!
        </Typography>
      </div>
    );
  }
  return (
    <Stack p={2} gap={3}>
      <SectionHeader large icon="rocket_launch">
        Publish To AstroHub
      </SectionHeader>
      <Stack className="floating" gap={3}>
        {/* <Stack>
          <Typography variant="h5">Description</Typography>
        </Stack> */}
        <Stack gap={3}>
          <Input
            size="small"
            dark
            label="Name"
            sx={{ width: "100%" }}
            placeholder="system name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            label="Tagline"
            sx={{ width: "100%" }}
            placeholder="system tagline"
            value={tagline}
            onChange={(e) => setTagline(e.target.value)}
          />
          {/* <Typography style={{ fontSize: "14px" }}>Description</Typography> */}
          {/* <MarkdownEditor
            {...{ markdown: description, setMarkdown: setDescription }}
          /> */}
          {files?.length ? (
            <CheckboxList
              title="Select Files to publish with System"
              items={files}
              checkedItems={checkedItems}
              getItemLabel={(item) => item.name}
              getItemValue={(item) => item.key}
              onChange={setCheckedItems}
            />
          ) : null}
          <TagInput />
          {/* <FileUpload
            sizeInKb={600}
            file={imageFile}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            handleFileChange={setImageFile}
          >
            {`Upload ${published ? "New" : ""} Store Image`}
          </FileUpload> */}
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          icon="save"
          dark
          noload
          handler={async () => {
            await onPublish();
          }}
        >
          Publish Version {version}
        </ActionButton>
      </div>
      <div style={{ height: "100px" }} />
    </Stack>
  );
}
