import React from "react";
import Page from "common/components/Page";
import { Stack, Icon, IconButton, Chip, Avatar } from "@mui/material";
import useState from "common/hooks/useState";
import { ReactComponent as AwsLogo } from "assets/icons/aws-logo.svg";
import { ReactComponent as GcpLogo } from "assets/icons/gcp-logo.svg";
import { ReactComponent as AzureLogo } from "assets/icons/azure-logo.svg";
import useFetcher from "common/hooks/useFetcher";
import AssetImage from "common/components/AssetImage";
import SidePanel from "common/components/SidePanel";
import ActionButton from "common/components/ActionButton";
import useConfirm from "common/hooks/useConfirm";
import AddTenant from "./components/AddTenant";
import ModifyTenant from "./components/ModifyTenant";
import useFolderView from "common/hooks/useFolderView";
import PopoverMenu from "common/components/PopoverMenu";
import Table from "common/components/Table/table";
import QueryBuilder from "common/components/QueryBuilder";
import { useSearchParams } from "react-router-dom";
const PlatformIconMap = {
  aws: AwsLogo,
  gcp: GcpLogo,
  azure: AzureLogo,
};

export default function Tenants() {
  const { tenants, loading, error, refresh } = useState("/api/tenants");
  const [newTenant, setNewTenant] = React.useState(false);
  const [modTenant, setModTenant] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const fetcher = useFetcher();
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const deleteTenant = async (id) => {
    await fetcher.delete(
      `deleting tenant`,
      `/api/tenants/${id}`,
      () => refresh(),
      () => {}
    );
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Tenant`,
    message: `Are you sure you want to delete this tenant? This action cannot be undone.`,
    callback: (_, target) => {
      deleteTenant(target?.pk);
    },
  });
  const searchFilter = () => {
    if (!tenants) return [];
    if (!filters.length && !folder) return tenants;
    let filtered = [...tenants];
    filtered = tenants.filter((tenant) => {
      if (folder) {
        if (folder !== "default") {
          if (
            !tenant.tags
              .map((t) => t.toLowerCase())
              .includes(folder.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return filters.every(({ field, value }) => {
        if (field === "tenant") {
          return `${tenant.name.toLowerCase()}${tenant.id.toLowerCase()}`.includes(
            value.toLowerCase()
          );
        } else if (field === "tag") {
          return tenant.tags
            .map((t) => t.toLowerCase())
            .includes(value.toLowerCase());
        } else if (field === "crew") {
          return (
            (tenant?.owner_name?.toLowerCase() || "").includes(
              value.toLowerCase()
            ) ||
            (tenant?.owner?.toLowerCase() || "").includes(value.toLowerCase())
          );
        }
      });
    });
    return filtered;
  };
  let filteredTenants = searchFilter();
  let [tags, tag_options] = filterFolders(filteredTenants, tenants);
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating tenant tags",
      `/api/tenants/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Move Target",
                  onClick: () => {
                    setMoveFolder(data.pk);
                  },
                  icon: "move_up",
                },
                {
                  id: "Delete Target",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>
          <IconButton
            onClick={() => {
              setModTenant(data);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Platform",
      field: "type",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <Avatar
              sx={{
                background: "#262626",
                width: 40,
                height: 40,
              }}
            >
              {React.createElement(PlatformIconMap[data.type] ?? AssetImage, {
                ...{
                  style: { width: "40px" },
                  path: `/api/assets/instance/platforms/${data.type}/logo.png`,
                },
              })}
            </Avatar>
            {/* {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })} */}
          </span>
        );
      },
    },
    {
      headerName: "Tenant Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tenant Id",
      field: "id",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Owner",
      field: "owner_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Tags",
      field: "tags",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })}
          </span>
        );
      },
    },
  ];
  return (
    <Page
      loading={loading}
      back="/app/targets"
      header="Tenants"
      refresh={refresh}
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setNewTenant(true);
          }}
        >
          Add Tenant
        </ActionButton>,
      ]}
    >
      <DeleteModal />
      <TagUpdateDialog
        title="Tenant"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="600px"
        open={modTenant}
        closeDrawer={() => setModTenant(false)}
        props={{ tenant: modTenant, refresh }}
      >
        {ModifyTenant}
      </SidePanel>
      <SidePanel
        width="600px"
        open={newTenant}
        closeDrawer={() => setNewTenant(false)}
        props={{ refresh }}
      >
        {AddTenant}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
      </Stack>
      <Stack spacing={2}>
        <QueryBuilder
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          defaultField="tenant"
          fields={[
            { id: "tenant", options: [] },
            {
              id: "tag",
              options: Array.from(
                new Set(tenants?.reduce((a, t) => [...a, ...t.tags], []))
              ),
            },
            {
              id: "crew",
              options: Array.from(
                new Set(
                  tenants?.reduce(
                    (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                    []
                  )
                )
              ),
            },
          ]}
        />
        {!folder ? (
          <FolderView tags={tags} />
        ) : (
          <Stack spacing={2}>
            <Table
              data={filteredTenants}
              idProperty={"id"}
              columns={columns}
              noSelect
              noPaginate
              loading={loading}
              tableName={"e1tenantlist"}
              tableHeight={700}
            />
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
