import React from "react";
import {
  Stack,
  IconButton,
  Icon,
  Typography,
  Card,
  Button,
} from "@mui/material";
import MultiSelect from "common/components/MultiSelect";
import DnDList from "common/components/DndList";
import { TypeMap } from "constants";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import List from "common/components/List";
import { nanoid } from "nanoid";
import Blurb from "constants/blurbs";
import E1Input from "common/components/E1Input";
import useForm from "common/hooks/useForm";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import Switch from "common/components/Switch";
import Checkbox from "common/components/Checkbox";
import toast from "react-hot-toast";
const InputTypeMap = {
  ...TypeMap,
  dropdown: {
    name: "Dropdown",
    icon: "dropdown",
    desc: "List of strings for a variable option set.",
    type: "dropdown",
    default: "[]",
  },
  file: {
    name: "File",
    icon: "upload_file",
    desc: "Select File to upload",
    type: "file",
    default: null,
  },
};
export default function CommandInput({ data, setData }) {
  const [name, setName] = React.useState("");
  const [help, setHelp] = React.useState("");
  const [required, setRequired] = React.useState(false);
  const [reorder, setReorder] = React.useState(false);
  const [type, setType] = React.useState("str");
  const [inputs, setInputs] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");
  const { controller, getFormValues } = useForm("default");
  let [selectedInput, setSelectedInput] = React.useState(null);
  const existing = selectedInput;
  React.useEffect(() => {
    if (!data) return;
    setInputs(data.inputs);
  }, [data]);
  const onSave = (close) => {
    let alreadyExistingInput = inputs.find(
      (input) => input.name === name.trim()
    );
    if (alreadyExistingInput) {
      if (selectedInput !== alreadyExistingInput?.id) {
        return toast.error("input name already exists");
      }
    }
    if (!validate("command_input_edit", { name, help })) return;
    let defaultValue = getFormValues()?.defaultValue;
    if (type === "dropdown") {
      try {
        let data = JSON.parse(defaultValue);
        if (!Array.isArray(data)) {
          return toast.error("dropdown options are not formatted correctly");
        }
        if (data?.length < 2) {
          return toast.error("there must be at least 2 options for dropdown");
        }
      } catch {
        return toast.error("dropdown options are not formatted correctly");
      }
    }
    if (existing) {
      setData({
        ...data,
        inputs: inputs.map((i) => {
          if (i.id !== existing) return i;
          return {
            name: name.trim(),
            help,
            type,
            required,
            id: existing,
            defaultValue: type == "bool" ? false : defaultValue,
          };
        }),
      });
    } else {
      const newInput = {
        name,
        help,
        type,
        id: nanoid(),
        required,
        defaultValue: type == "bool" ? false : defaultValue,
      };
      setData({ ...data, inputs: [...data.inputs, newInput] });
    }
    close();
  };
  const deleteInput = (id) => {
    setData({ ...data, inputs: inputs.filter((i) => i.id !== id) });
  };
  const onEdit = ({ id, name, help, type, required, defaultValue }) => {
    setName(name);
    setHelp(help);
    setType(type);
    setSelectedInput(id);
    setDefaultValue(defaultValue || "");
    setRequired(!!required);
    setEdit(true);
  };
  const updateOrder = (updateFunc) =>
    setData((data) => ({ ...data, inputs: updateFunc(data.inputs) }));
  return (
    <Stack m={0} gap={1} p={0}>
      <Stack sx={{ position: "relative" }} spacing={1}>
        <Modal
          forceOpen={edit}
          onClose={() => {
            setName("");
            setHelp("");
            setType("str");
            setEdit(false);
            setRequired(false);
            setSelectedInput(null);
          }}
          button={(props) => (
            <Stack
              sx={{ position: "absolute", right: 0 }}
              direction="row"
              spacing={1}
            >
              <IconButton {...props}>
                <Icon>add</Icon>
              </IconButton>
            </Stack>
          )}
        >
          {({ handleClose }) => (
            <Stack spacing={3}>
              <Typography variant="h5">
                {existing ? "Update" : "New"} Input
              </Typography>
              <Input
                sx={{ width: "100%" }}
                label="Name"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Blurb>command_inputs_help</Blurb>
              <Input
                size="small"
                sx={{ width: "100%" }}
                label="Help Text"
                value={help}
                onChange={(e) => setHelp(e.target.value)}
                multiline={true}
                minRows={3}
                maxRows={3}
              />
              <Blurb>command_inputs_type</Blurb>
              <MultiSelect
                size="small"
                sx={{ width: "100%" }}
                getOptionLabel={(e) => InputTypeMap[e]?.name || ""}
                label="Select Property Type"
                options={Object.keys(InputTypeMap)}
                multiple={false}
                value={type}
                setValue={(e) => {
                  setType(e);
                }}
              />
              {!["bool", "dropdown", "file"].includes(type) ? (
                <E1Input
                  key={defaultValue}
                  sx={{ width: "100%" }}
                  type={type}
                  label={"Default Value (Optional)"}
                  defaultValue={defaultValue}
                  inputProps={{ ...controller("defaultValue") }}
                />
              ) : null}
              {["dropdown"].includes(type) ? (
                <E1Input
                  key={defaultValue}
                  sx={{ width: "100%" }}
                  type={"lstr"}
                  label={"Option List"}
                  defaultValue={defaultValue}
                  inputProps={{ ...controller("defaultValue") }}
                />
              ) : null}
              <Checkbox
                text="Required"
                value={required}
                onChange={(value) => {
                  setRequired(value);
                }}
                id="required-toggle"
              />

              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  noload
                  handler={() => {
                    onSave(handleClose);
                  }}
                  variant="save"
                >
                  Save Input
                </ActionButton>
              </div>
            </Stack>
          )}
        </Modal>

        <Blurb>command_inputs</Blurb>
        {!inputs.length ? (
          <Typography sx={{ margin: 0, paddingLeft: 1 }} variant="body1">
            -
          </Typography>
        ) : (
          <DnDList
            state={[inputs, updateOrder]}
            itemComponent={({ item }) => (
              <Stack
                sx={{ width: "100%" }}
                ml={2}
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack alignItems={"center"}>
                  <span style={{ marginRight: "10px", width: "100%" }}>
                    {item.name}
                  </span>
                  {/* <Typography variant="caption" sx={{ color: "grey" }}>
                    ({item.id})
                  </Typography> */}
                </Stack>
                <Stack direction="row">
                  <IconButton onClick={() => onEdit(item)} color="primary">
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => deleteInput(item.id)}
                    color="error"
                  >
                    <Icon color="error">close</Icon>
                  </IconButton>
                </Stack>
              </Stack>
            )}
          />
        )}
      </Stack>
    </Stack>
  );
}
