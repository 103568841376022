import React from "react";
import Page from "common/components/Page";
import { Stack, IconButton, Icon, Typography, Avatar } from "@mui/material";
import { MiniLoader } from "common/components/Loader";
import { useParams } from "react-router-dom";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import InputAdornment from "@mui/material/InputAdornment";
import toast from "react-hot-toast";
import Input from "common/components/Input";
import useConfirm from "common/hooks/useConfirm";
import { RolesMap } from "./components/AddUser";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import { theme } from "theme";
import Switch from "common/components/Switch";
import Table from "common/components/Table/table";

const UserConfig = ({ user, onClose, saveUser, deleteUser }) => {
  const [pass, setPass] = React.useState("");
  const [confirmpass, setConfirmPass] = React.useState("");
  const [role, setRole] = React.useState(null);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete User`,
    message: `Are you sure you want to delete this ${user?.username}? This action can't be undone.`,
    callback: () => {
      onClose();
      deleteUser(user?.id);
    },
  });
  return (
    <Stack p={2} gap={3}>
      <DeleteModal />
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            account_circle
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">User Management</Typography>
          <Typography variant="caption">{user?.username}</Typography>
        </Stack>
      </Stack>
      <Switch label={"SSO Enabled"} checked={!!user?.sso} disabled />
      {!user?.sso ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Password Reset</Typography>
          <Stack gap={1}>
            <Typography>
              When creating your new password, choose a strong combination that
              includes uppercase and lowercase letters, numbers, and symbols.
            </Typography>
          </Stack>
          <Stack gap={3}>
            <Input
              size="small"
              dark
              sx={{ width: "100%" }}
              label="Password"
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <Input
              size="small"
              dark
              sx={{ width: "100%", marginTop: "20px" }}
              label="Confirm Password"
              type="password"
              value={confirmpass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </Stack>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="password"
              dark
              noload={!pass || pass !== confirmpass}
              handler={async () => {
                if (!pass) {
                  return toast.error("enter a password value");
                }
                if (pass === confirmpass) {
                  await saveUser({ ...user, password: pass });
                  setPass("");
                  setConfirmPass("");
                } else {
                  return toast.error("passwords must match");
                }
              }}
            >
              Reset Password
            </ActionButton>
          </div>
        </Stack>
      ) : null}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Update Role</Typography>
        <Stack gap={1}>
          <Typography sx={{ fontWeight: "bold" }}>Current Role</Typography>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Avatar
              sx={{
                width: 55,
                height: 55,
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              <img
                height="55px"
                width="55px"
                src={RolesMap[user?.role ?? "admin"].icon}
              />
            </Avatar>
            <Typography>{user?.role}</Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography>
            Select the desired role from the available options and confirm the
            changes to update the user's access permissions.
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2}>
          {["admin", "engineer", "operator", "auditor"]
            .filter((x) => x !== user?.role)
            .map((r, i) => {
              return (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Avatar
                    onClick={() => setRole(r)}
                    key={i}
                    sx={{
                      border:
                        r === role
                          ? `3px solid ${theme.palette.success.main}`
                          : "initial",
                      width: 50,
                      height: 50,
                    }}
                  >
                    <img height="50px" width="50px" src={RolesMap[r].icon} />
                  </Avatar>
                  <Typography variant="caption">{r}</Typography>
                </Stack>
              );
            })}
        </Stack>
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            disabled={!role}
            icon="save"
            dark
            handler={async () => {
              if (role !== user.role) {
                await saveUser({ ...user, role });
                onClose();
              }
              setRole(false);
            }}
          >
            Update Role
          </ActionButton>
        </div>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Delete User</Typography>
        <Stack gap={1}>
          <Typography>
            Deleting an account is permanent and cannot be undone.
          </Typography>
        </Stack>
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            danger
            noload
            icon="crisis_alert"
            dark
            handler={async () => {
              openDeleteModal(true);
            }}
          >
            Delete Account
          </ActionButton>
        </div>
      </Stack>
    </Stack>
  );
};
const AddUser = ({ user, addUser, crew, onClose }) => {
  const fetcher = useFetcher();
  const { type, loading: SSOLoading } = useState("/api/settings/sso/option");
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [confirmpass, setConfirmPass] = React.useState("");
  const [ssoEnabled, setSSOEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState("auditor");
  const submit = async () => {
    if (pass !== confirmpass) return toast.error("Passwords do not match");
    setLoading(true);
    let payload = { role, crew, name: email, password: pass, sso: ssoEnabled };
    let result = await addUser(payload);
    setLoading(false);
    if (!result) return;
    onClose();
  };
  if (loading || SSOLoading)
    return (
      <div
        style={{
          marginTop: "100px",
          flex: 1,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <MiniLoader />
      </div>
    );
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            account_circle
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">Add User</Typography>
        </Stack>
      </Stack>
      {type ? (
        <Switch
          label={"SSO Enabled"}
          checked={ssoEnabled}
          onChange={() => {
            setSSOEnabled(!ssoEnabled);
          }}
        />
      ) : null}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Email</Typography>
        <Stack gap={3}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Stack>
      </Stack>
      {!ssoEnabled ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Password</Typography>
          <Stack gap={1}>
            <Typography>
              When creating your password, choose a strong combination that
              includes uppercase and lowercase letters, numbers, and symbols.
            </Typography>
          </Stack>
          <Stack gap={3}>
            <Input
              size="small"
              dark
              sx={{ width: "100%" }}
              label="Password"
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <Input
              size="small"
              dark
              sx={{ width: "100%", marginTop: "20px" }}
              label="Confirm Password"
              type="password"
              value={confirmpass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </Stack>
        </Stack>
      ) : null}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Select Role</Typography>
        <Stack gap={1}>
          <Typography>
            Select the desired role from the available options
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={2}>
          {["admin", "engineer", "operator", "auditor"]
            .filter((x) => x !== user?.role)
            .map((r, i) => {
              return (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Avatar
                    onClick={() => setRole(r)}
                    key={i}
                    sx={{
                      border:
                        r === role
                          ? `3px solid ${theme.palette.success.main}`
                          : "initial",
                      width: 50,
                      height: 50,
                    }}
                  >
                    <img height="50px" width="50px" src={RolesMap[r].icon} />
                  </Avatar>
                  <Typography variant="caption">{r}</Typography>
                </Stack>
              );
            })}
        </Stack>
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            icon="save"
            dark
            noload
            handler={async () => {
              submit();
            }}
          >
            Create User
          </ActionButton>
        </div>
      </Stack>
    </Stack>
  );
};
export default function Crew() {
  const [drawer, setDrawer] = React.useState(null);
  const { id } = useParams();
  const { loading, error, refresh, crew, users } = useState(`/api/crews/${id}`);
  const [search, setSearch] = React.useState("");
  const [newUser, setNewUser] = React.useState(false);
  const [resetPass, setResetPass] = React.useState(false);

  const [edit, setEdit] = React.useState(false);
  const [editReset, setEditReset] = React.useState(1);
  const [init, setInit] = React.useState(null);
  const fetcher = useFetcher();
  const searchFilter = () => {
    if (!users) return [];
    if (!search) return users;
    return users.filter(({ username }) =>
      username.toLowerCase().includes(search.toLowerCase())
    );
  };
  const deleteUser = async (id) => {
    await fetcher.delete("deleting user", `/api/users/${id}`, ({ error }) => {
      if (error) return toast.error(error);
      refresh();
    });
  };
  const saveUser = async (user) => {
    let payload = user;
    payload.name = user.username;
    delete payload.username;
    if (init?.id) {
      payload.id = init?.id;
    }
    if (!validate("user_edit", payload)) return;
    await fetcher.post(
      "updating user",
      `/api/users`,
      payload,
      ({ error }) => {
        if (error) return toast.error(error);
        refresh();
      },
      () => {}
    );
  };
  const addUser = async (payload) => {
    if (!validate("user_edit", payload)) return;
    const noError = true;
    await fetcher.post(
      "adding new user",
      `/api/users`,
      payload,
      ({ error }) => {
        if (error) {
          noError = false;
          return toast.error(error);
        }
        refresh();
      },
      () => {}
    );
    return noError;
  };
  const loadedUsers = searchFilter();
  console.log(loadedUsers);
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <IconButton
            onClick={() => {
              setDrawer(data);
              // navigate(data.id);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Role",
      field: "role",
      width: 200,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Avatar
            sx={{
              background: crew?.color,
              width: 32,
              height: 32,
              marginRight: "20px",
            }}
          >
            <img height="40px" width="40px" src={RolesMap[data.role].icon} />
          </Avatar>
          <Typography variant="body2">{data.role}</Typography>
        </span>
      ),
    },
    {
      headerName: "Username",
      field: "username",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "SSO Enabled",
      field: "sso",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
  ];
  return (
    <Page
      refresh={refresh}
      loading={[loading]}
      back="/app/crews"
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setNewUser(true);
          }}
        >
          Add User
        </ActionButton>,
      ]}
      header={`${crew?.name} members`}
    >
      <SidePanel
        open={drawer}
        closeDrawer={() => setDrawer(null)}
        props={{ user: drawer, saveUser, deleteUser }}
      >
        {UserConfig}
      </SidePanel>
      <Stack spacing={3}>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <Input
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    {search ? (
                      <Icon sx={{ fontSize: "18px !important" }} color="error">
                        close
                      </Icon>
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="search..."
            sx={{ marginLeft: "auto", width: "300px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Stack spacing={3}>
          <Table
            data={
              loadedUsers?.map((u) => ({
                ...u,
                sso: u?.sso ? true : false,
              })) || []
            }
            idProperty={"id"}
            columns={columns}
            noSelect
            noPaginate
            loading={loading}
            tableName={"e1userlist"}
            tableHeight={700}
          />
          <SidePanel
            open={newUser}
            closeDrawer={() => setNewUser(null)}
            props={{ addUser, crew: id }}
          >
            {AddUser}
          </SidePanel>
        </Stack>
      </Stack>
    </Page>
  );
}
