import React from "react";
import Page from "common/components/Page";
import {
  Icon,
  Grid,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import Input from "common/components/Input";
import PopoverMenu from "common/components/PopoverMenu";
import MultiSelect from "common/components/MultiSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiLevelMenu from "common/components/MultilevelMenu";
import Table from "common/components/Table/table";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import useConfirm from "common/hooks/useConfirm";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import CreateSystem from "./components/CreateSystem";
import useFolderView from "common/hooks/useFolderView";
import QueryBuilder from "common/components/QueryBuilder";
import { nanoid } from "nanoid";

export default function Systems({ role }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { systems, loading, error, refresh } = useState("/api/sys");
  const [newSystem, setNewSystem] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const navigate = useNavigate();
  const deleteSystem = async (id) => {
    await fetcher.delete("deleting system", `/api/sys/${id}`, () => {
      refresh();
    });
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete System`,
    message: `Are you sure you want to delete this system? This action cannot be undone.`,
    callback: (_, data) => {
      deleteSystem(data.pk);
    },
  });
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const fetcher = useFetcher();
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating system tags",
      `/api/sys/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  // React.useEffect(() => {
  //   window.e1_last_sys_search = window.location.search;
  // }, [searchParams]);
  const searchFilter = () => {
    if (!systems) return [];
    if (!filters.length && !folder) return systems;
    let filtered = [...systems];
    filtered = systems.filter((system) => {
      if (folder) {
        if (folder !== "default") {
          if (
            !system.tags
              .map((t) => t.toLowerCase())
              .includes(folder.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return filters.every(({ field, value }) => {
        if (field === "system") {
          return `${system.name.toLowerCase()}${system.description.toLowerCase()}`.includes(
            value.toLowerCase()
          );
        } else if (field === "tag") {
          return system.tags
            .map((t) => t.toLowerCase())
            .includes(value.toLowerCase());
        } else if (field === "crew") {
          return (
            (system?.owner_name?.toLowerCase() || "").includes(
              value.toLowerCase()
            ) ||
            (system?.owner?.toLowerCase() || "").includes(value.toLowerCase())
          );
        }
      });
    });
    return filtered;
    // if (!search && !tag && !crewSearch && !folder) return systems;
    // let filter = [...systems];
    // if (tag) {
    //   filter = filter.filter(({ tags }) => tags.includes(tag));
    // }
    // if (crewSearch) {
    //   filter = filter.filter(
    //     ({ owner_name, owner }) =>
    //       owner_name === crewSearch || owner === crewSearch
    //   );
    // }
    // if (search) {
    //   filter = filter.filter(
    //     ({ name, description }) =>
    //       name.toLowerCase().includes(search.toLowerCase()) ||
    //       description.toLowerCase().includes(search.toLowerCase())
    //   );
    // }
    // if (folder) {
    //   if (folder === "default") return filter;
    //   filter = filter.filter(({ name, tags }) =>
    //     (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
    //   );
    // }
    // return filter;
  };
  let filteredSys = searchFilter();
  let [tags, tag_options] = filterFolders(filteredSys, systems);

  const systemColumns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          // onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Move System",
                  onClick: () => {
                    setMoveFolder(data.pk);
                  },
                  icon: "move_up",
                },
                {
                  id: "Delete System",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>

          <IconButton
            component="a"
            href={`/app/sys/${data?.pk}/dash`}
            aria-label="copy system link"
            onClick={(e) => {
              e.preventDefault();
              navigate(`${data?.pk}/dash`);
            }}
          >
            <Icon>dashboard</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "System Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Count",
      field: "count",
      width: 100,
    },
    {
      headerName: "Tags",
      field: "tags",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {data?.tags?.map((tag) => {
              return (
                <Chip
                  color="secondary"
                  sx={{ fontSize: "0.5", height: "20px" }}
                  clickable
                  onClick={() => {
                    const newParams = new URLSearchParams(searchParams);
                    if (newParams.has("folder")) {
                      newParams.delete("folder");
                    }
                    newParams.set("folder", tag);
                    setSearchParams(newParams);
                    setFolder(tag);
                  }}
                  key={tag}
                  label={tag}
                />
              );
            })}
            {/* <IconButton color="success" onClick={() => setResourceOpen(data)}>
              <Icon color="success">travel_explore</Icon>
              Folders
            </IconButton> */}
          </span>
        );
      },
    },
  ];
  console.log(filteredSys);
  return (
    <Page
      actions={
        !["auditor", "operator"].includes(role)
          ? [
              <ActionButton
                icon="add"
                noload
                handler={async () => {
                  setNewSystem(true);
                }}
              >
                New System
              </ActionButton>,
            ]
          : null
      }
      loading={[loading]}
      header="Systems"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="System"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="500px"
        open={newSystem}
        closeDrawer={() => setNewSystem(false)}
        props={{}}
      >
        {CreateSystem}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
      </Stack>
      <QueryBuilder
        loading={loading}
        filters={filters}
        defaultField="system"
        setFilters={setFilters}
        fields={[
          { id: "system", options: [] },
          {
            id: "tag",
            options: Array.from(
              new Set(systems?.reduce((a, t) => [...a, ...t.tags], []))
            ),
          },
          {
            id: "crew",
            options: Array.from(
              new Set(
                systems?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            ),
          },
        ]}
      />
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        <Stack spacing={2}>
          <DeleteModal />
          <Table
            data={filteredSys.map((f) => ({
              ...f,
              count: Number(f?.count || 0),
            }))}
            idProperty={"id"}
            columns={systemColumns}
            noSelect
            noPaginate
            loading={false}
            tableName={"e1syslist"}
            tableHeight={700}
          />
          <Stack spacing={2}></Stack>
        </Stack>
      )}
    </Page>
  );
}
