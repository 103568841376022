import React from "react";
import { Stack, Typography, Icon, IconButton } from "@mui/material";
import Table from "common/components/Table/table";
import useFetcher from "common/hooks/useFetcher";
import ActionButton from "common/components/ActionButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "common/components/Input";

export default function SelectTenants({
  tenants,
  platform,
  setTenants,
  onClose,
}) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(true);
  const [availableTenants, setAvailableTenants] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedResources, setSelectedResources] = React.useState(
    new Set(tenants || [])
  );
  const [unselectedResources, setUnselectedResources] = React.useState(
    new Set()
  );

  const [refreshKey, setRefreshKey] = React.useState(0);
  const getTenants = async () => {
    setLoading(true);
    setAvailableTenants([]);
    await fetcher.get(
      "fetching tenants",
      `/api/tenants`,
      (data) => {
        let refArr = tenants || [];
        setAvailableTenants(data?.tenants);
        setAvailableTenants(
          (data?.tenants || []).sort((a, b) => {
            const indexA = refArr.indexOf(a.pk);
            const indexB = refArr.indexOf(b.pk);

            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            } else if (indexA !== -1) {
              return -1;
            } else if (indexB !== -1) {
              return 1;
            } else {
              return 0;
            }
          })
        );
        if (tenants.length === data?.tenants?.length) {
          setSelectAll(true);
        } else {
          setSelectedResources(new Set(tenants || []));
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getTenants();
  }, []);
  let tableTenants = availableTenants.filter((x) =>
    RegExp(search, "i").test(JSON.stringify(x))
  );
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack gap={1}>
          <Typography variant="h4">Select Tenants</Typography>
          <Typography variant="body2">
            Pick tenants that should be assoicated with the target.
          </Typography>
        </Stack>
      </Stack>
      <div>
        <ActionButton
          icon="check"
          sx={{ marginLeft: "auto" }}
          dark
          noload
          handler={async () => {
            let selected = [];
            if (selectAll) {
              selected =
                availableTenants
                  ?.filter(
                    (x) => !Array.from(unselectedResources).includes(x.pk)
                  )
                  ?.map((x) => x.pk) || [];
            } else {
              selected = Array.from(selectedResources);
            }
            setTenants(selected);
            onClose();
          }}
        >
          Done
        </ActionButton>
      </div>

      <Stack gap={1}>
        <div>
          <Input
            dark
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    {search ? (
                      <Icon sx={{ fontSize: "18px !important" }} color="error">
                        close
                      </Icon>
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="search..."
            style={{ marginLeft: "auto", width: "100%" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table
          key={refreshKey}
          filter={false}
          data={tableTenants?.filter((i) => i.type === platform) || []}
          idProperty={"pk"}
          columns={[
            {
              headerName: "Id",
              field: "id",
              minWidth: 200,
              resizable: true,
              flex: 1,
            },
            {
              headerName: "Tenant Name",
              field: "name",
              minWidth: 200,
              resizable: true,
              flex: 1,
            },
          ]}
          loading={loading}
          noPaginate
          tableName={"e1targettenstable"}
          tableHeight={600}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedRowIds={selectedResources}
          setSelectedRowIds={setSelectedResources}
          unselectedRowIds={unselectedResources}
          setUnselectedRowIds={setUnselectedResources}
        />
      </Stack>
    </Stack>
  );
}
