import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import { useAsyncError } from "common/hooks/useAsyncError";
import { Skeleton } from "@mui/material";
export const AssetImage = ({
  path,
  fallback = null,
  style = {
    height: "40px",
    width: "40px",
    backgroundFit: "cover",
  },
  skeletonProps = {
    height: "40px",
    width: "40px",
  },
  cn = "asset-image",
}) => {
  const throwError = useAsyncError();
  const [pimage, setPImage] = useState(null);
  const [shouldFallback, setShouldFallback] = useState(false);
  const FallbackComponent = fallback;
  const loadImage = async () => {
    try {
      let res = await authFetch(path);
      let image = await res.blob();
      if (image.type !== "image/jpeg" && image.type !== "image/png")
        return setShouldFallback(true);
      setPImage(URL.createObjectURL(image));
    } catch (e) {
      console.log("error", e);
      if (fallback) {
        return setShouldFallback(true);
      }
      throwError(e);
    }
  };
  useEffect(() => loadImage(), []);
  if (shouldFallback) return FallbackComponent ? <FallbackComponent /> : null;

  if (!pimage) return <Skeleton variant="rectangular" {...skeletonProps} />;
  return <img className={cn} src={pimage} style={style} />;
};
export default AssetImage;
