import React from "react";
import { Stack, Avatar, Typography, Icon, Grid } from "@mui/material";
import ActionButton from "common/components/ActionButton";
import useFetcher from "common/hooks/useFetcher";
import Switch from "common/components/Switch";
import toast from "react-hot-toast";
import { JsonVerticalTable } from "../Resource";
import SectionHeader from "common/components/SectionHeader";
import CronCreator from "common/components/CronCreator";
import { useParams } from "react-router-dom";
import { getNextCronExecutions } from "common/components/CronCreator";
export default function ScheduleEdit({ refresh, schedule, onClose }) {
  const fetcher = useFetcher();
  const [enabled, setEnabled] = React.useState(schedule?.enabled || false);
  const [cron, setCron] = React.useState(schedule?.cron || {});
  const [editCron, setEditCron] = React.useState(false);
  const { id } = useParams();
  const updateSchedule = async () => {
    if (!cron?.expression) {
      return toast.error("configure a schedule");
    }
    let payload = {
      enabled,
      cron,
    };
    await fetcher.put(
      "updating schedule",
      `/api/sys/${id || schedule?.data?.split("#")[1]}/schedule/${schedule.pk}`,
      payload,
      ({ error }) => {
        if (error) {
          toast.error(error);
          return;
        }
        refresh();
        onClose();
      }
    );
  };

  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon color="primary" sx={{ fontSize: "50px !important" }}>
            schedule
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">
            {schedule?.name?.split("_")?.at(-1)}
          </Typography>
          <Typography variant="caption">
            {schedule?.created_by_user} ({schedule?.created_by_crew})
          </Typography>
        </Stack>
      </Stack>
      <Switch
        checked={enabled}
        label="Enabled"
        onChange={() => {
          setEnabled(!enabled);
        }}
      />
      <Stack className="floating" gap={3}>
        <SectionHeader icon="input">Context</SectionHeader>
        <Stack gap={1}>
          <JsonVerticalTable
            data={[
              {
                inputs: schedule?.content?.inputs || {},
                target: schedule?.content?.target,
                selection: {
                  selectAll: schedule?.content?.selection?.selectAll,
                  selected: schedule?.content?.selection?.selectedResources,
                  unselected: schedule?.content?.selection?.unselectedResources,
                },
              },
            ]}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        {editCron ? (
          <CronCreator
            cron={cron}
            setCron={(cron) => {
              setCron(cron);
              setEditCron(false);
            }}
          />
        ) : (
          <Grid className="floating" gap={1} container>
            <Grid xs={12} item>
              <SectionHeader icon="schedule">Schedule</SectionHeader>
            </Grid>
            <Grid xs={12} item>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Next trigger date
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body2">
                Date and time are displayed in your current time zone in UTC
                format, e.g. “Wed, Nov 9, 2022 09:00 (UTC-04:00)” for Eastern
                time
              </Typography>
            </Grid>
            <Grid xs={12} item>
              {getNextCronExecutions(cron)[0]?.map((e) => {
                return (
                  <Typography
                    sx={{ display: "block", fontWeight: "bold" }}
                    variant="subtitle"
                  >
                    {e}
                  </Typography>
                );
              })}
            </Grid>
            <Grid xs={12} item>
              <ActionButton
                icon="schedule"
                noload
                handler={() => {
                  setEditCron(true);
                }}
              >
                Change Schedule
              </ActionButton>
            </Grid>
          </Grid>
        )}
      </Stack>
      <Stack>
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            dark
            icon="save"
            noload
            handler={async () => await updateSchedule()}
          >
            Update Schedule
          </ActionButton>
        </div>
      </Stack>
    </Stack>
  );
}
