import React from "react";
import { ResponsivePie } from "@nivo/pie";
// const data = [
//   ["category", ""],
//   ["finops", 22.0],
//   ["security", 16.0],
//   ["well-architected", 4.0],
// ];

// Transform the data

const theme = {
  labels: {
    text: {
      fontWeight: "bold", // Make arc labels bold
      fontSize: ".8rem",
      fontFamily: '"Exo", sans-serif', // Set font family for arc labels
    },
  },
  tooltip: {
    container: {
      background: "#333",
      color: "#fff", // Set tooltip text color to black
      fontFamily: '"Exo", sans-serif',
    },
  },
};

const NivoPie = ({ data, colors }) => {
  const transformedData =
    data?.slice(1)?.map(([id, value]) => ({ id, value })) || [];

  return (
    <div className="pie-chart-container" style={{ width: "100%", height: 280 }}>
      <ResponsivePie
        data={transformedData}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        colors={colors}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        slicesLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        arcLabelsTextColor={{ from: "color", modifiers: [["brighter", 3]] }} // Darker shade of slice color
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={theme}
      />
    </div>
  );
};

export default NivoPie;
