import React from "react";
import { Stack, Typography, Grid, Tooltip } from "@mui/material";
import MultiSelect from "../MultiSelect";
import Input from "../Input";
import Metric from "../Metric";
// import Chart from "react-google-charts";
import ActionButton from "../ActionButton";
import TextNode from "../TextNode";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import Switch from "../Switch";
import SectionHeader from "../SectionHeader";
import tooltip from "./tooltip";
import JsonView from "react18-json-view";
import Widget from "../Widget";
import { Size, ColorGenerator, shuffleArray, colors } from "../Graph";
import { nanoid } from "nanoid";
shuffleArray(colors);

export default function GraphBuilder({
  data,
  onSave,
  existing,
  onClose,
  setSampleCache,
  sampleCache,
}) {
  const palette = React.useMemo(() => new ColorGenerator(colors), []);
  const fetcher = useFetcher();
  const [type, setType] = React.useState("Bar");
  const [filter, setFilter] = React.useState("");
  const [expression, setExpression] = React.useState("");
  // const [sort, setSort] = React.useState("");
  const [limit, setLimit] = React.useState(false);
  const [order, setOrder] = React.useState("First");
  const [limitCount, setLimitCount] = React.useState(10);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [prefix, setPrefix] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [granularity, setGranularity] = React.useState("");
  const [sample, setSample] = React.useState("");
  const [testResult, setTestResult] = React.useState(null);
  React.useEffect(() => {
    if (!existing?.id) return;
    setType(existing.type);
    setFilter(existing.filter);
    // setSort(existing.sort);
    setName(existing.name);
    if (existing.limit) {
      setLimit(true);
      setOrder(Math.sign(existing.limit) === -1 ? "Last" : "First");
      setLimitCount(Math.abs(existing.limit));
    }
    setGranularity(existing?.granularity || "");
    setDescription(existing.description);
    setExpression(existing.expression);
    setLabel(existing.label);
    setGroup(existing.group);
    setPrefix(existing.prefix);
    setSuffix(existing.suffix);
  }, [existing]);
  const coloredData = (data) => {
    palette.reset();
    return [
      [...data[0], { role: "style" }],
      ...data?.slice(1)?.map((d, i) => [...d, palette.generate()]),
    ];
  };
  const loadSampleDataset = async () => {
    await fetcher.get(
      "fetching system data sampling",
      `/api/sys/${data.id}/data?page=1&count=20`,
      ({ system, graphs, data, colors }) => {
        if (data?.docs?.length) {
          setSampleCache(data?.docs || []);
        }
      },
      () => {}
    );
  };
  const testWidget = async () => {
    try {
      if (!sampleCache) JSON.parse(sample);
    } catch {
      return toast.error("Sample JSON is not valid.");
    }
    let payload = {
      type,
      filter,
      // sort,
      name,
      limit: limit
        ? order === "First"
          ? Number(limitCount)
          : Number(limitCount) * -1
        : null,
      description,
      expression,
      label,
      group,
      granularity,
      sample: sampleCache ? sampleCache : JSON.parse(sample),
    };
    await fetcher.post(
      "testing widget logic",
      `/api/testing/widget`,
      payload,
      ({ error, result }) => {
        if (error) {
          toast.error(error);
          return;
        }
        if (["Pie", "Bar"].includes(type)) {
          setTestResult(coloredData(result));
        } else {
          setTestResult(result);
        }
      }
    );
  };
  const updateType = (e) => {
    if (
      ["Pie", "Bar", "Waffle"].includes(e) &&
      ["Pie", "Waffle", "Bar"].includes(type)
    ) {
      setType(e);
      setLabel("");
      return;
    }
    setLimit(false);
    setOrder("");
    setFilter("");
    setExpression("");
    setLimitCount("");
    setLabel("");
    setSuffix("");
    setPrefix("");
    setGroup("");
    setGranularity("");
    setDescription("");
    setTestResult(null);
    setType(e);
  };
  return (
    <div style={{ padding: "20px", width: "900px" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          marginLeft: "auto",
          padding: "0 20px",
        }}
      >
        <div>
          <SectionHeader large icon="donut_large">
            Widget Creator
          </SectionHeader>
        </div>
        <div style={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
          {type !== "Text" ? (
            <ActionButton
              dark
              disabled={!sample && !sampleCache}
              danger
              icon={"science"}
              handler={async () => {
                await testWidget();
              }}
            >
              Test
            </ActionButton>
          ) : null}
          <ActionButton
            dark
            icon={"save"}
            handler={async () => {
              let payload = {
                id: existing?.id || nanoid(),
                type,
                filter,
                // sort,
                name,
                limit: limit
                  ? order === "First"
                    ? Number(limitCount)
                    : Number(limitCount) * -1
                  : null,
                expression,
                label,
                group,
                granularity,
                prefix,
                suffix,
              };
              if (type === "Text") {
                payload.description = description;
              }
              await onSave(payload);
              onClose();
            }}
          >
            Save
          </ActionButton>
        </div>
      </div>

      <Grid container gap={1}>
        <Grid xs={5.9} item>
          <Grid container>
            <Grid xs={12} item>
              <Stack className="floating" gap={3}>
                <SectionHeader icon="analytics">General</SectionHeader>
                <Stack gap={1}>
                  <Typography variant="body2">
                    Select a name and a visualization for this chart.
                  </Typography>
                </Stack>
                <Stack gap={3}>
                  <Input
                    dark
                    label="Name"
                    size="small"
                    sx={{ width: "100%" }}
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {type === "Text" ? (
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      {...(type === "Text"
                        ? { maxRows: 8, minRows: 8, multiline: true }
                        : {})}
                    />
                  ) : null}
                  <MultiSelect
                    dark
                    disableClearable
                    options={[
                      "Bar",
                      "Pie",
                      "Waffle",
                      "TimeSeries",
                      "Metric",
                      "Text",
                    ]}
                    multiple={false}
                    label="Chart Type"
                    value={type}
                    setValue={updateType}
                    size="small"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} item>
              {type !== "Text" ? (
                <>
                  <Stack className="floating" gap={3}>
                    <SectionHeader info={tooltip.filterFormula} icon="dataset">
                      Data Wrangling
                    </SectionHeader>
                    <Stack gap={1}>
                      <Typography variant="body2">
                        Techniques to filter, sort, and limit data prior to
                        analysis.
                      </Typography>
                    </Stack>
                    <Stack gap={1}>
                      <Input
                        dark
                        label="Filter Expression"
                        size="small"
                        sx={{ width: "100%" }}
                        placeholder={`resource["Service"] == "Lambda"`}
                        value={filter}
                        multiline
                        minRows={5}
                        maxRows={5}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={5.9} item>
          <Stack
            sx={{ height: "100%", width: "100%" }}
            justifyContent={"center"}
            className="floating"
          >
            <SectionHeader icon="pie_chart">Graph Preview</SectionHeader>
            <div>
              {/* {["Bar", "Pie", "TimeSeries"].includes(type) ? (
                <Chart
                  chartVersion="50" // Explicitly set version to 50
                  chartType={
                    type === "Bar"
                      ? "ColumnChart"
                      : type === "Pie"
                      ? "PieChart"
                      : type === "Waffle"
                      ? "WaffleChart"
                      : "LineChart"
                  }
                  data={
                    testResult || [
                      ["", ""],
                      ["0", 0],
                    ]
                  }
                  options={{
                    title: name,
                    legend: { position: "none" },
                    backgroundColor: {
                      fill: "transparent",
                    },
                    chartArea: {
                      left: 60, // Adjust left padding
                      right: 60, // Adjust right padding
                      top: 60, // Adjust top padding
                      bottom: 60, // Adjust bottom padding
                    },
                    titleTextStyle: {
                      color: "#fff",
                      fontName: "Titillium Web",
                      fontSize: 18,
                    },
                    annotations: {
                      textStyle: {
                        color: "#fff",
                        fontName: "Titillium Web",
                      },
                    },
                    hAxis: {
                      textStyle: {
                        color: "#fff",
                        fontName: "Titillium Web",
                      },
                      showTextEvery: 2,
                      title: group,
                      titleTextStyle: {
                        color: "#fff",
                        bold: true,
                        italic: false,
                      },
                      gridlines: {
                        color: "transparent",
                      },
                      format: "0",
                    },
                    vAxis: {
                      textStyle: {
                        color: "#fff",
                        fontName: "Titillium Web",
                        bold: true,
                      },
                      titleTextStyle: {
                        color: "#fff",
                        fontName: "Titillium Web",
                        bold: true,
                        italic: false,
                      },
                      color: "#fff",
                      gridlines: {
                        color: "transparent",
                      },
                      title: label,
                      format: "0",
                    },
                    // colors: ["#ff914c"],
                    colors: palette.colorArray,
                    lineWidth: 2,
                    pointSize: 6,
                    animation: {
                      startup: true,
                      duration: 1000,
                      easing: "out",
                    },
                  }}
                  height={Size["lg"][type.toLowerCase()].canvasHeight}
                />
              ) : ( */}
              <Widget
                noHeader={true}
                name={name}
                description={description}
                prefix={prefix}
                suffix={suffix}
                data={testResult}
                type={type}
              />
              {/* )} */}
            </div>
          </Stack>
        </Grid>
      </Grid>
      {type !== "Text" ? (
        <Grid container>
          <Grid xs={12} item>
            <Stack className="floating">
              {["Bar", "Pie", "Waffle", "TimeSeries"].includes(type) ? (
                <Stack className="floating" gap={3}>
                  <SectionHeader info={tooltip.formula} icon="calculate">
                    Chart Formula
                  </SectionHeader>

                  <Stack gap={1}>
                    <Typography variant="body2">
                      Define the y-axis equation and the group by property.
                    </Typography>
                  </Stack>
                  <Stack gap={2}>
                    {["Bar", "TimeSeries"].includes(type) ? (
                      <Input
                        dark
                        label="Y Axis Label"
                        size="small"
                        sx={{ width: "100%" }}
                        placeholder="Count"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                      />
                    ) : null}
                    {["TimeSeries"].includes(type) ? (
                      <MultiSelect
                        dark
                        disableClearable
                        options={["day", "month", "year"]}
                        multiple={false}
                        label="Granularity Type"
                        value={granularity}
                        setValue={setGranularity}
                        size="small"
                      />
                    ) : null}
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Y Axis Expression"
                      placeholder={`Sum("Cost")`}
                      value={expression}
                      onChange={(e) => setExpression(e.target.value)}
                    />
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Group by property"
                      placeholder="Id"
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                    />
                    <Stack gap={1}>
                      <div style={{ width: "100px" }}>
                        <Switch
                          label={"Limit"}
                          checked={limit}
                          onChange={() => {
                            setLimit(!limit);
                            setOrder("");
                            setLimitCount("");
                          }}
                        />
                      </div>
                      <Stack direction="row" gap={1}>
                        <MultiSelect
                          dark
                          label="Direction"
                          options={["First", "Last"]}
                          multiple={false}
                          disabled={!limit}
                          disableClearable
                          value={order}
                          setValue={setOrder}
                          size="small"
                          sx={{ width: "100px" }}
                        />
                        <Input
                          dark
                          size="small"
                          disabled={!limit}
                          sx={{ width: "100px" }}
                          label="Count"
                          placeholder="10"
                          type="number"
                          value={limitCount}
                          onChange={(e) => setLimitCount(e.target.value)}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ) : null}
              {type === "Metric" ? (
                <Stack className="floating" gap={3}>
                  <SectionHeader info={tooltip.formula} icon="calculate">
                    Metric Formula
                  </SectionHeader>
                  <Stack gap={1}>
                    <Typography variant="body2">
                      Create an expression to equate your metric.
                    </Typography>
                  </Stack>
                  <Stack gap={2}>
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Metric Formula"
                      placeholder={`Count("Service")`}
                      value={expression}
                      onChange={(e) => setExpression(e.target.value)}
                    />
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Prefix"
                      placeholder="$"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                    />
                    <Input
                      dark
                      size="small"
                      sx={{ width: "100%" }}
                      label="Suffix"
                      placeholder="%"
                      value={suffix}
                      onChange={(e) => setSuffix(e.target.value)}
                    />
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      ) : null}
      {["Pie", "Waffle", "Bar", "Metric", "TimeSeries"].includes(type) ? (
        <Stack className="floating" mt={2} gap={3}>
          <SectionHeader icon="data_object">Sample Dataset</SectionHeader>
          <Stack gap={1}>
            <Typography variant="body2">
              Provide a sample JSON array that Echelon One can use to test your
              widgets.
            </Typography>
          </Stack>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              dark
              icon={"download"}
              handler={async () => {
                await loadSampleDataset();
              }}
            >
              Load System Data Sample
            </ActionButton>
          </div>
          <Stack gap={1}>
            {sampleCache ? (
              <JsonView displaySize src={sampleCache} />
            ) : (
              <Input
                dark
                size="small"
                sx={{ width: "100%" }}
                placeholder="Sample JSON"
                value={sample}
                onChange={(e) => setSample(e.target.value)}
                multiline
                minRows={8}
                maxRows={8}
              />
            )}
          </Stack>
        </Stack>
      ) : null}
    </div>
  );
}
