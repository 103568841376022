import React from "react";
import { Stack, Icon, Button, IconButton, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "common/components/Loader";
import ViewEdit from "pages/Nexus/components/ViewEdit";
import Page from "common/components/Page";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import rootFetcher from "common/utils/fetcher";
import useFetcher from "common/hooks/useFetcher";
import { StarBorderOutlined } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import Metric from "common/components/Metric";
// import Graph from "common/components/Graph";
import Widget from "common/components/Widget";
import { createGridDashboardArray } from "common/utils/createArrayToAddUpToNumber";
import SidePanel from "common/components/SidePanel";
import ActionButton from "common/components/ActionButton";
const SystemTile = ({ name, id, count, navigate }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        action={
          <IconButton
            onClick={() => navigate(`/app/sys/${id}/dash?from=nexus`)}
          >
            <Icon>arrow_forward</Icon>
          </IconButton>
        }
        title={
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>dns</Icon> <span style={{ marginLeft: "5px" }}>{name}</span>
            </Typography>
            <Typography variant="subtitle2">
              Count of total system resources
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="h1">{count}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const WidgetTile = ({ tile }) => {
  const navigate = useNavigate();
  let widget = tile.widget;
  return (
    <Widget
      link={() => navigate(`/app/sys/${tile.id}/dash?from=nexus`)}
      {...widget}
      data={widget.data}
    />
  );
  // const description = `${tile.name}${
  //   widget.description ? ` - ${widget.description}` : ""
  // }`;
  // if (widget.type === "Metric") {
  //   if (widget.data.value && widget.data.value === 0)
  //     return (
  //       <Metric
  //         link={() => navigate(`/app/sys/${tile.id}/dash?from=nexus`)}
  //         name={widget.name}
  //         description={description}
  //         text={"-"}
  //         textFont={60}
  //       />
  //     );
  //   return (
  //     <Metric
  //       link={() => navigate(`/app/sys/${tile.id}/dash?from=nexus`)}
  //       name={widget.name}
  //       description={description}
  //       count={widget.data.value}
  //     />
  //   );
  // }
  // if (!widget.data.length)
  //   return (
  //     <Metric
  //       link={() => navigate(`/app/sys/${tile.id}/dash?from=nexus`)}
  //       name={widget.name}
  //       description={description}
  //       text={"-"}
  //       textFont={60}
  //       titleIcon={widget.type === "Pie" ? "pie_chart" : "insert_chart"}
  //     />
  //   );
  // return (
  //   <Graph
  //     link={() => navigate(`/app/sys/${tile.id}/dash?from=nexus`)}
  //     name={widget.name}
  //     description={description}
  //     type={widget.type}
  //     property={widget.property.name}
  //     yProperty={widget?.yProperty?.name}
  //     data={widget.data || [["", 0, null, "No Data Copy"]]}
  //   />
  // );
};

const SystemTiles = ({ systems }) => {
  const navigate = useNavigate();
  const dashboardGrid = React.useMemo(() => {
    return createGridDashboardArray(systems?.length ?? 0);
  }, [systems]);
  return (
    // <div
    //   className="three-grid-column"
    //   style={{
    //     width: "100%",
    //     display: "grid",
    //     gap: "10px",
    //     marginBottom: "50px",
    //   }}
    // >
    <Grid container spacing={1}>
      {systems.map((system, idx) => {
        let index;
        if (dashboardGrid?.length <= 2) {
          index = 6;
        } else {
          index = dashboardGrid[idx];
        }
        const Component =
          system.type === "system" ? (
            <SystemTile
              key={idx}
              id={system.id}
              name={system.name || " "}
              count={system.count || 0}
              navigate={navigate}
            />
          ) : (
            <WidgetTile tile={system} />
          );
        return (
          <Grid key={idx} item xs={12} md={index}>
            {Component}
          </Grid>
        );
      })}
    </Grid>
  );
};

const View = ({ content, edit, views, refresh }) => {
  return <SystemTiles systems={views} />;
};
export default function Nexus({ role, crew_name }) {
  const [edit, setEdit] = React.useState(false);
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [defaultView, setDefaultView] = React.useState(null);
  const [views, setViews] = React.useState([]);
  const [info, setInfo] = React.useState({});
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const toggleStar = async () => {
    await fetcher.post(
      "updating default view preference",
      `/api/nexus/views/${id}/star`,
      {},
      ({ error }) => {
        if (error) {
          toast.error(error);
          return;
        }
        fetchUserPreference();
      },
      ({ error }) => {}
    );
  };
  const fetchUserPreference = async () => {
    let { error, view } = await rootFetcher(`/api/nexus/views/default`);
    setDefaultView(view);
  };
  const fetchView = async () => {
    setLoading(true);
    let { error, tiles, view } = await rootFetcher(`/api/nexus/views/${id}`);
    if (!view) {
      return navigate(`/app/nexus`, { state: { from: "view" } });
    }
    await fetchUserPreference();
    if (!error) {
      setViews(tiles);
      setInfo(view);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    fetchView();
  }, []);
  if (loading) return <Loader />;
  return (
    <Page
      actions={
        <Stack direction={"row"} spacing={2} alignItems="center">
          {["admin", "engineer"].includes(role) ? (
            <ActionButton icon="edit" noload handler={() => setEdit(info)}>
              Edit View
            </ActionButton>
          ) : null}
          {defaultView === id ? (
            <div>
              <Star
                color="secondary"
                sx={{ fontSize: 30, cursor: "pointer" }}
              />
            </div>
          ) : null}
        </Stack>
      }
      back={() => navigate(`/app/nexus`, { state: { from: "view" } })}
      refresh={fetchView}
      header={info?.name}
    >
      <SidePanel
        open={edit}
        width={600}
        closeDrawer={() => setEdit(false)}
        props={{
          view: edit,
          refresh: fetchView,
          toggleStar,
          starred: defaultView === id,
        }}
      >
        {ViewEdit}
      </SidePanel>
      <View views={views} refresh={fetchView} />
    </Page>
  );
}
