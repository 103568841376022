import React from "react";
import Page from "common/components/Page";
import { Stack, IconButton, Icon, InputAdornment } from "@mui/material";
import Table from "common/components/Table/table";
import { useNavigate } from "react-router-dom";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import EditCrew from "pages/Crews/component/EditCrew";
import ActionButton from "common/components/ActionButton";
import Input from "common/components/Input";
import SidePanel from "common/components/SidePanel";
import { toast } from "react-hot-toast";
import useConfirm from "common/hooks/useConfirm";
import PopoverMenu from "common/components/PopoverMenu";

export default function Crews({ crew_name }) {
  const { crews, loading, error, refresh } = useState("/api/crews");
  const [search, setSearch] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const searchFilter = () => {
    if (!crews) return [];
    let filtered = [...crews];
    if (!search) return crews;
    if (crew_name !== "administrators") {
      filtered = filtered?.filter((c) => c.name === crew_name);
    }
    return crews.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );
  };
  const deleteCrew = async (id) => {
    await fetcher.delete("deleting crew", `/api/crews/${id}`, ({ error }) => {
      if (error) return toast.error(error);
      refresh();
    });
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Crew`,
    message: `Are you sure you want to delete this crew? This action cannot be undone.`,
    callback: (_, data) => {
      deleteCrew(data?.id);
    },
  });
  const actions =
    crew_name === "administrators"
      ? [
          <ActionButton
            icon="add"
            noload
            handler={async () => {
              setEdit(true);
            }}
          >
            Add Crew
          </ActionButton>,
        ]
      : null;
  let loadedCrews = searchFilter();
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span style={{ position: "relative" }}>
            <PopoverMenu
              options={[
                {
                  id: "Delete Crew",
                  onClick: () => {
                    openDeleteModal(true, data);
                  },
                  icon: "delete",
                  color: "#FF6961",
                },
              ]}
            />
          </span>
          <IconButton
            onClick={() => {
              navigate(data.id);
            }}
          >
            <Icon>tune</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Crew Color",
      field: "color",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: data?.color,
              borderRadius: "50%",
            }}
          />
        </span>
      ),
    },
    {
      headerName: "Crew Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
  ];
  return (
    <Page
      refresh={refresh}
      loading={[loading]}
      header="Crews"
      actions={actions}
    >
      <DeleteModal />
      <Stack spacing={3}>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <Input
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    {search ? (
                      <Icon sx={{ fontSize: "18px !important" }} color="error">
                        close
                      </Icon>
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="search..."
            sx={{ marginLeft: "auto", width: "300px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <SidePanel
          open={edit}
          closeDrawer={() => setEdit(false)}
          props={{ refresh }}
        >
          {EditCrew}
        </SidePanel>
        <Table
          data={loadedCrews || []}
          idProperty={"id"}
          columns={columns}
          noSelect
          noPaginate
          loading={loading}
          tableName={"e1crewlist"}
          tableHeight={700}
        />
      </Stack>
    </Page>
  );
}
