import { Stack, Typography, Icon, Avatar } from "@mui/material";
import SidePanel from "common/components/SidePanel";
import { theme } from "theme";
import ActionButton from "common/components/ActionButton";
import Background from "assets/poly_bg.svg";
export default function CommandPalette({
  open,
  setOpen,
  system,
  setActionOpen,
  selectedResources,
  selectAll,
}) {
  if (!system || !system?.actions) return null;
  return (
    <SidePanel
      open={open}
      width={480}
      closeDrawer={() => {
        setOpen(null);
      }}
    >
      {({ onClose }) => (
        <Stack padding={4} spacing={2}>
          <Stack alignItems={"center"} direction="row" gap={1} marginBottom={2}>
            <Icon
              style={{
                fontSize: "40px",
                color: theme.palette.secondary.main,
              }}
            >
              bolt
            </Icon>
            <Typography variant="h4">Command Palette</Typography>
          </Stack>
          {system.actions
            .filter((a) => {
              let disabled =
                (a?.selection &&
                  selectAll !== true &&
                  !selectedResources?.length) ||
                (!a.selection &&
                  (selectAll === true || selectedResources?.length));
              return !a.hidden && !disabled;
            })
            .map((a) => {
              let disabled =
                (a?.selection &&
                  selectAll !== true &&
                  !selectedResources?.length) ||
                (!a.selection &&
                  (selectAll === true || selectedResources?.length));
              return (
                <Stack
                  sx={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  key={a.id}
                  className="floating"
                  gap={3}
                >
                  <Stack alignItems={"center"} direction="row" gap={1}>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        marginRight: "10px",
                        backgroundColor: theme.palette.secondary.main,
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: "30px",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {a.icon}
                      </Icon>
                    </Avatar>
                    <Typography
                      sx={{ fontWeight: "bold", letterSpacing: "1.1px" }}
                      variant="h5"
                    >
                      {a.name}
                    </Typography>
                  </Stack>
                  <Stack gap={1}>
                    <Typography>{a.description}</Typography>
                  </Stack>

                  <div style={{ marginLeft: "auto" }}>
                    <ActionButton
                      icon="rocket_launch"
                      dark
                      disabled={disabled}
                      noload
                      sx={{
                        zIndex: 10,
                      }}
                      handler={async () => {
                        setActionOpen(a.id);
                        onClose();
                      }}
                    >
                      Execute
                    </ActionButton>
                  </div>
                </Stack>
              );
            })}
        </Stack>
      )}
    </SidePanel>
  );
}
