import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTooltip } from "@nivo/tooltip";

// Define a custom rainbow pastel color palette
const pastelColors = [
  "#FFB3BA", // Light Pink
  "#FFDFBA", // Light Orange
  "#FFFFBA", // Light Yellow
  "#BAFFC9", // Light Green
  "#BAE1FF", // Light Blue
  "#E1BAFF", // Light Purple
];

const theme = {
  textColor: "#fff",
  axis: {
    ticks: {
      text: {
        fill: "#fff",
        fontFamily: '"Exo", sans-serif',
      },
    },
    legend: {
      text: {
        fill: "#fff",
        fontFamily: '"Exo", sans-serif',
      },
    },
  },
  legends: {
    text: {
      fill: "#fff",
      fontFamily: '"Exo", sans-serif',
    },
  },
  labels: {
    text: {
      fill: "#fff",
      fontFamily: '"Exo", sans-serif',
    },
  },
};

const CustomTooltip = ({ id, value }) => {
  return (
    <div
      style={{
        color: "#fff",
        background: "#333",
        padding: "5px 10px",
        borderRadius: "3px",
        fontFamily: '"Exo", sans-serif',
      }}
    >
      {`${id}: ${value}`}
    </div>
  );
};

const CustomBarComponent = ({ bar, ...rest }) => {
  let id = bar?.data?.data?.id;
  let value = bar?.data?.data?.value;
  const maxWidth = 1000; // Set a maximum width for each bar
  const ref = React.useRef();
  //   console.log(ref?.current?.getBoundingClientRect());
  let truncatedLabel;
  if (ref?.current?.getBoundingClientRect().width > 22) {
    truncatedLabel = id.length > 20 ? `${id.substring(0, 20)}...` : id; // Truncate label if too long
  } else {
    truncatedLabel = id.length > 10 ? `${id.substring(0, 10)}...` : id; // Truncate label if too long
  }
  //   console.log(truncatedLabel);
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  return (
    <g
      transform={`translate(${bar.x},${bar.y})`}
      onMouseEnter={(event) =>
        showTooltipFromEvent(<CustomTooltip id={id} value={value} />, event)
      }
      onMouseLeave={hideTooltip}
    >
      <rect
        ref={ref}
        width={Math.min(bar.width, maxWidth)}
        height={bar.height}
        fill={bar.color}
        stroke={bar.borderColor}
        strokeWidth={bar.borderWidth}
      />
      {bar.index % 4 === 0 && (
        <text
          x={Math.min(bar.width, maxWidth) / 2}
          y={bar.height + 20}
          textAnchor="middle"
          style={{
            fill: "#fff",
            fontSize: 11,
            fontFamily: '"Exo", sans-serif',
          }}
        >
          {truncatedLabel}
        </text>
      )}
    </g>
  );
};

const NivoBar = ({ data, colors }) => {
  const transformedData =
    data?.slice(1)?.map(([id, value]) => ({ id, value })) || [];
  return (
    <div className="bar-chart-container" style={{ width: "100%", height: 230 }}>
      <ResponsiveBar
        data={transformedData}
        keys={["value"]}
        indexBy="id"
        margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
        padding={0.3}
        layout="vertical"
        colors={colors} // Use the custom pastel color palette
        colorBy="indexValue" // Color bars by their id
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        enableLabel={false}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: !data ? "" : data[0][1]?.replace("_", " "), // Use the second element of the first array for x-axis label
          legendPosition: "middle",
          legendOffset: 35,
          tickValues: [],
        }}
        enableGridY={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: !data ? "" : data[0][0]?.replace("_", " "), // Use the first element of the first array for y-axis label
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={theme}
        barComponent={CustomBarComponent} // Use the custom bar component
      />
    </div>
  );
};

export default NivoBar;
