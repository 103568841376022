import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  IconButton,
  Icon,
  Typography,
  Badge,
  Checkbox,
  Grid,
  Avatar,
} from "@mui/material";
import Table from "common/components/Table/table";
import AstroHubPublish from "pages/System/components/AstroHubPublish";
import copyContent from "common/utils/copyContent";
import { theme } from "theme";
import Widget from "common/components/Widget";
import { nanoid } from "nanoid";
import { themeOptions } from "theme";
import Fab from "common/components/Fab";
import ActionButton from "common/components/ActionButton";
import getEasierToReadColor from "common/utils/getEasierToReadColor";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import Table from "common/components/Table";
import useFetcher from "common/hooks/useFetcher";
import { DEFAULT_PAGE_COUNT } from "constants";
import ActionModal from "pages/SystemDash/component/Action";
import ResourceModal from "pages/SystemDash/component/Resource";
import FilesModal from "pages/SystemDash/component/Files";
import Color from "color";
import JobsModal from "pages/SystemDash/component/Jobs";
import AlertEmote from "common/components/AlertEmote";
import SidePanel from "common/components/SidePanel";
import { createGridDashboardArray } from "common/utils/createArrayToAddUpToNumber";
import CommandPalette from "./component/CommandPalette";
import Tooltip from "@mui/material/Tooltip";
import QueryBuilder from "pages/System/components/QueryBuilder";
import { updateUtcTimestampWithOffset } from "common/utils/getOffsetUTCHours";
import SystemInfo from "./component/SystemInfo";
import validate from "constants/validate";
import EditPanel from "./component/EditPanel";
import { ContextMenu } from "mui-nested-menu";
// import useRightClickPopover from "common/hooks/useRightClickPopover";
export default function SystemDash({ role }) {
  // const { Popover, handleContextMenu } = useRightClickPopover();
  const { id } = useParams();
  const [filters, setFilters] = React.useState([]);
  const [editPanelOpen, setEditPanelOpen] = React.useState(false);
  // const [systemReadUrl, setSystemReadUrl] = React.useState(false);
  const [jobsOpen, setJobsOpen] = React.useState(false);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  // const [webhooksOpen, setWebhooksOpen] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);
  const [actionOpen, setActionOpen] = React.useState(false);
  const [resourceOpen, setResourceOpen] = React.useState(false);
  const [publishPanel, setPublishPanel] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  let [commandPaletteOpen, setCommandPaletteOpen] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedResources, setSelectedResources] = React.useState(new Set());
  const [unselectedResources, setUnselectedResources] = React.useState(
    new Set()
  );
  const [system, setSystem] = React.useState({});
  const [data, setData] = React.useState({});
  const [graphs, setGraphs] = React.useState({});
  const [colors, setColors] = React.useState({});
  const [params, setParams] = React.useState({
    sort: searchParams.get("sort") || null,
    dir: searchParams.get("dir") || null,
    query: searchParams.get("query") || null,
    page: searchParams.get("page") || 1,
    count: DEFAULT_PAGE_COUNT,
  });
  const { current: fromNexus } = React.useRef(
    searchParams.get("from") === "nexus"
  );
  const resetSelection = () => {
    setSelectAll(false);
    setSelectedResources(new Set());
    setUnselectedResources(new Set());
    setRefreshKey((s) => ++s);
  };
  const query = searchParams.get("query");
  const paginate = async (noload) => {
    const queryString = Object.keys(params)
      .filter((k) => params[k])
      .map((key) => key + "=" + params[key])
      .join("&");
    setSearchParams(
      Object.keys(params)
        .filter((k) => params[k])
        .reduce((a, c) => ({ ...a, [c]: params[c] }), {})
    );
    if (!noload) setLoading(true);
    await fetcher.get(
      "fetching system data",
      `/api/sys/${id}/data?${queryString}`,
      ({ system, graphs, data, colors }) => {
        setSystem(system);
        setData(data);
        setGraphs(graphs);
        setLoading(false);
        setColors(colors);
      },
      () => {
        fromNexus ? navigate(-2) : navigate("/app/sys");
        setLoading(false);
      }
    );
    if (!noload) setLoading(false);
  };
  const onPaginate = async (page) => {
    setParams({ ...params, page });
  };
  const generateReport = async () => {
    await fetcher.get(
      "generating report",
      `/api/sys/${id}/report`,
      ({ url }) => {
        if (url) {
          const a = document.createElement("a");
          a.href = url;
          a.download = "filename";
          a.click();
        }
      },
      () => {}
    );
  };
  const onSortInfoChange = async (value) => {
    if (!value) return setParams({ ...params, sort: null, dir: null });
    setParams({ ...params, sort: value?.name, dir: value?.dir });
  };
  const queryBasedOnFilters = () => {
    let query = encodeURIComponent(JSON.stringify(filters));
    setParams({ ...params, query, page: 1 });
    resetSelection();
  };
  const queryBasedOnPassedFilters = (filters) => {
    let query = encodeURIComponent(JSON.stringify(filters));
    setParams({ ...params, query, page: 1 });
    resetSelection();
  };
  // const onSearch = (query) => {
  //   setParams({ ...params, query, page: 1 });
  //   setSelectedResources(new Set());
  //   setUnselectedResources(new Set());
  // };
  const onClearSearch = () => {
    setParams({ ...params, query: null, page: 1 });
    setFilters([]);
  };
  const saveSystem = async (system, update = true) => {
    const payload = { ...system };
    if (!validate("system_general_edit", payload)) return;
    await fetcher.post(
      "saving system",
      `/api/sys`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        if (!update) {
          return;
        }
      },
      () => {}
    );
    await fetcher.get(
      "fetching system",
      `/api/sys/${id}`,
      ({ system }) => {
        setSystem(system);
      },
      () => {}
    );
  };
  React.useEffect(() => {
    if (params.query) {
      setFilters(JSON.parse(decodeURIComponent(params.query)));
    }
    paginate();
  }, [params]);
  const dashboardGrid = React.useMemo(() => {
    return createGridDashboardArray(system?.graphs?.length ?? 0);
  }, [system, params, graphs]);
  const graphData = React.useMemo(() => {
    if (!system || !graphs) return null;
    return system?.graphs?.length ? (
      <Grid container spacing={1}>
        {system.graphs.map((g, i) => {
          let index;
          if (dashboardGrid?.length <= 2) {
            index = 6;
          } else {
            index = dashboardGrid[i];
          }
          let d = graphs ?? {};
          return (
            <Grid key={i} item xs={12} md={index}>
              <Widget {...g} data={d[g.id]} />
            </Grid>
          );
        })}
      </Grid>
    ) : null;
  }, [system, params, graphs]);

  // const columns = React.useMemo(() => {
  //   //add default beginning columns
  //   let cols = [
  //     {
  //       header: "",
  //       name: "data",
  //       defaultWidth: 60,
  //       render: ({ data }) => (
  //         <Stack direction="row" spacing={1}>
  //           <IconButton color="success" onClick={() => setResourceOpen(data)}>
  //             <Icon color="success">travel_explore</Icon>
  //           </IconButton>
  //         </Stack>
  //       ),
  //     },
  //   ];
  //   //add property columns
  //   cols = [
  //     ...cols,
  //     ...(system?.properties?.filter((p) => !p?.hidden) || []).map((p) => {
  //       const customCellStyle = (cellProps) => {
  //         const { data, name, value, rowActive, rowIndex } = cellProps;
  //         let color = null;
  //         if (colors[name]) {
  //           color = colors[name][data[system.uid]];
  //         }

  //         if (!color) return {};
  //         return {
  //           background: color,
  //           color: getEasierToReadColor(color), //Color(color).darken(0.7),
  //         };
  //       };
  //       if (p.type === "bool") {
  //         return {
  //           name: p.name,
  //           header: p.name,
  //           defaultWidth: 150,
  //           textAlign: "center",
  //           style: customCellStyle,
  //           render: ({ data, ...props }) => {
  //             let value = data[p.name];
  //             let color = null;
  //             if (colors[p.name]) {
  //               color = colors[p.name][data[system.uid]];
  //             }
  //             return (
  //               <Checkbox
  //                 checked={value}
  //                 disabled
  //                 sx={{
  //                   "&.Mui-disabled": {
  //                     color: color
  //                       ? `${Color(color).darken(0.7)} !important`
  //                       : "init",
  //                   },
  //                 }}
  //               />
  //             );
  //           },
  //         };
  //       }
  //       if (["object", "list", "lstr"].includes(p.type)) {
  //         return {
  //           name: p.name,
  //           header: p.name,
  //           style: customCellStyle,
  //           defaultWidth: Number(p?.width) || null,
  //           sortable: false,
  //           textAlign: "center",
  //           render: ({ data, ...rest }) => {
  //             let value = data[p.name];
  //             if (!value) return null;
  //             return (
  //               <Tooltip
  //                 title={
  //                   <pre
  //                     style={{
  //                       whiteSpace: "pre-wrap",
  //                       wordWrap: "break-word",
  //                     }}
  //                   >
  //                     {JSON.stringify(value, null, 4)}
  //                   </pre>
  //                 }
  //               >
  //                 <div
  //                   onMouseDown={(event) => {
  //                     event.preventDefault();
  //                     if (event.button === 2) {
  //                       handleContextMenu(event, event.target, {
  //                         data,
  //                         ...rest,
  //                       });
  //                     }
  //                   }}
  //                 >
  //                   {p.type === "lstr" ? (
  //                     <span>{value}</span>
  //                   ) : (
  //                     <Icon>
  //                       {p.type === "list" ? "data_array" : "data_object"}
  //                     </Icon>
  //                   )}
  //                 </div>
  //               </Tooltip>
  //             );
  //           },
  //         };
  //       }
  //       return {
  //         name: p.name,
  //         header: p.name,
  //         style: customCellStyle,
  //         defaultWidth: Number(p?.width) || null,
  //         render: ({ data, ...rest }) => {
  //           let value = data[p.name];
  //           try {
  //             let j = JSON.parse(value);
  //             if (Array.isArray(j)) {
  //               return (
  //                 <div
  //                   onMouseDown={(event) => {
  //                     event.preventDefault();
  //                     if (event.button === 2) {
  //                       handleContextMenu(event, event.target, {
  //                         data,
  //                         ...rest,
  //                       });
  //                     }
  //                     // add filters here
  //                   }}
  //                 >
  //                   {String(j.length)}
  //                 </div>
  //               );
  //             } else if (typeof j === "object") {
  //               return (
  //                 <div
  //                   onMouseDown={(event) => {
  //                     event.preventDefault();
  //                     if (event.button === 2) {
  //                       handleContextMenu(event, event.target, {
  //                         data,
  //                         ...rest,
  //                       });
  //                     }
  //                   }}
  //                 >
  //                   {String(Object.keys(j).length)}
  //                 </div>
  //               );
  //             } else {
  //               return (
  //                 <div
  //                   onMouseDown={(event) => {
  //                     event.preventDefault();
  //                     if (event.button === 2) {
  //                       handleContextMenu(event, event.target, {
  //                         data,
  //                         ...rest,
  //                       });
  //                     }
  //                   }}
  //                 >
  //                   {String(value)}
  //                 </div>
  //               );
  //             }
  //           } catch (e) {
  //             if (
  //               /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
  //                 value
  //               )
  //             ) {
  //               return (
  //                 <IconButton
  //                   onClick={() => window.open(value, "_blank")}
  //                   color="secondary"
  //                 >
  //                   <Icon color="secondary">link</Icon>
  //                 </IconButton>
  //               );
  //             }
  //             return (
  //               <div
  //                 onMouseDown={(event) => {
  //                   event.preventDefault();
  //                   if (event.button === 2) {
  //                     handleContextMenu(event, event.target, {
  //                       data,
  //                       ...rest,
  //                     });
  //                   }
  //                 }}
  //               >
  //                 {String(value)}
  //               </div>
  //             );
  //           }
  //         },
  //       };
  //     }),
  //   ];
  //   //add detector columns if applicable
  //   if (system?.detectors?.length) {
  //     cols.push({
  //       name: "detections",
  //       header: "Detectors",
  //       defaultWidth: 300,
  //       render: ({ data }) => {
  //         return (
  //           <Stack direction="row" spacing={1}>
  //             {data?.detections?.map((d) => {
  //               let detector = system.detectors.find(({ id }) => id === d);
  //               let detectorTriggers =
  //                 detector?.triggers?.map((t) => t.id) || [];
  //               const tCount =
  //                 data?.triggers?.filter((t) => {
  //                   return detectorTriggers.includes(t);
  //                 }).length ?? 0;
  //               if (!tCount)
  //                 return (
  //                   <IconButton
  //                     color="success"
  //                     onClick={() =>
  //                       setFilters((f) => [
  //                         ...filters,
  //                         {
  //                           id: nanoid(),
  //                           column: "detections",
  //                           operator: "contains",
  //                           value: d,
  //                         },
  //                       ])
  //                     }
  //                   >
  //                     <Icon color="success" key={d}>
  //                       {system.detectors.find((dt) => dt.id === d)?.icon ||
  //                         "help"}
  //                     </Icon>
  //                   </IconButton>
  //                 );
  //               return (
  //                 <IconButton
  //                   color="success"
  //                   onClick={() => setParams({ ...params, query: d, page: 1 })}
  //                 >
  //                   <Badge badgeContent={tCount} color="primary">
  //                     <Icon color="success" key={d}>
  //                       {system.detectors.find((dt) => dt.id === d)?.icon ||
  //                         "help"}
  //                     </Icon>
  //                   </Badge>
  //                 </IconButton>
  //               );
  //             })}
  //           </Stack>
  //         );
  //       },
  //     });
  //   }
  //   return cols;
  // }, [system, colors]);
  let columns = [
    {
      headerName: "",
      field: "inspect",
      width: 60,
      cellRenderer: ({ data }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <IconButton color="success" onClick={() => setResourceOpen(data)}>
            <Icon color="success">travel_explore</Icon>
          </IconButton>
        </span>
      ),
    },
  ];
  let propCols = (system?.properties?.filter((p) => !p?.hidden) || []).map(
    (p) => {
      const config = { headerName: p.name, field: p.name };
      // if (p?.width) {
      //   console.log(p.width);
      //   config.width = Number(p.width);
      // }
      config.context = { type: p.type };
      // handle types
      if (["list", "object"].includes(p.type)) {
        config.context.align = "center";
      }
      //handle cell color
      if (p.name in colors) {
        try {
          if (Object.keys(colors[p.name])?.length) {
            config.context.cellColor = colors[p.name];
          }
        } catch {}
      }
      config.c;
      return config;
    }
  );
  columns = [...columns, ...propCols];
  const actions = React.useMemo(() => {
    const a = [];
    if (
      system?.actions?.filter((a) => {
        let disabled =
          (a?.selection && selectAll !== true && !selectedResources?.size) ||
          (!a.selection && (selectAll === true || selectedResources?.size));
        return !a.hidden && !disabled;
      })?.length &&
      system?.plugins?.data &&
      role !== "auditor"
    ) {
      a.push(
        <>
          <ActionButton
            noload
            icon="bolt"
            circle
            handler={() => setCommandPaletteOpen(true)}
          />
          <Fab
            active={selectedResources?.size || selectAll == true}
            style={{
              "--background": themeOptions.palette.secondary.main,
            }}
            icon="bolt"
            iconColor="#000"
            onClick={() => setCommandPaletteOpen(true)}
          />
        </>
      );
    }
    a.push(
      <ActionButton
        noload
        icon="sms"
        circle
        handler={() => setJobsOpen(true)}
      />
    );
    a.push(
      <ActionButton
        noload
        icon="attachment"
        circle
        handler={() => setFilesOpen(true)}
      />
    );
    a.push(
      <ActionButton
        noload
        icon="info"
        circle
        handler={() => setInfoOpen(true)}
      />
    );
    if (system?.plugins?.data) {
      a.push(
        <ActionButton
          noload
          icon="download"
          circle
          handler={() => generateReport()}
        />
      );
    }
    if (!["auditor", "operator"].includes(role)) {
      a.push(
        <ActionButton
          noload
          icon="edit"
          circle
          // handler={() => navigate(`/app/sys/${id}`)}
          handler={() => setEditPanelOpen(true)}
        />
      );
    }

    return a;
  }, [system, selectedResources]);

  return (
    <>
      <Page
        dynamicSize
        refresh={() => {
          paginate();
          resetSelection();
        }}
        back={() => {
          fromNexus ? navigate(-2) : navigate(`/app/sys`);
          // : navigate(`/app/sys${window?.e1_last_sys_search || ""}`);
        }}
        header={system?.name || "..."}
        loading={[loading && !system.id]}
        actions={actions}
        chips={[
          {
            label: !system?.plugins?.data
              ? `${system?.actions?.length} commands`
              : `${data?.total || 0} resources ${
                  query ? ` / ${data?.selection || 0} queried resources` : ""
                }`,
          },
          {
            label: `last modified on ${
              data?.last_mod
                ? updateUtcTimestampWithOffset(data?.last_mod)
                : "n/a"
            }`,
          },
        ]}
      >
        {/* <Popover
          options={[
            {
              title: "Copy",
              handler: (data) => {
                copyContent(data?.data[data?.cellProps?.computedHeader]);
              },
            },
            {
              title: "Add Filter",
              handler: (data) => {
                setFilters((f) => [
                  ...f,
                  {
                    id: nanoid(),
                    column: data?.cellProps?.computedHeader,
                    operator: "equals",
                    value: data?.data[data?.cellProps?.computedHeader],
                  },
                ]);
              },
            },
          ]}
        /> */}
        {/* <NewReadWebhookModal
          open={systemReadUrl}
          setOpen={setSystemReadUrl}
          system={system}
        /> */}
        <FilesModal
          role={role}
          dataBacked={system?.plugins?.data}
          system={system?.id}
          open={filesOpen}
          setOpen={setFilesOpen}
        />
        <JobsModal
          role={role}
          dataBacked={system?.plugins?.data}
          system={system?.id}
          open={jobsOpen}
          setOpen={setJobsOpen}
        />
        {/* <WebhooksModal
          system={system}
          open={webhooksOpen}
          setOpen={setWebhooksOpen}
        /> */}
        <ResourceModal
          selectAndOpenCommand={(id) => {
            setUnselectedResources(new Set());
            setSelectedResources(new Set([id]));
            setSelectAll(false);
            setCommandPaletteOpen(true);
          }}
          colors={colors}
          refresh={paginate}
          system={system}
          open={resourceOpen}
          setOpen={setResourceOpen}
          params={params}
        />
        <CommandPalette
          {...{
            open: commandPaletteOpen,
            setOpen: setCommandPaletteOpen,
            system,
            setActionOpen,
            selectedResources: Array.from(selectedResources),
            selectAll,
          }}
        />
        <SidePanel
          width={800}
          open={!!infoOpen}
          noTopMargin
          closeDrawer={() => setInfoOpen(null)}
          props={{
            system,
            onPublish: () => setPublishPanel(true),
            role,
            // id,
            // refresh: () => paginate(true),
          }}
        >
          {SystemInfo}
        </SidePanel>
        <SidePanel
          width={600}
          open={!!editPanelOpen}
          closeDrawer={() => setEditPanelOpen(null)}
          sx={{ overflowY: "hidden !important" }}
          props={{
            id,
            refresh: () => paginate(true),
          }}
        >
          {EditPanel}
        </SidePanel>
        <SidePanel
          width={500}
          open={publishPanel}
          closeDrawer={() => setPublishPanel(null)}
          props={{ system }}
        >
          {AstroHubPublish}
        </SidePanel>
        <SidePanel
          width={600}
          open={!!actionOpen}
          closeDrawer={() => setActionOpen(null)}
          props={{
            role,
            action: actionOpen,
            refreshSystemData: () => paginate(true),
            resetSelection,
            resources: {
              selectedResources: Array.from(selectedResources),
              unselectedResources: Array.from(unselectedResources),
              selectAll,
              query: params?.query
                ? JSON.parse(decodeURIComponent(params?.query))
                : null,
            },
            query: params?.query,

            // allSelected:
            //   selectedResources === true ||
            //   selectedResources?.length === data?.total,
            system,
          }}
        >
          {ActionModal}
        </SidePanel>
        <Stack m={3} spacing={2}>
          <Typography
            sx={{ fontWeight: "bold" }}
            pt={1}
            pb={3}
            variant="subtitle2"
          >
            {system?.description}
          </Typography>
          {graphData}
          {!system?.plugins?.data ? (
            <Grid container gap={2}>
              {system?.actions?.map((a) => {
                return (
                  <Grid item xs={12} md={3.9}>
                    <Stack key={a.id} className="floating" gap={3}>
                      <Stack alignItems={"center"} direction="row" gap={1}>
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            marginRight: "10px",
                            backgroundColor: theme.palette.secondary.main,
                          }}
                        >
                          <Icon
                            style={{
                              fontSize: "30px",
                              color: theme.palette.primary.main,
                            }}
                          >
                            {a.icon}
                          </Icon>
                        </Avatar>
                        <Typography variant="h5">{a.name}</Typography>
                      </Stack>
                      <Stack gap={1}>
                        <Typography>{a.description}</Typography>
                      </Stack>

                      <div style={{ marginLeft: "auto" }}>
                        <ActionButton
                          icon="rocket_launch"
                          dark
                          noload
                          handler={async () => {
                            setActionOpen(a.id);
                          }}
                        >
                          Execute
                        </ActionButton>
                      </div>
                    </Stack>
                  </Grid>
                  // <OptionCard
                  //   height="250px"
                  //   key={a.id}
                  //   icon={a.icon}
                  //   name={a.name}
                  //   description={
                  //     <div>
                  //       <Typography>{a.description}</Typography>
                  //     </div>
                  //   }
                  //   onClick={() => setActionOpen(a.id)}
                  // />
                );
              })}
            </Grid>
          ) : (
            <Stack>
              <QueryBuilder
                system={system}
                saveSystem={saveSystem}
                query={queryBasedOnFilters}
                queryBasedOnPassedFilters={queryBasedOnPassedFilters}
                columns={columns?.map((c) => c.field) ?? []}
                filters={filters}
                setFilters={setFilters}
                searchParam={params?.query}
                clear={onClearSearch}
                additionalOptions={
                  system?.detectors?.length
                    ? system?.detectors?.map(({ icon, name, id }) => [
                        icon,
                        name,
                        () => {
                          setFilters((f) => [
                            ...f,
                            {
                              id: nanoid(),
                              column: "detections",
                              operator: "contains",
                              value: id,
                            },
                          ]);
                        },
                      ])
                    : []
                }
              />
              <Table
                key={refreshKey}
                data={data?.docs || []}
                idProperty={system?.uid}
                filter={searchParams.get("query")}
                columns={columns}
                loading={loading}
                onPaginate={onPaginate}
                onSort={onSortInfoChange}
                sortColumn={searchParams.get("sort")}
                sortDirection={searchParams.get("dir")}
                page={data?.page || 0}
                pages={data?.out_of}
                tableName={"e1dashtable"}
                tableHeight={600}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                selectedRowIds={selectedResources}
                setSelectedRowIds={setSelectedResources}
                unselectedRowIds={unselectedResources}
                setUnselectedRowIds={setUnselectedResources}
                contextMenu={[
                  {
                    title: "Copy",
                    handler: (row, column, value) => {
                      copyContent(value);
                    },
                  },
                  {
                    title: "Add to Filter",
                    handler: (row, column, value) => {
                      setFilters((f) => [
                        ...f,
                        {
                          id: nanoid(),
                          column: column,
                          operator: "equals",
                          value: value,
                        },
                      ]);
                    },
                  },
                ]}
              />
              {/* <Table
                expand
                data={data?.docs || []}
                loading={loading}
                idProperty={system?.uid}
                emptyText={<AlertEmote noButton text="No dataset yet" />}
                checkboxColumn
                onSelectionChange={({ selected, unselected }) => {
                  setUnselectedResources(unselected);
                  if (typeof selected == "boolean") {
                    return setSelectedResources(selected);
                  }
                  setSelectedResources(Object.keys(selected));
                }}
                selected={
                  typeof selectedResources == "boolean"
                    ? selectedResources
                    : selectedResources?.reduce(
                        (a, c) => ({ ...a, [c]: true }),
                        {}
                      ) || {}
                }
                unselected={unselectedResources}
                {...{
                  page: data?.page || 0,
                  pages: data?.out_of,
                  onPaginate,
                  onSortInfoChange,
                  // searchProps: { query, onSearch, onClearSearch },
                  sortInfo: !params?.sort
                    ? null
                    : { name: params.sort, dir: params.dir },
                }}
                cols={columns}
                // renderRowContextMenu={renderRowContextMenu}
                // updateMenuPositionOnColumnsChange
              /> */}
            </Stack>
          )}
        </Stack>
      </Page>
      <div style={{ height: "50px" }} />
    </>
  );
}
