import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Button,
  Typography,
  TextField,
  Paper,
  LinearProgress,
} from "@mui/material";
import E1Input from "common/components/E1Input";
import Input from "common/components/Input";
import { styled, useTheme } from "@mui/material/styles";
import useFetcher from "common/hooks/useFetcher";
import { MiniLoader } from "common/components/Loader";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import Divider from "@mui/material/Divider";
import useForm from "common/hooks/useForm";
import validate from "constants/validate";
import toast from "react-hot-toast";
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3),
  borderRadius: theme.spacing(1),
}));

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { useNavigate } from "react-router-dom";

const CompletionComponent = ({ onAction }) => {
  const keyframes = `
    @keyframes float {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }
    @keyframes pulse {
      0%, 100% { transform: scale(1); }
      50% { transform: scale(1.1); }
    }
    @keyframes glow {
      0%, 100% { filter: drop-shadow(0 0 2px #ff6961); }
      50% { filter: drop-shadow(0 0 8px #ff6961); }
    }
  `;

  const theme = useTheme();
  return (
    <>
      <style>{keyframes}</style>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 400,
          mx: "auto",
          my: 4,
        }}
      >
        <Box
          sx={{
            width: 80,
            height: 80,
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <RocketLaunchIcon
            sx={{
              fontSize: 50,
              color: theme.palette.secondary.main,
              animation: `
                pulse 2s ease-in-out infinite,
                glow 2s ease-in-out infinite
              `,
              transformOrigin: "center",
            }}
          />
        </Box>

        <Typography
          variant="h5"
          component="h2"
          sx={{
            mb: 3,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Installation Complete!
        </Typography>

        <Button
          onClick={onAction}
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
            px: 4,
            py: 1,
            borderRadius: 2,
            letterSpacing: "1.2px",
          }}
        >
          CONTINUE TO SYSTEM
        </Button>
      </Paper>
    </>
  );
};

const steps = ["System Information", "Required Variables", "Review"];

export default function InstallWizard({ system, onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const {
    controller: envscontroller,
    getFormValues: getEnvsFormValues,
    setFormValues,
  } = useForm("envs");
  const {
    tags,
    TagInput,
    loading: attrLoading,
    setInit,
  } = useScopeAttributes();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    envs: {},
  });
  const [envs, setEnvs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetcher = useFetcher();
  const [completed, setCompleted] = useState(false);
  const [newSystem, setNewSystem] = useState(null);
  const getInstallInfo = async () => {
    setLoading(true);
    try {
      await fetcher.get(
        "Preparing installation",
        `/api/store/system/${system?.id}/data`,
        ({ envs }) => {
          if (envs?.length) {
            // setFormData();
          }
          setEnvs(envs);
        },
        () => {}
      );
    } catch {}
    setLoading(false);
  };
  React.useEffect(() => {
    if (activeStep == 1) {
      setFormValues(formData?.envs || {});
    }
  }, [activeStep]);
  React.useEffect(() => {
    getInstallInfo();
  }, [system]);

  const handleNext = () => {
    if (activeStep === 1) {
      setFormData({ ...formData, envs: getEnvsFormValues() });
      setActiveStep((prevStep) => prevStep + 1);
    } else if (activeStep === steps.length - 1) {
      installHandler();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const installHandler = async () => {
    setLoading(true);
    const payload = formData;
    payload.tags = tags;
    if (!validate("system_install", payload)) {
      return setLoading(false);
    }

    try {
      await fetcher.post(
        "installing system",
        `/api/store/install/${system.id}`,
        payload,
        ({ id, error }) => {
          if (error) {
            toast.error(error);
            setLoading(false);
            return;
          }
          setNewSystem(id);
          setCompleted(true);
        },
        () => {}
      );
    } catch {}
    setLoading(false);
  };
  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return formData?.name?.length > 5;
      case 1:
        return true;
      case 2:
        return true;
      default:
        return true;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack gap={2}>
            <Typography variant="h6" gutterBottom>
              System Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              Provide identifying information for the new system installation
            </Typography>
            <Stack className="shadow" sx={{ padding: "10px" }}>
              <Input
                dark
                label="System Name"
                size="small"
                name="name"
                fullWidth
                autoFocus
                //   sx={{ width: "100%" }}
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Stack>
            <Stack className="shadow" sx={{ padding: "10px" }}>
              <TagInput size="small" dark />
            </Stack>
          </Stack>
        );
      case 1:
        return (
          <Stack gap={2}>
            <Typography variant="h6" gutterBottom>
              Required Variables
            </Typography>
            <Typography variant="body1" gutterBottom>
              {envs.length
                ? "The following required variables will be created in your Echelon One instance and referenced by this system after installation"
                : "There are no required variables for this system installation."}
            </Typography>
            {envs?.map((env) => (
              <Stack key={env.name} className="shadow" sx={{ padding: "10px" }}>
                <E1Input
                  sx={{ width: "100%" }}
                  type={
                    env.type === "str" && env.secret ? "password" : env.type
                  }
                  label={`${env.name}`}
                  inputProps={{ ...envscontroller(env.name) }}
                />
              </Stack>
            ))}
          </Stack>
        );
      case 2:
        return (
          <Stack gap={2}>
            <Typography variant="h6" gutterBottom>
              Review
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please confirm the system configuration below to be used for
              system installation
            </Typography>
            <Divider sx={{ backgroundColor: "rgba(239,237,239,0.38)" }} />
            <Typography>
              <strong>System Name:</strong> {formData.name}
            </Typography>
            <Typography>
              <strong>System Tags:</strong>{" "}
              {tags.length ? tags.join(", ") : "none"}
            </Typography>
            <Typography>
              <strong>New Variables:</strong>{" "}
              {envs.length ? envs.map((e) => e.name).join(", ") : "none"}
            </Typography>
            {/* <Typography>Install Path: {formData.installPath}</Typography>
            <Typography>Port: {formData.port}</Typography>
            <Typography>Username: {formData.username}</Typography> */}
          </Stack>
        );
      default:
        return "Unknown step";
    }
  };

  // Rest of your component code remains the same...
  if (loading) {
    return (
      <StyledPaper sx={{ height: "323px" }} elevation={3}>
        <div
          style={{
            marginTop: "120px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MiniLoader />
        </div>
      </StyledPaper>
    );
  }
  return (
    <StyledPaper elevation={3}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 4, mb: 4 }}>
        {completed ? (
          <CompletionComponent
            onAction={() => navigate(`/app/sys/${newSystem}/dash`)}
          />
        ) : (
          <Box>
            {getStepContent(activeStep)}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
            >
              <Button onClick={handleCancel} color="error" variant="outlined">
                Cancel
              </Button>
              <Box>
                {activeStep > 0 && (
                  <Button
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained"
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={!isStepValid(activeStep)}
                  color={
                    activeStep === steps.length - 1 ? "success" : "primary"
                  }
                >
                  {activeStep === steps.length - 1 ? "Install" : "Next"}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </StyledPaper>
  );
}
