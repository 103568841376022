import React from "react";
import { Stack, Typography, Icon, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import useFitText from "use-fit-text";
import { useNavigate } from "react-router-dom";
// import Chart from "react-google-charts";
import NivoPie from "./NivoPie";
import NivoBar from "./NivoBar";
import NivoWaffle from "./NivoWaffle";
import NivoLine from "./NivoLine";
export const ChartIcons = {
  Text: "article",
  Metric: "ping",
  Pie: "pie_chart",
  Bar: "bar_chart",
  Waffle: "pie_chart",
  TimeSeries: "show_chart",
};
// const ChartType = {
//   Bar: "ColumnChart",
//   Pie: "PieChart",
//   Waffle: "WaffleChart",
//   TimeSeries: "LineChart",
// };

export const Size = {
  sm: {
    scatter: {
      containerHeight: "150px",
      canvasHeight: "100px",
      canvasStyle: {},
    },
    pie: {
      containerHeight: "300px",
      canvasHeight: "300px",
      canvasStyle: {},
    },
    bar: {
      containerHeight: "150px",
      canvasHeight: "130px",
      canvasStyle: {
        overflow: "",
      },
    },
    waffle: {
      containerHeight: "150px",
      canvasHeight: "130px",
      canvasStyle: {
        overflow: "",
      },
    },
    timeseries: {
      containerHeight: "150px",
      canvasHeight: "130px",
      canvasStyle: {
        overflow: "",
      },
    },
  },
  reg: {
    scatter: {
      containerHeight: "280px",
      canvasHeight: "240px",
      canvasStyle: {
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    pie: {
      containerHeight: "280px",
      canvasHeight: "280px",
      canvasStyle: {
        position: "absolute",
        top: "30%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    bar: {
      containerHeight: "280px",
      canvasHeight: "180px",
      canvasStyle: {
        position: "absolute",
        top: "60%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    waffle: {
      containerHeight: "280px",
      canvasHeight: "180px",
      canvasStyle: {
        position: "absolute",
        top: "60%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    timeseries: {
      containerHeight: "280px",
      canvasHeight: "180px",
      canvasStyle: {
        position: "absolute",
        top: "60%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
  },
  lg: {
    scatter: {
      containerHeight: "450px",
      canvasHeight: "180px",
      canvasStyle: {},
    },
    pie: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {},
    },
    bar: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {
        overflow: "",
      },
    },
    waffle: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {
        overflow: "",
      },
    },
    timeseries: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {
        overflow: "",
      },
    },
  },
};

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export let colors = [
  "#cc6666",
  "#cc8f66",
  "#ccb866",
  "#b8cc66",
  "#8fcc66",
  "#66cc66",
  "#66cc8f",
  "#66ccb8",
  "#66b8cc",
  "#668fcc",
  "#6666cc",
  "#8f66cc",
  "#b866cc",
  "#cc66b8",
  "#cc668f",
];
// export let colors = [
//   "#cc6666",
//   "#cc8f66",
//   "#66cc66",
//   "#668fcc",
//   "#8f66cc",
//   "#cc66b8",
// ];

shuffleArray(colors);

export class ColorGenerator {
  constructor(colors) {
    this.original = colors;
    this.colors = [...this.original];
  }
  reset() {
    this.colors = [...this.original];
  }
  get availableColors() {
    return this.colors.length;
  }
  get colorArray() {
    return this.original;
  }
  getRandomColor() {
    return this.colors.splice(0, 1)[0];
  }
  generate() {
    if (this.availableColors === 0) {
      this.reset();
    }
    return this.getRandomColor();
  }
}

export const Graph = ({
  name,
  link,
  group,
  label,
  data,
  type,
  size = "reg",
  noHeader,
}) => {
  const navigate = useNavigate();
  const handleError = () => {
    const elements = document.querySelectorAll(
      '[id^="google-visualization-errors-"]'
    );

    // Iterate over the selected elements
    elements.forEach((element) => {
      // Remove each element from the DOM
      element.remove();
    });
  };

  const chartEvents = [
    {
      eventName: "error",
      callback: handleError,
    },
  ];
  const palette = React.useMemo(() => new ColorGenerator(colors), []);
  const colorPalette = [];
  const coloredData = React.useMemo(() => {
    try {
      palette.reset();
      return [
        [...data[0], { role: "style" }],
        ...data?.slice(1)?.map((d, i) => {
          let color = palette.generate();
          colorPalette.push(color);
          return [...d, color];
        }),
      ];
    } catch (e) {
      console.log(e);
    }
  }, [data]);
  palette.reset();

  if (!Size[size][type?.toLowerCase()]) return null;
  return (
    <Card
      sx={{
        height: Size[size][type.toLowerCase()]?.containerHeight || "280px",
        position: "relative",
        overflow: "visible",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Stack
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          {noHeader ? null : (
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>{ChartIcons[type]}</Icon>
              {/* <Icon>{type === "Pie" ? "pie_chart" : "insert_chart"}</Icon>{" "} */}
              <span style={{ marginLeft: "5px" }}>{name}</span>
            </Typography>
          )}
          {link ? (
            <IconButton
              style={{ position: "relative", zIndex: 100, cursor: "pointer" }}
              onClick={() =>
                typeof link === "string" ? navigate(link) : link()
              }
            >
              <Icon>arrow_forward</Icon>
            </IconButton>
          ) : null}
        </Stack>
        <div style={Size[size][type.toLowerCase()].canvasStyle}>
          {type === "TimeSeries" ? (
            <NivoLine
              data={coloredData}
              colors={colorPalette.length ? colorPalette : colors}
            />
          ) : type === "Pie" ? (
            <NivoPie
              data={coloredData}
              colors={colorPalette.length ? colorPalette : colors}
            />
          ) : type === "Waffle" ? (
            <NivoWaffle
              data={coloredData}
              colors={colorPalette.length ? colorPalette : colors}
            />
          ) : type === "Bar" ? (
            <NivoBar
              data={coloredData}
              colors={colorPalette.length ? colorPalette : colors}
            />
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};

// export const MinimalGraph = ({
//   name,
//   link,
//   group,
//   label,
//   data,
//   type,
//   size = "reg",
// }) => {
//   const navigate = useNavigate();
//   const handleError = () => {
//     const elements = document.querySelectorAll(
//       '[id^="google-visualization-errors-"]'
//     );

//     // Iterate over the selected elements
//     elements.forEach((element) => {
//       // Remove each element from the DOM
//       element.remove();
//     });
//   };

//   const chartEvents = [
//     {
//       eventName: "error",
//       callback: handleError,
//     },
//   ];
//   const palette = React.useMemo(() => new ColorGenerator(colors), []);
//   const coloredData = React.useMemo(() => {
//     try {
//       palette.reset();
//       return [
//         [...data[0], { role: "style" }],
//         ...data?.slice(1)?.map((d, i) => [...d, palette.generate()]),
//       ];
//     } catch (e) {
//       console.log(e);
//     }
//   }, [data]);
//   palette.reset();

//   if (!Size[size][type?.toLowerCase()]) return null;
//   return (
//     <Card
//       sx={{
//         // height: Size[size][type.toLowerCase()]?.containerHeight || "280px",
//         // position: "relative",
//         padding: 0,
//         margin: 0,
//         // overflow: "visible",
//       }}
//     >
//       <CardContent sx={{ position: "relative" }}>
//         <Stack
//           style={{ flexDirection: "row", justifyContent: "space-between" }}
//         >
//           <Typography
//             gutterBottom
//             variant="h5"
//             component="div"
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <Icon>{ChartIcons[type]}</Icon>
//             {/* <Icon>{type === "Pie" ? "pie_chart" : "insert_chart"}</Icon>{" "} */}
//             <span style={{ marginLeft: "5px" }}>{name}</span>
//           </Typography>
//           {link ? (
//             <IconButton
//               style={{ position: "relative", zIndex: 100, cursor: "pointer" }}
//               onClick={() =>
//                 typeof link === "string" ? navigate(link) : link()
//               }
//             >
//               <Icon>arrow_forward</Icon>
//             </IconButton>
//           ) : null}
//         </Stack>
//         <div style={Size[size][type.toLowerCase()].canvasStyle}>
//           <Chart
//             chartVersion="50" // Explicitly set version to 50
//             id={Math.random()}
//             chartType={ChartType[type]}
//             className="graph"
//             options={{
//               // title: name,
//               legend: { position: "none" },
//               backgroundColor: {
//                 fill: "transparent",
//               },
//               chartArea: {
//                 left: 60, // Adjust left padding
//                 right: 60, // Adjust right padding
//                 top: 60, // Adjust top padding
//                 bottom: 60, // Adjust bottom padding
//               },
//               titleTextStyle: {
//                 color: "#fff",
//                 fontName: "Titillium Web",
//                 fontSize: 18,
//               },
//               annotations: {
//                 textStyle: {
//                   color: "#fff",
//                   fontName: "Titillium Web",
//                 },
//               },
//               hAxis: {
//                 textStyle: {
//                   color: "#fff",
//                   fontName: "Titillium Web",
//                 },
//                 showTextEvery: 2,
//                 title: group,
//                 titleTextStyle: {
//                   color: "#fff",
//                   bold: true,
//                   italic: false,
//                 },
//                 gridlines: {
//                   color: "transparent",
//                 },
//                 format: "0",
//               },
//               vAxis: {
//                 textStyle: {
//                   color: "#fff",
//                   fontName: "Titillium Web",
//                   bold: true,
//                 },
//                 titleTextStyle: {
//                   color: "#fff",
//                   fontName: "Titillium Web",
//                   bold: true,
//                   italic: false,
//                 },
//                 color: "#fff",
//                 gridlines: {
//                   color: "transparent",
//                 },
//                 title: label,
//                 format: "0",
//               },
//               // colors: ["#ff914c"],
//               colors: palette.colorArray,
//               lineWidth: 2,
//               pointSize: 6,
//               animation: {
//                 startup: true,
//                 duration: 1000,
//                 easing: "out",
//               },
//             }}
//             data={coloredData}
//             width="100%"
//             height={Size[size][type.toLowerCase()].canvasHeight}
//             chartEvents={chartEvents}
//           />
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

const TextNode = ({ name, description }) => {
  // const { fontSize, ref } = useFitText({
  //   maxFontSize: 800.7142857142857,
  //   minFontSize: 125.7142857142857,
  // });
  return (
    <Card
      sx={{
        height: 280,
        backgroundSize: "cover",
        overflow: "none",
      }}
    >
      <CardHeader
        sx={{ position: "relative", zIndex: "40" }}
        title={
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>{ChartIcons["Text"]}</Icon>
              <span
                style={{
                  marginLeft: "5px",
                  textShadow: "0 1px 0 black",
                  color: "#fff",
                }}
              >
                {name}
              </span>
            </Typography>
          </Stack>
        }
      />

      <CardContent
        sx={{
          position: "relative",
          zIndex: "40",
          height: "185px",
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            whiteSpace: "pre-line",
            textShadow: "0 1px 0 black",
            color: "#fff",
          }}
          component="p"
          variant="subtitle1"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Widget = ({
  type,
  name,
  group,
  description,
  data,
  label,
  link,
  size = "reg",
  textFont = 100,
  titleIcon = "pin",
  prefix,
  suffix,
  noHeader,
}) => {
  const navigate = useNavigate();
  const { fontSize, ref } = useFitText({
    maxFontSize: 600.7142857142857,
    minFontSize: 100.7142857142857,
  });
  if (type === "Text") {
    return <TextNode name={name} description={description} />;
  }
  if (["Pie", "Bar", "Waffle", "TimeSeries"].includes(type)) {
    return (
      <Graph
        noHeader={noHeader}
        name={name}
        group={group}
        label={label}
        data={data}
        type={type}
        size={size}
        link={link}
      />
    );
  }
  return (
    <Card sx={{ height: "280px" }}>
      <CardHeader
        action={
          link ? (
            <IconButton
              onClick={() =>
                typeof link === "string" ? navigate(link) : link()
              }
            >
              <Icon>arrow_forward</Icon>
            </IconButton>
          ) : null
        }
        title={
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>{ChartIcons["Metric"]}</Icon>
              <span style={{ marginLeft: "5px" }}>{name}</span>
            </Typography>
          </Stack>
        }
      />

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!data && data !== 0 ? (
          <Typography
            style={{
              fontSize: textFont,
              width: "100%",
              textAlign: "center",
            }}
            variant="h3"
          >
            -
          </Typography>
        ) : (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            sx={{ width: "100%" }}
          >
            <div
              ref={ref}
              style={{
                textAlign: "center",
                verticalAlign: "center",
                height: "100%",
                width: "100%",
                fontSize,
                fontFamily: "'ECHELONONE'",
              }}
            >
              {prefix ? (
                <span
                  style={{
                    fontFamily: "Mulish",
                    position: "relative",
                    top: "-1vw",
                    fontSize: "50%",
                  }}
                >
                  {prefix}
                </span>
              ) : null}
              {data || 0}
              {suffix ? (
                <span
                  style={{
                    fontFamily: "Mulish",
                    position: "relative",
                    top: "-1vw",
                    fontSize: "50%",
                  }}
                >
                  {suffix}
                </span>
              ) : null}
            </div>

            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20%"
              height="100%"
              // preserveAspectRatio="xMidYMid meet"
              preserveAspectRatio="none"
              fontSize="4vw"
            >
              <text
                dominantBaseline="middle"
                textAnchor="middle"
                x="50%"
                y="50%"
                fontFamily="'Echelon One', sans-serif"
                fontSize="100%"
                fontSizeAdjust="0.58"
                fill="#fff"
              >
                $
              </text>
            </svg>
            <CountUp start={0} end={Number(data ?? 0)} duration={1.5} delay={0}>
              {({ countUpRef }) => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60%"
                  height="100%"
                  // preserveAspectRatio="xMidYMid meet"
                  preserveAspectRatio="none"
                  fontSize="9vw"
                >
                  <text
                    dominantBaseline="middle"
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    fontFamily="'Echelon One', sans-serif"
                    fontSize="100%"
                    fontSizeAdjust="0.58"
                    fill="#fff"
                    ref={countUpRef}
                  />
                </svg>
              )}
            </CountUp>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20%"
              height="100%"
              // preserveAspectRatio="xMidYMid meet"
              preserveAspectRatio="none"
              fontSize="4vw"
            >
              <text
                dominantBaseline="middle"
                textAnchor="middle"
                x="50%"
                y="50%"
                fontFamily="'Echelon One', sans-serif"
                fontSize="100%"
                fontSizeAdjust="0.58"
                fill="#fff"
              >
                kWh
              </text>
            </svg> */}

            {/* {suffix ? (
              <Typography
                sx={{
                  fontSize: `${Number(fontSize?.split("%")[0] || 100)}%`,
                }}
              >
                {suffix}
              </Typography>
            ) : null} */}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
export default Widget;
