import { ResponsiveWaffle } from "@nivo/waffle";

export const NivoWaffle = ({ data, colors }) => {
  const transformedData =
    data?.slice(1)?.map(([id, value]) => ({ id, label: id, value })) || [];
  return (
    <div
      className="waffle-chart-container"
      style={{ width: "100%", height: 230 }}
    >
      <ResponsiveWaffle
        data={transformedData}
        total={100}
        colors={colors}
        rows={12}
        columns={30}
        animate={false}
        padding={1}
        valueFormat=".2f"
        margin={{ top: 30, right: 10, bottom: 10, left: 10 }}
        borderRadius={3}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        theme={{
          tooltip: {
            container: {
              background: "#333",
              color: "#fff", // Set tooltip text color to black
              fontFamily: '"Exo", sans-serif',
            },
          },
        }}
        motionStagger={2}
        emptyColor={"#898b90"}
      />
    </div>
  );
};

export default NivoWaffle;
