import { ResponsiveLine } from "@nivo/line";

const NivoLine = ({ data, colors }) => {
  let transformedData = [];
  try {
    let lineInfo = { id: data.slice(0)[0][0], colors: colors[0], data: [] };
    data?.slice(1)?.forEach(([x, y]) => lineInfo.data.push({ x, y }));
    transformedData.push(lineInfo);
  } catch {}
  if (!transformedData.length) {
    return null;
  }
  console.log(data);
  return (
    <div
      className="waffle-chart-container"
      style={{ width: "100%", height: 230 }}
    >
      <ResponsiveLine
        data={transformedData}
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: transformedData[0]?.id || "",
          legendOffset: 36,
          legendPosition: "middle",
          truncateTickAt: 0,
          renderTick: ({
            opacity,
            textAnchor,
            textBaseline,
            textX,
            textY,
            theme,
            value,
            x,
            y,
          }) => {
            const index = transformedData[0].data.findIndex(
              (d) => d?.x === value
            );
            if (index % 5 !== 0) {
              return "";
            }
            return (
              <g transform={`translate(${x},${y})`} style={{ opacity }}>
                <text
                  alignmentBaseline={textBaseline}
                  style={{
                    fill: "#fff",
                    fontSize: 11,
                    fontFamily: '"Exo", sans-serif',
                  }}
                  textAnchor={textAnchor}
                  transform={`translate(${textX},${textY})`}
                >
                  {value}
                </text>
              </g>
            );
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
          truncateTickAt: 0,
        }}
        enableGridX={false}
        enableGridY={false}
        colors={colors}
        lineWidth={2}
        pointSize={8}
        pointColor={colors}
        pointBorderWidth={3}
        pointBorderColor={{ from: "serieColor", modifiers: [] }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair={false}
        useMesh={true}
        theme={{
          tooltip: {
            container: {
              background: "#333",
              color: "#fff", // Set tooltip text color to black
              fontFamily: '"Exo", sans-serif',
            },
          },
          textColor: "#fff",
          axis: {
            ticks: {
              text: {
                fill: "#fff",
                fontFamily: '"Exo", sans-serif',
              },
            },
            legend: {
              text: {
                fill: "#fff",
                fontFamily: '"Exo", sans-serif',
              },
            },
          },
          legends: {
            text: {
              fill: "#fff",
              fontFamily: '"Exo", sans-serif',
            },
          },
          labels: {
            text: {
              fill: "#fff",
              fontFamily: '"Exo", sans-serif',
            },
          },
        }}
      />
    </div>
  );
};

export default NivoLine;
