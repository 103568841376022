import React from "react";
import Page from "common/components/Page";
import { Icon, Stack, Typography, IconButton, Card } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MiniLoader } from "common/components/Loader";
import useFetcher from "common/hooks/useFetcher";
import CodeEditor from "common/components/CodeEditor";
import toast from "react-hot-toast";
import SectionHeader from "common/components/SectionHeader";
import MultiLevelMenu from "common/components/MultilevelMenu";
import ActionButton from "common/components/ActionButton";
import useConfirm from "common/hooks/useConfirm";
import Fab from "common/components/Fab";
import SidePanel from "common/components/SidePanel";
import copyContent from "common/utils/copyContent";
import CommandPackages from "./components/Packages";
import GlowingDot from "common/components/GlowingDot";
import Table from "common/components/Table/table";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import CodeAnalyzer from "pages/Action/components/CodeAnalyzer";
import { themeOptions } from "theme";
import CliCommand from "common/components/CliCommand";
const PackageMenu = ({ id, acl, tags, versions }) => {
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedResources, setSelectedResources] = React.useState(new Set());
  const [unselectedResources, setUnselectedResources] = React.useState(
    new Set()
  );
  const [refreshKey, setRefreshKey] = React.useState(0);

  const [cliModal, setCliModal] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const fetcher = useFetcher();
  const { ScopeInputs, getScope, setInit, loading } = useScopeAttributes();
  const deletePkg = React.useCallback(async () => {
    let selected = [];
    if (selectAll) {
      selected = versions.filter(
        (x) => !Array.from(unselectedResources).includes(x) && x !== "0"
      );
    } else {
      selected = Array.from(selectedResources);
    }
    if (!selected.length) {
      return;
    }
    await fetcher.delete(
      "deleting versions",
      `/api/pkg/${id}/versions?versions=${selected.join(",")}`,
      ({ success }) => {
        //clear searchparams uri
        setSearchParams({});
        setSelectAll(false);
        setSelectedResources(new Set());
        setUnselectedResources(new Set());
        setRefreshKey((x) => ++x);
      },
      () => {}
    );
  }, [versions, selectAll, selectedResources, unselectedResources]);
  const updatePkg = async () => {
    await fetcher.put(
      "updating package",
      `/api/pkg/${id}`,
      { ...getScope() },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        setSearchParams({});
      },
      () => {}
    );
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Multiple Package Versions`,
    message: `Are you sure you want to delete these versions? This action can't be undone.`,
    callback: () => {
      deletePkg();
    },
  });
  React.useEffect(() => {
    setInit(tags || [], acl || []);
  }, [acl, tags, loading]);
  return (
    <Stack p={2} gap={3}>
      <CliCommand
        context={
          "Initialize this package into your local development environment"
        }
        code={`e1 pkg init -i ${id}`}
        open={cliModal}
        onClose={() => setCliModal(false)}
      />
      <DeleteModal />
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        direction="row"
        gap={1}
      >
        <Stack>
          <Typography variant="h4">{id} menu</Typography>
        </Stack>
        <MultiLevelMenu
          button={(props) => (
            <ActionButton
              handler={props.onClick}
              circle
              dark
              noload
              icon="more_vert"
            />
          )}
          menu={{
            main: {
              "Local Development": {
                leftIcon: <Icon>terminal</Icon>,
                onClick: () => {
                  setCliModal(true);
                },
              },
            },
          }}
        />
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Manage Versions</Typography>
        <Stack gap={1}>
          <Typography>batch delete multiple package versions</Typography>
        </Stack>
        <Stack gap={1}>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="delete"
              dark
              noload
              danger
              handler={async () => {
                openDeleteModal(true);
              }}
            >
              Delete Versions
            </ActionButton>
          </div>
          <Table
            key={refreshKey}
            data={
              versions
                ?.filter((v) => Number(v) !== 0)
                ?.map((v) => ({ id: v, version: v })) || []
            }
            idProperty={"id"}
            columns={[
              {
                headerName: "Version",
                field: "version",
                minWidth: 200,
                resizable: true,
                flex: 1,
              },
            ]}
            loading={loading}
            noPaginate
            tableName={"e1pkgvertable"}
            tableHeight={500}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            selectedRowIds={selectedResources}
            setSelectedRowIds={setSelectedResources}
            unselectedRowIds={unselectedResources}
            setUnselectedRowIds={setUnselectedResources}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1} sx={{ minHeight: "100px" }}>
          {loading ? (
            <div style={{ marginLeft: "20px", position: "relative" }}>
              <MiniLoader />
            </div>
          ) : (
            <>
              <ScopeInputs />
              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  icon="save"
                  dark
                  noload
                  handler={async () => {
                    updatePkg();
                  }}
                >
                  Update
                </ActionButton>
              </div>
            </>
          )}
        </Stack>
      </Stack>
      {/* <Stack className="floating" gap={3}>
        <Typography variant="h5">Type</Typography>
        <Stack gap={1}>
          <Typography>
            Establish what value type this variable will hold.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <MultiSelect
            size="small"
            dark
            disabled={variable.pk}
            options={Object.keys(TypeMap).filter(
              (k) => !["bool", "float", "int", "date", "lstr"].includes(k)
            )}
            loading={false}
            label="Type"
            multiple={false}
            placeholder="Select a type"
            value={type}
            setValue={setType}
            sx={{ width: "100%" }}
          />
          <div style={{ width: "105px" }}>
            {type == "str" ? (
              <Switch
                disabled={variable.pk}
                label="Secret"
                checked={secret}
                onChange={() => setSecret(!secret)}
              />
            ) : null}
          </div>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Value</Typography>
        <Stack gap={1}>
          <Typography>Define your variable value.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Value"
            value={value}
            type={type === "str" && secret ? "password" : "str"}
            onChange={(e) => setValue(e.target.value)}
            {...(type === "str" && secret
              ? {}
              : {
                  multiline: true,
                  minRows: 3,
                  maxRows: 20,
                })}
          />
        </Stack>
        {variable?.pk ? (
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="content_copy"
              dark
              noload
              handler={async () => {
                toast.success("copied variable");
                copyContent(value);
              }}
            >
              Copy Value
            </ActionButton>
          </div>
        ) : null}
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          disabled={!value}
          handler={async () => {
            await saveVariable();
          }}
        >
          Save Variable
        </ActionButton>
      </div> */}
    </Stack>
  );
};
const Status = ({ children }) => {
  let colormap = {
    building: themeOptions.palette.info.main,
    deployed: themeOptions.palette.success.main,
    error: themeOptions.palette.error.main,
  };
  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <GlowingDot style={{ backgroundColor: colormap[children] }} />
      <Typography variant="caption">{children}</Typography>
    </Stack>
  );
};
export default function Package({ role }) {
  const [loading, setLoading] = React.useState(false);
  const [execute, setExecute] = React.useState("");
  const [copy, setCopy] = React.useState(false);
  const [restore, setRestore] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [err, setError] = React.useState(false);
  const [packages, setPackages] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [menu, setMenu] = React.useState(false);
  const { id } = useParams();
  const [jobsOpen, setJobsOpen] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  // const systemname = searchParams.get("system") || "";
  // const isNew = action === "new";
  const [refreshKey, setRefreshKey] = React.useState("1");
  const [configs, setConfigs] = React.useState(false);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [dataBacked, setDataBacked] = React.useState(false);
  const [code, setCodeState] = React.useState("");
  const [docs, setDocs] = React.useState(null);
  const [originalCode, setOriginalCode] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [version, setVersion] = React.useState(
    Number(searchParams.get("version") || 0)
  );
  const [data, setData] = React.useState({
    name: "",
    description: "",
    packages: [],
    tags: [],
    acl: [],
    scan: [],
  });
  const setPackagesState = (d) => {
    setPackages(d);
    if (!dirty) setDirty(true);
  };
  const setCode = (d) => {
    setCodeState(d);
    if (!dirty) setDirty(true);
  };
  const publishVersion = async () => {
    const payload = { packages, code };
    // if (!validate("command_edit", payload)) return;
    await fetcher.post(
      "publishing package version",
      `/api/pkg/${id}/publish`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        setDirty(false);
        setData((d) => ({
          ...d,
          versions: [...d.versions, data?.version],
        }));
        setVersion(Number(data?.version || 0));
        setSearchParams({ version: Number(data?.version || 0) });
        // updateCodeBase(data?.version || 0);
      },
      () => {}
    );
  };
  const [openConfirmModal, UploadModal] = useConfirm({
    title: `Publish Version`,
    message: `Publish a new version of ${data.name} package? `,
    callback: (props, context) => {
      publishVersion();
    },
    onCancel: () => {},
  });

  const fetcher = useFetcher();

  const navigate = useNavigate();

  const saveStaging = async () => {
    const payload = { packages, code };
    // if (!validate("command_edit", payload)) return;
    await fetcher.post(
      "saving to package staging",
      `/api/pkg/${id}/staging`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        setDirty(false);
        fetchPackage();
      },
      () => {}
    );
  };

  const fetchPackage = async () => {
    let ver = searchParams.get("version");
    setLoading(true);
    setDirty(false);
    await fetcher.get(
      "fetching package",
      `/api/pkg/${id}`,
      ({ pkg }) => {
        // setDataBacked(action?.data_backed);
        // setProperties(action?.properties || []);
        // if (isNew) return setError(false);
        // delete action?.data_backed;
        // delete action?.properties;
        let packages;
        if (pkg.data) {
          packages = pkg.data.packages;
        } else {
          packages = [];
        }
        setData({ ...pkg });
        // setVersion(data?.version || 0);
        // setSearchParams({ version: data?.version || 0 });
        setCodeState(pkg.code);
        setPackages(pkg.packages);
        // setCodeState(action.code);
        // setOriginalCode(action.code);
        setError(false);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
    if (Number(ver) !== 0) {
      await updateCodeBase(ver);
    } else {
      setStatus(null);
      setDocs(null);
    }
    setLoading(false);
  };
  const updateCodeBase = async (version) => {
    // setVersion(version);
    // setSearchParams({ version: version });
    // setLoading(true);
    // setDirty(false);
    await fetcher.get(
      "fetching version",
      `/api/pkg/${id}/version/${version}`,
      ({ pkg }) => {
        // setDataBacked(action?.data_backed);
        // setProperties(action?.properties || []);
        // if (isNew) return setError(false);
        // delete action?.data_backed;
        // delete action?.properties;
        setCodeState(pkg.code);
        setPackages(pkg.packages);
        setDocs(pkg.doc || null);
        if (pkg.status) {
          setStatus(pkg.status);
        } else {
          setStatus(null);
        }
        // setCodeState(action.code);
        // setOriginalCode(action.code);
      },
      () => {}
    );
  };
  const deletePkg = async (id, version) => {
    setLoading(true);
    setDirty(false);
    await fetcher.delete(
      "deleting version",
      `/api/pkg/${id}/version/${version}`,
      ({ pkg }) => {},
      () => {}
    );
    setVersion(0);
    setSearchParams({ version: 0 });
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Package Version`,
    message: `Are you sure you want to delete this version? This action can't be undone.`,
    callback: () => {
      deletePkg(id, version);
    },
  });

  React.useEffect(() => {
    let ver = searchParams.get("version");
    if (!ver) {
      setVersion(0);
      return setSearchParams({ version: 0 });
    }
    fetchPackage();
  }, [searchParams]);
  // if (err) {
  //   return (
  //     <Page
  //       back={`/app/sys/${id}`}
  //       loading={loading}
  //       header={`Cannot fetch command (${id})`}
  //     />
  //   );
  // }
  return (
    <>
      <DeleteModal />
      <UploadModal type="info" />
      {/* <SidePanel
        width={600}
        open={!!execute}
        closeDrawer={() => setExecute(null)}
        props={{
          system: id,
          id: execute,
        }}
      >
        {CommandDebugPanel}
      </SidePanel>
      <FilesModal
        role={role}
        // system={system?.id}
        open={filesOpen}
        setOpen={setFilesOpen}
      />
      <JobsModal system={id} open={jobsOpen} setOpen={setJobsOpen} /> */}
      <Page
        // refresh={!isNew ? () => fetchCommand(action) : null}
        back={`/app/packages`}
        header={
          data?.name ? (
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography variant="h4">{data.name}</Typography>
              <Stack ml={3}>{status ? <Status>{status}</Status> : null}</Stack>
            </Stack>
          ) : (
            "Package"
          )
        }
        refresh={() => fetchPackage()}
        dynamicSize
        loading={loading}
        actions={[
          ...[
            // <ActionButton
            //   noload
            //   icon="rocket_launch"
            //   circle
            //   handler={() => setExecute(data.id)}
            // />,
            // <ActionButton
            //   noload
            //   icon="dashboard"
            //   circle
            //   handler={() => navigate(`/app/sys/${id}/dash`)}
            // />,
            // <ActionButton
            //   noload
            //   icon="sms"
            //   circle
            //   handler={() => setJobsOpen(true)}
            // />,
            ...(version === 0
              ? [
                  <ActionButton
                    noload
                    icon="publish"
                    handler={() => openConfirmModal(true)}
                  >
                    Publish Version
                  </ActionButton>,
                ]
              : []),
            <div
              style={{
                width: "120px",
                position: "relative",
                paddingRight: "20px",
              }}
            >
              <MultiLevelMenu
                button={(props) => (
                  <ActionButton
                    sx={{ width: "120px", textAlign: "center" }}
                    handler={props.onClick}
                    noload
                    icon="tag"
                  >
                    {version === 0 ? "Staging" : `v${version}` || "Staging"}
                  </ActionButton>
                )}
                menu={{
                  main: (data.versions || []).reduce(
                    (a, c) => ({
                      ...a,
                      [Number(c) === 0 ? "Staging" : `v${c}`]: {
                        leftIcon: <Icon>tag</Icon>,
                        onClick: () => {
                          setVersion(Number(c));
                          setSearchParams({ version: Number(c) });
                          // updateCodeBase(Number(c));
                        },
                      },
                    }),
                    {}
                  ),
                }}
              />
            </div>,
            ,
            <ActionButton
              noload
              icon="code" //"more_vert"
              circle
              handler={() => setConfigs(true)}
            />,
            ...(version !== 0
              ? [
                  <ActionButton
                    noload
                    danger
                    icon="clear" //"more_vert"
                    circle
                    handler={() => openDeleteModal(true)}
                  />,
                ]
              : []),
            ...(true
              ? [
                  <ActionButton
                    noload
                    circle
                    icon="more_vert"
                    handler={() => setMenu(true)}
                  ></ActionButton>,
                ]
              : []),
            // <ActionButton
            //   noload
            //   icon="more_vert"
            //   circle
            //   handler={() => setConfigs(true)}
            // />,
          ],
        ]}
      >
        <div>
          <CodeEditor
            parent="app-body"
            name="code-editor"
            value={code}
            setValue={setCode}
            disabled={version !== 0}
            // onMenu={() => setConfigs(true)}
            passiveTools={(editor, insert) => [
              // {
              //   icon: (
              //     <IconButton>
              //       <Icon color="success">rocket_launch</Icon>
              //     </IconButton>
              //   ),
              //   onClick: () => setExecute(data.id),
              // },
              ...(data?.scan?.length
                ? [
                    {
                      component: <CodeAnalyzer data={data?.scan} />,
                    },
                  ]
                : []),
            ]}
          />
        </div>
        {/* <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          }}
        >
          <CommandCopy
            {...{
              open: copy,
              refresh: (id) => fetchCommand(id),
              close: () => {
                setCopy(false);
              },
              data,
              code,
            }}
          />
          <CommandRestore
            {...{
              open: restore,
              close: () => setRestore(false),
              action,
              refresh: () => fetchCommand(),
            }}
          />
        </div> */}
        <Fab
          active={dirty}
          icon="save"
          onClick={() => {
            saveStaging();
            // setConfigs(null);
            // saveCommand();
          }}
        />
        {/* <DeleteModal /> */}
        <SidePanel
          width={600}
          open={!!configs}
          closeDrawer={() => {
            setConfigs(null);
          }}
          props={{
            isStaging: version === 0,
            data,
            setData,
            packages,
            setPackagesState,
          }}
        >
          {(props) => (
            <>
              {docs ? (
                <Card
                  className="floating"
                  sx={{
                    minHeight: 250,
                    margin: 2,
                    padding: 2,
                    marginTop: "25px",
                    background: "#171717",
                  }}
                >
                  <Stack>
                    <SectionHeader>Documentation</SectionHeader>
                    {Object.keys(docs).map((key, idx) => {
                      const { signature, docstring } = docs[key];
                      return (
                        <Stack className="floating" key={idx} gap={2}>
                          <Typography mt={2} variant="body1">
                            {docstring}
                          </Typography>
                          <Stack sx={{ position: "relative" }} gap={1}>
                            <Stack
                              sx={{
                                position: "absolute",
                                padding: 0,
                                margin: 0,
                                top: "5px",
                                left: "5px",
                              }}
                              direction="row"
                              alignItems={"center"}
                            >
                              <IconButton
                                onClick={() => {
                                  copyContent(signature);
                                  toast.success("copied value!");
                                }}
                                sx={{ margin: 0, padding: 0 }}
                              >
                                <Icon
                                  sx={{ fontSize: "16px !important" }}
                                  color="success"
                                >
                                  content_copy
                                </Icon>
                              </IconButton>
                            </Stack>
                            <span
                              style={{
                                borderRadius: "10px",
                                backgroundColor:
                                  themeOptions.palette.primary.main,
                                padding: "20px",
                                paddingLeft: "40px",
                              }}
                            >
                              <pre
                                style={{
                                  margin: 0,
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}
                              >
                                {signature}
                              </pre>
                            </span>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Card>
              ) : null}
              <CommandPackages {...props} />
            </>
          )}
        </SidePanel>
        <SidePanel
          width={600}
          open={menu}
          closeDrawer={() => {
            setMenu(false);
          }}
          props={{
            versions: data?.versions || [],
            id: data?.name,
            ...data,
            // isStaging: version === 0,
            // data,
            // setData,
            // packages,
            // setPackagesState,
          }}
        >
          {PackageMenu}
        </SidePanel>
      </Page>
    </>
  );
}
