import React from "react";
import {
  Icon,
  Badge,
  Grid,
  Typography,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import { styled } from "@mui/material/styles";
import { theme } from "theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import Table from "common/components/Table/table";
const AnimatedGridItem = styled(Grid)(({ theme }) => ({
  cursor: "pointer",
  "&:hover .animated-icon": {
    transform: "rotateX(25deg)",
  },
  "&:hover .folder-text": {
    fontWeight: "bold !important",
  },
  "&:hover .MuiBadge-badge": {
    top: "10px",
  },
}));

const AnimatedIcon = styled(Icon)(({ theme }) => ({
  fontSize: "5rem !important",
  color: "grey !important",
  transition: "transform 0.3s ease-in-out",
  transformOrigin: "left bottom",
}));

const TagUpdateDialog = ({ title, open, onClose, tags, onUpdateTag }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  React.useEffect(() => {
    if (!open) {
      setValue(null);
      setInputValue("");
    }
  }, [open]);
  const handleSubmit = () => {
    onUpdateTag(value || inputValue);
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          bgcolor: "#171717",
          border: "2px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          p: 3,
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Move {title}
        <IconButton
          aria-label="close"
          color="error"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color="error" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          freeSolo
          options={tags.map((t) => t[0])}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => (
            <Input
              {...params}
              color="secondary"
              size="small"
              dark
              InputLabelProps={{ shrink: true }}
              label="Select a tag or enter a new one and press enter"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <ActionButton
          PaperComponent={({ children }) => (
            <Paper
              style={{ background: "#262626", color: "rgba(239,237,239,0.87)" }}
            >
              {children}
            </Paper>
          )}
          icon="move_up"
          noload
          handler={async () => {
            handleSubmit();
          }}
        >
          Change Tag
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

const useFolderView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [folder, setFolder] = React.useState(searchParams.get("folder") || "");
  const [moveFolder, setMoveFolder] = React.useState(null);
  React.useEffect(() => {
    let saved = `e1_last_search_${window.location.pathname.split("/").at(-1)}`;
    const currentSearch = window[saved];
    if (currentSearch) {
      let params = new URLSearchParams(currentSearch);
      setSearchParams(params);
      if (params.get("folder")) {
        setFolder(params.get("folder"));
      }
    }
  }, []);
  React.useEffect(() => {
    let saved = `e1_last_search_${window.location.pathname.split("/").at(-1)}`;
    window[saved] = window.location.search;
  }, [searchParams]);
  const filterFolders = (filtered, data) => {
    let tags = { default: 0 };

    filtered?.forEach((obj) => {
      tags["default"]++;
      obj.tags.forEach((tag) => {
        let t = tag.toLowerCase().trim();
        if (tags[t]) {
          tags[t]++;
        } else {
          tags[t] = 1;
        }
      });
    });
    tags = Object.entries(tags).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return a[0].localeCompare(b[0]);
    });
    let tag_options = { default: 0 };
    (data || [])?.forEach((obj) => {
      obj.tags.forEach((tag) => {
        let t = tag.toLowerCase().trim();
        if (t !== "default") {
          tag_options["default"]++;
        }
        if (tag_options[t]) {
          tag_options[t]++;
        } else {
          tag_options[t] = 1;
        }
      });
    });
    tag_options = Object.entries(tag_options).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return a[0].localeCompare(b[0]);
    });
    return [tags, tag_options];
  };
  const MoveFolderButton = ({ id }) => {
    return (
      <IconButton
        sx={{
          height: "40px",
          width: "40px",
          borderRadius: "20px",
          backgroundColor: "#a370f0",
        }}
        onClick={() => setMoveFolder(id)}
      >
        <Icon
          sx={{
            color: "#fff !important",
          }}
        >
          move_up
        </Icon>
      </IconButton>
    );
  };
  const FolderHeader = () => {
    return folder ? (
      <Typography
        style={{ display: "flex", alignItems: "center" }}
        variant="h4"
      >
        <IconButton
          onClick={() => {
            if (searchParams.has("folder")) {
              searchParams.delete("folder");
              setSearchParams(searchParams);
            }
            setFolder(null);
          }}
        >
          <Icon sx={{ color: "#fff" }}>arrow_back</Icon>
        </IconButton>
        <Icon sx={{ fontSize: "2rem !important", marginRight: "10px" }}>
          folder
        </Icon>
        {folder}
      </Typography>
    ) : null;
  };
  const folderColumns = [
    {
      headerName: "",
      field: "inspect",
      width: 60,
      cellRenderer: ({ data }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <IconButton
            onClick={() => {
              const newParams = new URLSearchParams(searchParams);
              newParams.set("folder", data.tag.toLowerCase());
              setSearchParams(newParams);
              setFolder(data.tag.toLowerCase());
            }}
          >
            <Icon>folder</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Folder",
      field: "tag",
      minWidth: 200,
      resizable: true,
      flex: 1,
      cellRenderer: ({ data }) => {
        return (
          <span
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <span>{data.tag}</span>
            {/* <IconButton color="success" onClick={() => setResourceOpen(data)}>
              <Icon color="success">travel_explore</Icon>
              Folders
            </IconButton> */}
          </span>
        );
      },
    },
    {
      headerName: "System Count",
      field: "count",
      width: 200,
    },
  ];
  // const folderCols = tags.map(([tag,value]) => {
  //   const config = { headerName: p.name, field: p.name };
  //   // if (p?.width) {
  //   //   console.log(p.width);
  //   //   config.width = Number(p.width);
  //   // }
  //   config.context = { type: p.type };
  //   // handle types
  //   if (["list", "object"].includes(p.type)) {
  //     config.context.align = "center";
  //   }
  //   //handle cell color
  //   if (p.name in colors) {
  //     try {
  //       if (Object.keys(colors[p.name])?.length) {
  //         config.context.cellColor = colors[p.name];
  //       }
  //     } catch {}
  //   }
  //   config.c;
  //   return config;
  // });
  const FolderView = ({ tags }) => {
    return (
      <Stack>
        <Table
          data={tags.map(([tag, value]) => ({
            tag,
            count: Number(value || 0),
          }))}
          idProperty={"field"}
          // filter={searchParams.get("query")}
          columns={folderColumns}
          noSelect
          noPaginate
          loading={false}
          // onSort={onSortInfoChange}
          // sortColumn={searchParams.get("sort")}
          // sortDirection={searchParams.get("dir")}
          tableName={"e1syslist"}
          tableHeight={700}
        />
      </Stack>
      // <Grid container spacing={2}>
      //   {tags.map(([tag, value], index) => (
      //     <AnimatedGridItem
      //       onClick={() => {
      //         const newParams = new URLSearchParams(searchParams);
      //         newParams.set("folder", tag.toLowerCase());
      //         setSearchParams(newParams);
      //         setFolder(tag.toLowerCase());
      //       }}
      //       p={2}
      //       item
      //       xs={6}
      //       sm={3}
      //       md={2}
      //       key={index}
      //     >
      //       <Box
      //         sx={{
      //           width: "100%",
      //           height: "200px", // Adjust the height as needed
      //           borderRadius: "8px",
      //           border: `1px solid ${theme.palette.primary.light}`,
      //           display: "flex",
      //           alignItems: "center",
      //           justifyContent: "center",
      //           padding: 2,
      //         }}
      //       >
      //         <Stack gap={1}>
      //           <Stack alignItems={"center"}>
      //             <Badge
      //               max={999}
      //               badgeContent={value}
      //               color="secondary"
      //               class
      //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
      //               sx={{
      //                 "& .MuiBadge-badge": {
      //                   transition: ".3s",
      //                   fontWeight: "bold",
      //                   top: "20px",
      //                   zIndex: "-1",
      //                 },
      //               }}
      //             >
      //               <AnimatedIcon className="animated-icon">
      //                 folder
      //               </AnimatedIcon>
      //             </Badge>
      //             <span className="folder-text">{tag?.toLowerCase()}</span>
      //           </Stack>
      //         </Stack>
      //       </Box>
      //     </AnimatedGridItem>
      //   ))}
      // </Grid>
    );
  };
  return {
    FolderView,
    FolderHeader,
    setFolder,
    folder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  };
};

export default useFolderView;
