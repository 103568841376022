import React from "react";
import { Stack, IconButton, Icon } from "@mui/material";
import DatePicker from "common/components/DatePicker";
import { useSearchParams } from "react-router-dom";
import Table from "common/components/Table/table";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import convertTimeToApiString from "common/utils/convertTimeToApiString";
import MultiSelect from "common/components/MultiSelect";
import Tooltip from "@mui/material/Tooltip";
import { MiniLoader } from "common/components/Loader";
// import { Chart } from "react-google-charts";
import SidePanel from "common/components/SidePanel";
import getOffsetUTCHours from "common/utils/getOffsetUTCHours";
import Log from "./Log";
import gmtToLocal from "common/utils/gmtToLocal";
import Widget from "common/components/Widget";
// Function to parse date strings and extract hours
function createTimeSeriesByHour(data) {
  function parseTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  function sortTimeArray(array) {
    return array.sort((a, b) => parseTime(a[0]) - parseTime(b[0]));
  }
  function formatUTCHour(hour) {
    // Ensure the hour is within the 0-23 range
    hour = hour % 24;
    // Format the hour and minute
    var formattedHour = (hour < 10 ? "0" : "") + hour;
    var formattedMinute = "00"; // Since we're formatting to the hour, minutes are always 00
    return formattedHour + ":" + formattedMinute;
  }
  // Initialize an object to hold the aggregated data
  const timeSeries = {};
  Array.from(Array(24)).forEach((_, idx) => {
    timeSeries[formatUTCHour(idx)] = 0;
  });
  // Iterate over each object in the data array
  data.forEach((item) => {
    // Parse the date string into a Date object
    const date = new Date(item.time);

    // Extract the hour from the date
    const hour = formatUTCHour(date.getHours());

    // Increment the count for this hour
    timeSeries[hour]++;
  });

  // Convert the timeSeries object into an array of [hour, count] pairs
  const timeSeriesArray = Object.entries(timeSeries).map(([hour, count]) => [
    hour,
    count,
  ]);

  return sortTimeArray(timeSeriesArray);
}

export default function Logging({ active }) {
  const [event, setEvent] = React.useState(null);
  const [logGroup, setLogGroup] = React.useState("/echelonone/systems");
  const [timeseries, setTimeseries] = React.useState([
    [
      "Hour",
      "Count",
      {
        role: "style",
      },
    ],
    ["00:00", 0, "#668fcc"],
    ["01:00", 0, "#cc8f66"],
    ["02:00", 0, "#b866cc"],
    ["03:00", 0, "#cc6666"],
    ["04:00", 0, "#66b8cc"],
    ["05:00", 0, "#8fcc66"],
    ["06:00", 0, "#ccb866"],
    ["07:00", 0, "#6666cc"],
    ["08:00", 0, "#66ccb8"],
    ["09:00", 0, "#cc668f"],
    ["10:00", 0, "#8f66cc"],
    ["11:00", 0, "#66cc66"],
    ["12:00", 0, "#66cc8f"],
    ["13:00", 0, "#b8cc66"],
    ["14:00", 0, "#cc66b8"],
    ["15:00", 0, "#668fcc"],
    ["16:00", 0, "#cc8f66"],
    ["17:00", 0, "#b866cc"],
    ["18:00", 0, "#cc6666"],
    ["19:00", 0, "#66b8cc"],
    ["20:00", 0, "#8fcc66"],
    ["21:00", 0, "#ccb866"],
    ["22:00", 0, "#6666cc"],
    ["23:00", 0, "#66ccb8"],
  ]);
  const [logs, setLogs] = React.useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(false);
  const [date, setDate] = React.useState(
    searchParams.get("date") ? new Date(searchParams.get("date")) : new Date()
  );
  const fetcher = useFetcher();
  const fetchLogs = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching logs",
      `/api/logs?date=${convertTimeToApiString(
        date
      )}&offset=${getOffsetUTCHours()}`,
      ({ logs, error }) => {
        if (error) return toast.error(error);
        let data = logs.map((l) => {
          let key = l.Key.split("/").reverse()[0];
          let [time, system_name, cmd_name, name] = l.Key.split("/")
            .reverse()[0]
            .split("_");
          return {
            time: gmtToLocal(l.LastModified),
            name,
            system_name,
            cmd_name,
            key: l.Key,
          };
        });
        let timeseries = createTimeSeriesByHour(data);
        setTimeseries([
          ["Hour", "Count"],
          ...(timeseries.length ? timeseries : [["0", 0]]),
        ]);
        setLogs(data);
      },
      () => {}
    );
    setLoading(false);
  };
  React.useEffect(() => {
    fetchLogs();
  }, [searchParams, date]);
  let d = new Date();
  let maxDate = new Date(d.setDate(d.getDate() + 1));
  console.log(logs);
  const columns = [
    {
      headerName: "",
      field: "inspect",
      width: 120,
      cellClass: "overflow-visible",
      suppressRowTransform: true,
      cellRenderer: ({ data, ...rest }) => (
        <span
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            overflow: "visible",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <IconButton
            onClick={() => {
              setEvent({
                ...data,
                name: `${data.system_name}_${data.cmd_name}_${data.name}`,
                date: convertTimeToApiString(date),
              });
            }}
          >
            <Icon>travel_explore</Icon>
          </IconButton>
        </span>
      ),
    },
    {
      headerName: "Time",
      field: "time",
      minWidth: 200,
      resizable: true,
      flex: 1,
      context: { type: "datetime" },
    },
    {
      headerName: "Job Name",
      field: "name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Command Name",
      field: "cmd_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "System Name",
      field: "system_name",
      minWidth: 200,
      resizable: true,
      flex: 1,
    },
  ];
  return (
    <Stack spacing={1}>
      <SidePanel
        width={600}
        open={!!event}
        closeDrawer={() => setEvent(null)}
        props={{ event }}
      >
        {Log}
      </SidePanel>
      <Stack alignItems="center" direction="row" gap={"10px"}>
        <MultiSelect
          disabled
          sx={{ width: "30%", minWidth: "250px" }}
          clearHandler={() => {
            setLogGroup("");
          }}
          label="Log Group"
          options={["/echelonone/systems", "/echelonone/users"] || []}
          multiple={false}
          value={logGroup}
          setValue={(e) => {
            setLogGroup(e);
          }}
        />
        <div
          onClick={() => setOpenDate(true)}
          onMouseLeave={() => setOpenDate(false)}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <Tooltip
            open={openDate}
            disableHoverListener
            disableFocusListener
            disableTouchListener
            arrow
            title={
              <DatePicker
                selected={date}
                onChange={(d) => {
                  setDate(d);
                  setSearchParams({
                    ...searchParams,
                    date: convertTimeToApiString(d),
                  });
                }}
                maxDate={maxDate}
              />
            }
          >
            <Stack alignItems={"center"} direction={"row"} gap="10px">
              <Icon>event</Icon>
              <p>{convertTimeToApiString(date)}</p>
            </Stack>
          </Tooltip>
        </div>
        {loading ? (
          <div style={{ marginLeft: "20px", position: "relative" }}>
            <MiniLoader />
          </div>
        ) : null}
      </Stack>
      <div className="py-10">
        <Widget
          noHeader={true}
          name={""}
          description={""}
          prefix={""}
          suffix={""}
          data={timeseries}
          type={"TimeSeries"}
        />
      </div>
      <Stack direction="row" spacing={1}>
        <Stack spacing={1} alignItems="start"></Stack>
        <Stack style={{ flex: 1 }}>
          <Table
            data={logs}
            idProperty={"time"}
            columns={columns}
            noSelect
            noPaginate
            loading={loading}
            tableName={"e1loglist"}
            tableHeight={550}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
